import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';
import './DeleteConfirmationDialog.scss';

interface DeleteConfirmationDialogProps {
  open: boolean;
  customMsg?: string | null;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteConfirmationDialog = ({
  open,
  customMsg,
  onConfirm,
  onCancel,
}: DeleteConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onCancel} className="delete-confirmation-dialog">
      <DialogContent>
        <DialogContentText>
          {customMsg ? customMsg : 'Are you sure you want to delete this record?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          Yes
        </Button>
        <Button onClick={onCancel} color="primary" autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
