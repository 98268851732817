import { Typography } from '@mui/material';
import TableParts from '../../components/dmsms/parts/TableParts';

import React, { useState } from 'react';

const PartsPage = () => {
  return (
    <div className="page-container">
      <Typography variant="h4" className="page-title">
        Parts
      </Typography>

      <TableParts />
    </div>
  );
};

export default PartsPage;
