import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Link,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import './PopupRiskCases.scss';
import { RiskCaseViewModelDto } from '../../../api/types/types';
import Badge from '../../Site-Admin/Colors/Badge';
import { generateMonochromaticScale } from '../../Site-Admin/Colors/utils/generateMonochromaticScale';

interface Article {
  id: number;
  title: string;
  pubDate?: string;
  sourceDomain: string;
  url: string;
  sentimentMetrics: string;
  semanticSummary: string;
  content: string;
  imageUrl: string;
  suppliers: string;
  positiveSentiment: number;
  negativeSentiment: number;
  supplierArticles?: SupplierArticle[];
  articleKeywords?: ArticleKeyword[];
  articleRiskCases?: ArticleRiskCases[];
}

interface ArticleRiskCases {
  id: number;
  riskCaseId: number;
  articleId: number;
}

interface SupplierArticle {
  id: string;
  name: string;
}

interface ArticleKeyword {
  id: string;
  name: string;
}

interface RiskCasePopupProps {
  popupRiskCasesVisible: boolean;
  handleRiskCasesClose: () => void;
  selectedRiskCaseId?: number;
  article?: Article | null;
  riskCases?: RiskCaseViewModelDto[];
}

const PopupRiskCases: React.FC<RiskCasePopupProps> = ({
  popupRiskCasesVisible,
  handleRiskCasesClose,
  article,
  riskCases,
}) => {
  const location = useLocation();
  const allRiskCases: RiskCaseViewModelDto[] = useSelector(
    (state: any) => state.riskCases?.allRiskCasesVm
  );

  const primaryColor = useSelector((state: any) => state.theme.primaryColor);

  const primaryMonochromaticScale = useMemo(() => {
    return generateMonochromaticScale(primaryColor);
  }, [primaryColor]);

  const selectRiskCasesToDisplay = (): RiskCaseViewModelDto[] => {
    if (riskCases?.length) {
      return riskCases;
    }

    if (article != null) {
      const relatedRiskCaseIds: number[] =
        article?.articleRiskCases?.map((arc) => arc.riskCaseId) ?? [];

      const relatedRiskCases =
        allRiskCases?.filter((riskCaseVm: RiskCaseViewModelDto) =>
          relatedRiskCaseIds.includes(riskCaseVm.generalInformation.riskCaseId)
        ) ?? [];

      return relatedRiskCases;
    }

    return [];
  };

  const riskCasesToDisplay = selectRiskCasesToDisplay();

  const handleRiskCaseNavigation = (id: number) => {
    const currentLocation = location.pathname;

    const locationMapping = [
      {
        sourceLocationPattern: 'archived-keyword',
        mapToLocation: '/scrm/risk-cases/archived-kwm/',
      },
      {
        sourceLocationPattern: 'archived-supplier',
        mapToLocation: '/scrm/risk-cases/archived-supplier/',
      },
      {
        sourceLocationPattern: 'kwm',
        mapToLocation: '/scrm/risk-cases/kwm/',
      },
      {
        sourceLocationPattern: 'supplier',
        mapToLocation: '/scrm/risk-cases/supplier/',
      },
      {
        sourceLocationPattern: 'dashboard',
        mapToLocation: '/scrm/risk-cases/supplier/',
      },
    ];

    // Find the matching route pattern
    const matchedMapping = locationMapping.find((mapping) =>
      currentLocation.includes(mapping.sourceLocationPattern)
    );

    // If a match is found, navigate to the mapped location with the ID
    if (matchedMapping) {
      const baseUrl = getBaseUrl();
      const targetUrl = `${baseUrl}${matchedMapping.mapToLocation}${id}`;
      window.open(targetUrl, '_blank');
    } else {
      console.error(
        `Failed to determine target url based on current location: ${location.pathname}`
      );
    }
  };

  const getBaseUrl = () => {
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  };

  const handleSupplierClick = (supplierId: number) => {
    const baseUrl = getBaseUrl();
    window.open(`${baseUrl}/scrm/suppliers/${supplierId}`, '_blank');
  };

  const formatDate = (date: Date | string | null): string => {
    if (!date) return '';

    return new Date(date).toLocaleDateString();
  };

  return (
    <Dialog
      open={popupRiskCasesVisible}
      onClose={() => {}}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: { maxHeight: '80vh', overflowY: 'auto' },
      }}
    >
      <Typography variant="h5" component="div" className="dialog-title">
        {riskCasesToDisplay.length} Risk Case(s)
      </Typography>
      <IconButton
        aria-label="close"
        onClick={handleRiskCasesClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#ffffff',
        }}
      >
        <CloseIcon sx={{ fontSize: 20 }} />
      </IconButton>

      <DialogContent dividers className="dialog-scrollbar">
        <Grid container spacing={2}>
          {riskCasesToDisplay.length > 0 ? (
            riskCasesToDisplay.map((rc) => {
              const { generalInformation: info, measures } = rc;

              return (
                <Card key={info.riskCaseId} variant="outlined" sx={{ width: '100%', margin: 1 }}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant="h6" component="div">
                          <Link
                            onClick={() => handleRiskCaseNavigation(info.riskCaseId)}
                            className="riskCase-name"
                          >
                            {info.riskCaseName}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          <strong>Date Created</strong> {formatDate(info.dateAdded)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          <strong>Last Activity</strong> {formatDate(info.dateUpdated)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container sx={{ marginTop: 2 }}>
                      <Grid item xs={12}>
                        <Typography variant="body2" component="div">
                          <strong>Description</strong>
                          <Box
                            // The description field should be truncated with elipsis if the
                            // content exceeds 2 lines of text
                            sx={{
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              WebkitLineClamp: 2,
                            }}
                          >
                            {info.description}
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container sx={{ marginTop: 2 }}>
                      <Grid item xs={4}>
                        <Typography variant="body2" component="div">
                          <strong>Suppliers</strong>
                          <Box>
                            {info.suppliers.map((s, index) => (
                              <span key={s.id}>
                                <Link
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleSupplierClick(s.id)}
                                >
                                  {s.label}
                                </Link>
                                {index < info.suppliers.length - 1 && <span> </span>}
                              </span>
                            ))}
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2" component="div">
                          <strong>Owner</strong>
                          <p style={{ marginTop: 0 }}>{info.owner.name}</p>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2" component="div">
                          <strong>Keyword Models</strong>
                          <p style={{ marginTop: 0 }}>
                            {info.keywordModels.map((kwm) => kwm.label + ' ')}
                          </p>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Card variant="outlined" sx={{ width: '100%', marginTop: 1 }}>
                      <CardContent>
                        {/* Header Row */}
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Typography variant="body2">
                              <strong>Measures</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="body2">
                              <strong>Risk Level</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography variant="body2">
                              <strong>Comments</strong>
                            </Typography>
                          </Grid>
                        </Grid>

                        {/* Data Rows */}
                        {measures.riskMeasures.map((m) => (
                          <Box key={m.riskMeasureId} mb={1}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={3}>
                                <Typography variant="body2" component="div">
                                  <Box mt={1}>{m.riskMeasureName}</Box>
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                {(() => {
                                  const label = m.availableRiskMeasureLevels.find(
                                    (av) => av.id === m.selectedLevelId
                                  )?.label;
                                  return label ? (
                                    <Badge
                                      type="light"
                                      monochromaticScale={
                                        m.themeColor?.monochromaticScale ??
                                        primaryMonochromaticScale
                                      }
                                    >
                                      {label}
                                    </Badge>
                                  ) : null;
                                })()}
                              </Grid>
                              <Grid item xs={5}>
                                <Typography variant="body2">{m.comments}</Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </CardContent>
                    </Card>
                  </CardContent>
                </Card>
              );
            })
          ) : (
            <Typography variant="body2" align="center" sx={{ width: '100%', mt: 2 }}>
              There are no associated Risk Cases.
            </Typography>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PopupRiskCases;
