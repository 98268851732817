import { Box, Typography } from '@mui/material';
import React from 'react';
import ColorPicker from './ColorPicker';

const PrimaryColor = () => {
  return (
    <>
      <Box pb={2}>
        <Typography variant="h6" fontWeight="bold">
          Primary Color
        </Typography>
        <Typography variant="body2">
          The Primary Color controls the appearance of buttons, sidebar navigation and dashboard
          elements. When selecting the Primary Color, it is best to pick a shade that is neither too
          dark nor too light. A medium shade works best as the app will automatically create a
          monochromatic theme with shades both darker and lighter than the selected color.
        </Typography>
        <Typography variant="body2" mt={1}>
          In the following color dialog, input a hex code for the primary color. The app will
          display that color in the header element of the dialog and it will provide a preview of
          the dashboard monochromatic theme, navigation theme, and buttons. You can replace the hex
          by clicking on any of the ten dashboard element swatches.
        </Typography>
      </Box>

      <ColorPicker />
    </>
  );
};

export default PrimaryColor;
