import RiskCase from './riskCase';
import RiskCaseLogEntry from './riskCaseLogEntry';
import RiskCaseLogEntryWatchedParts from './riskCaseLogEntryWatchedPart';

const RiskCaseApi = {
  ...RiskCase,
  ...RiskCaseLogEntry,
  ...RiskCaseLogEntryWatchedParts,
};

export default RiskCaseApi;
