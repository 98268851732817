import chroma from 'chroma-js';

export const generateMonochromaticScale = (hex: string): string[] => {
  const baseColor = chroma(hex);

  const darkerScale = chroma
    .scale([baseColor.darken(4), baseColor])
    .mode('lab')
    .colors(5);
  const lighterScale = chroma
    .scale([baseColor, baseColor.brighten(4)])
    .mode('lab')
    .colors(7);

  const scale = [...darkerScale.slice(0, -1), hex, ...lighterScale.slice(1, -1)];

  return scale;
};
