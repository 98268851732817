import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createColumn } from '../../../utils/data-grid';
import { NsnDataGridHelpMenu } from './NsnDataGridHelpMenu';
import './NsnDetails.scss';

export default function NsnDetailMoeDataGrid({ data }) {
  // Create column definitions	// Create column definitions
  const columns = [
    { ...createColumn('MOE_RL', 'MOE RL'), minWidth: 80, flex: 1 },
    { ...createColumn('AMC', 'AMC'), minWidth: 80, flex: 1 },
    {
      ...createColumn('AMSC', 'AMSC'),
      minWidth: 80,
      flex: 1,
    },
    {
      ...createColumn('NIMSC', 'NIMSC'),
      minWidth: 80,
      flex: 1,
    },
    { ...createColumn('EFF_DATE', 'EFF DATE'), minWidth: 80, flex: 1 },
    { ...createColumn('IMC', 'IMC'), minWidth: 80, flex: 1 },
    { ...createColumn('IMCA', 'IMCA'), minWidth: 80, flex: 1 },
    {
      ...createColumn('AAC', 'AAC'),
      minWidth: 80,
      flex: 1,
    },
    {
      ...createColumn('SUPP_COLLAB', 'SUPP COLLAB'),
      minWidth: 80,
      flex: 1,
    },
    {
      ...createColumn('SUPP_RCVR', 'SUPP RCVR'),
      minWidth: 80,
      flex: 1,
    },
    {
      ...createColumn('DSOR', 'DSOR'),
      minWidth: 80,
      flex: 1,
    },
  ];

  return (
    <Box className="nsn-detail-grid">
      <h5>MOE Rules</h5>
      <DataGrid
        autoHeight
        getRowId={() => 10000 * Math.random()}
        density="compact"
        rows={data}
        columns={columns}
        slots={{ columnMenu: NsnDataGridHelpMenu }}
      />
    </Box>
  );
}
