import React from 'react';

import {
  Autocomplete,
  Chip,
  FormControl,
  SelectProps,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import { chipContainerStyles } from '../styles';
import './AltruityTagBox.scss';
import { TagBoxOption } from '../../form/index';

interface TagBoxProps {
  name: string;
  label?: string | null;
  defaultValues?: any[] | null;
  values: any[];
  autoComplete?: boolean | null;
  options?: TagBoxOption[] | null;
  //selectProps?: SelectProps | null;
  formControlSx?: SxProps<Theme> | undefined;
  onChange: (field: string, e: any) => void;
  onDeleteOption: (field: string, e: any) => void;
}

export default function AltruityTagBox({
  label,
  defaultValues,
  values,
  onChange,
  onDeleteOption,
  name,
  options,
  //selectProps,
  autoComplete,
  formControlSx,
}: //placeholder,
TagBoxProps) {
  return (
    <FormControl fullWidth sx={formControlSx}>
      {autoComplete ? (
        <Autocomplete
          multiple
          freeSolo
          options={values}
          value={values}
          defaultValue={defaultValues ?? undefined}
          onChange={(e: any) => {
            onChange(name, e);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              label={label}
              variant="outlined"
              className="tagbox-text-field"
            />
          )}
          renderTags={() => (
            <div style={chipContainerStyles}>
              {values.map((value, i) => {
                const k = `${value}_${i}`;
                return (
                  <Chip
                    key={k}
                    label={value}
                    onDelete={(e: any) => {
                      onDeleteOption(name, value);
                    }}
                    onMouseDown={(event: any) => event.stopPropagation()}
                  />
                );
              })}
            </div>
          )}
        />
      ) : (
        <Autocomplete
          className="select-box-text-field"
          multiple
          options={options || []}
          getOptionLabel={(option) => option.text ?? ''}
          value={values}
          onChange={(e: any, newValue: any[]) => {
            if (newValue.length === 0) {
              for (var val in values) {
                onDeleteOption(name, val);
              }
            } else {
              onChange(name, newValue[newValue.length - 1].value);
            }
          }}
          renderInput={(params) => <TextField {...params} label={label} />}
          renderTags={(values) =>
            values.map((value, i) => {
              const option = options?.find((c) => c.value === value);
              const k = `${option?.text}_${i}`;
              return (
                <Chip
                  label={option?.text}
                  key={k}
                  onDelete={(e: any) => {
                    onDeleteOption(name, option?.value);
                  }}
                  onMouseDown={(event: any) => event.stopPropagation()}
                />
              );
            })
          }
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      )}
    </FormControl>
  );
}
