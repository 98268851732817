import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createColumn } from '../../../utils/data-grid';
import { NsnDataGridHelpMenu } from './NsnDataGridHelpMenu';
import './NsnDetails.scss';

export default function NsnDetailManagementDataGrid({ data }) {
  // Create column definitions	// Create column definitions
  const columns = [
    { ...createColumn('EFF_DT', 'EFF DT'), minWidth: 80, flex: 1 },
    { ...createColumn('MOE', 'MOE'), minWidth: 80, flex: 1 },
    {
      ...createColumn('AAC', 'AAC'),
      minWidth: 80,
      flex: 1,
    },
    {
      ...createColumn('SOS', 'SOS'),
      minWidth: 80,
      flex: 1,
    },
    { ...createColumn('UI', 'UI'), minWidth: 80, flex: 1 },
    { ...createColumn('UI_PRICE', 'UI PRICE'), minWidth: 80, flex: 1 },
    { ...createColumn('QUP', 'QUP'), minWidth: 80, flex: 1 },
    {
      ...createColumn('CIIC', 'CIIC'),
      minWidth: 80,
      flex: 1,
    },
    {
      ...createColumn('SLC', 'SLC'),
      minWidth: 80,
      flex: 1,
    },
    {
      ...createColumn('REP', 'REP'),
      minWidth: 80,
      flex: 1,
    },
    {
      ...createColumn('USC', 'USC'),
      minWidth: 80,
      flex: 1,
    },
  ];

  return (
    <Box className="nsn-detail-grid">
      <h5>Management</h5>
      <DataGrid
        autoHeight
        getRowId={() => 10000 * Math.random()}
        density="compact"
        rows={data}
        columns={columns}
        slots={{ columnMenu: NsnDataGridHelpMenu }}
      />
    </Box>
  );
}
