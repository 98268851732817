import React, { useEffect, useState } from 'react';
import DmsmsLifeStage from './DmsmsLifeStage';
import DmsmsApi from '../../../../api/dmsms/dmsmsAPI';
import DmsmsSuppliersChart from './DmsmsSuppliersChart';
import { WatchedPartDto } from '../../../../api/types/types';
import { Box, CircularProgress } from '@mui/material';

const DmsmsContainer = () => {
  const [partsData, setPartsData] = useState<WatchedPartDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      const response = await DmsmsApi.getWatchedParts();
      setPartsData(response.result);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching parts:', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'start',
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          style={{ padding: '20px', gap: '20px' }}
        >
          <DmsmsLifeStage parts={partsData} />
          <DmsmsSuppliersChart partsData={partsData} />
        </Box>
      )}
    </div>
  );
};

export default DmsmsContainer;
