import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';
import './ModelExecutionConfirmationDialog.scss';

interface ModelExecutionConfirmationDialogProps {
  open: boolean;
  loading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ModelExecutionConfirmationDialog = ({
  open,
  loading,
  onConfirm,
  onCancel,
}: ModelExecutionConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onCancel} className="model-execution-confirmation-dialog">
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontSize: '18px' }}>
          Confirm model execution
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minHeight: 100 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <DialogContentText>Do you want to run this model across all Suppliers?</DialogContentText>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions>
          <Button onClick={onConfirm} color="primary">
            Yes
          </Button>
          <Button onClick={onCancel} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModelExecutionConfirmationDialog;
