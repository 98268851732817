import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  primaryColor: '#061939',
  colors: [],
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setPrimaryColor: (state, action) => {
      state.primaryColor = action.payload;
    },
    setColors: (state, action) => {
      state.colors = action.payload;
    },
  },
});

export const { setPrimaryColor, setColors } = themeSlice.actions;
export default themeSlice.reducer;
