import { createTheme } from '@mui/material/styles';
import chroma from 'chroma-js';

const createCustomTheme = (primaryColor: string) => {
  const isLightColor = chroma.contrast(primaryColor, 'white') < 4.5;

  const hoverColor = isLightColor
    ? chroma(primaryColor).darken(1.5).hex()
    : chroma(primaryColor).brighten(1.5).hex();

  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: chroma(primaryColor).darken(1.5).hex(),
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: hoverColor,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            '&.MuiChip-clickable:hover': {
              backgroundColor: hoverColor,
            },
          },
        },
      },
    },
  });
};

export default createCustomTheme;
