import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  IconButton,
} from '@mui/material';
import PopupArticle from '../../../components/risk-discovery/risk-discovery-popup/PopupArticle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './RiskCaseArticle.scss';
import PopupRiskCases from '../../risk-discovery/popup-risk-cases/PopupRiskCases';

export interface Article {
  id: number;
  title: string;
  semanticSummary: string;
  sourceDomain: string;
  url: string;
  sentimentMetrics: any;
  content: string;
  imageUrl: string;
  suppliers: string;
  positiveSentiment: number;
  negativeSentiment: number;
}

interface ArticlesTableProps {
  articles: Article[];
}

const ArticlesTable: React.FC<ArticlesTableProps> = ({ articles }) => {
  const [isPopupVisible, setPopupVisible] = React.useState(false);
  const [currentArticle, setCurrentArticle] = React.useState<Article | null>(null);
  const [popupRiskCasesVisible, setPopupRiskCasesVisible] = React.useState(false);
  const handleViewClick = (article: Article) => {
    setCurrentArticle(article);
    setPopupVisible(true);
  };

  const safeArticles = Array.isArray(articles) ? articles : [];

  const closePopup = () => {
    setPopupVisible(false);
    setCurrentArticle(null);
  };

  const handleRiskCaseClick = (article: Article) => {
    setCurrentArticle(article);
    setPopupRiskCasesVisible(true);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className="table-container">
      <TableContainer component={Paper}>
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Source Domain</TableCell>
              {/* <TableCell>Relevance</TableCell> */}
              <TableCell>+ Sentiment</TableCell>
              <TableCell>-Sentiment</TableCell>
              <TableCell>Summary Excerpt</TableCell>
              <TableCell>Risk Case</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {safeArticles.map((article) => (
              <TableRow key={article.id}>
                <TableCell>{truncateText(article.title, 80)}</TableCell>
                <TableCell>{article.sourceDomain}</TableCell>
                <TableCell>{article.positiveSentiment}</TableCell>
                <TableCell>{article.negativeSentiment}</TableCell>
                <TableCell>{truncateText(article.semanticSummary, 80)}</TableCell>
                <TableCell>
                  <IconButton className="icon-button" onClick={() => handleRiskCaseClick(article)}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    onClick={() => handleViewClick(article)}
                    className="
                    action-botton"
                  >
                    VIEW
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPopupVisible && currentArticle && (
        <PopupArticle
          onClose={closePopup}
          articles={[currentArticle]}
          currentIndex={0}
          setCurrentIndex={() => {}}
        />
      )}
      <PopupRiskCases
        popupRiskCasesVisible={popupRiskCasesVisible}
        handleRiskCasesClose={() => setPopupRiskCasesVisible(false)}
        article={currentArticle ? currentArticle : null}
      />
    </div>
  );
};

export default ArticlesTable;
