// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, NsnCageStatusDto } from '../../api/types/types';

const { NSN_SERVICE_URL } = config;

const Verify = {
  getCageCode: async function (cageCodeId: number): Promise<ResponseDto<NsnCageStatusDto>> {
    const url = `${NSN_SERVICE_URL}/Verify/CageCode/${cageCodeId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<NsnCageStatusDto>;
  },
};

export default Verify;
