import React, { useState, useEffect } from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { ThemeColorDto } from '../../../api/types/types';
import { MenuItem, MenuItemProps, Select } from '@mui/material';
import { ThemeDisplay } from './ColorThemePicker';

// Custom edit component for theme picker in DataGrid
interface ThemePickerEditComponentProps extends GridRenderEditCellParams {
  availableThemes: ThemeColorDto[];
}

/**
 * This component is used as a cell editor for selecting a theme color in a table.
 *
 * - **Value Type**: The `value` prop of the column is expected to be of type `ThemeColorDto | null`.
 *   If `value` is `null`, it means no theme has been selected for that particular row/cell.
 *
 * - **Available Themes**: The component expects the theme to be selected from the `availableThemes` array,
 *   which should contain a list of `ThemeColorDto` objects. Each `ThemeColorDto` contains the necessary
 *   properties to display and identify the theme.
 *
 * - **Value Setting**: Upon selection, the value of the cell is set to the selected theme object (an instance
 *   of `ThemeColorDto`). The selected object is passed back via the appropriate change handlers, updating
 *   the table row with the new theme.
 */
function ThemePickerEditComponent(props: ThemePickerEditComponentProps) {
  const { id, field, value, row, hasFocus, availableThemes } = props;
  const apiRef = useGridApiContext();
  const [selectedColorTheme, setSelectedColorTheme] = useState<ThemeColorDto | null>(value || null);

  // Handle focus to ensure the component is focused when the cell is in edit mode
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hasFocus && ref.current) {
      ref.current.focus();
    }
  }, [hasFocus]);

  // Handle theme change
  const handleThemeChange = (event: any) => {
    const themeId: number | null = event.target.value === '' ? null : event.target.value;

    const selectedTheme = availableThemes.find((t) => t.id === themeId) ?? null;
    setSelectedColorTheme(selectedTheme);

    // Set the new value in the DataGrid
    apiRef.current.setEditCellValue({
      id,
      field,
      value: selectedTheme, // Set the theme here as the new value
    });
  };

  return (
    <Select
      value={selectedColorTheme?.id || ''} // Ensure the value is valid (either a number or an empty string)
      onChange={handleThemeChange}
      sx={{ width: '100%', height: '100%' }}
      renderValue={(value) => {
        const theme = availableThemes.find((t) => t.id === value);
        return theme ? <ThemeDisplay theme={theme} /> : null;
      }}
      ref={ref} // Ensure focus is maintained
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {availableThemes.map((theme: ThemeColorDto) => (
        <ThemeSelectMenuItem value={theme.id} theme={theme} key={theme.id} />
      ))}
    </Select>
  );
}

export default ThemePickerEditComponent;

interface ThemeSelectMenuItemProps extends MenuItemProps {
  theme: ThemeColorDto;
}

const ThemeSelectMenuItem: React.FC<ThemeSelectMenuItemProps> = ({ theme, ...props }) => (
  <MenuItem {...props}>
    <ThemeDisplay theme={theme} />
  </MenuItem>
);
