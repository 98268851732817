import React, { useEffect, useState } from 'react';
import TreeView from 'devextreme-react/tree-view';
import './RiskDiscoveryMenu.scss';
import RiskDiscoveryApi from '../../api/riskDiscovery/riskDiscoverylAPI';
import { useNavigate } from 'react-router';
import { setKeywordModel } from '../../slices/discoverySlice';
import { useDispatch, useSelector } from 'react-redux';
import { LoadIndicator } from 'devextreme-react';

interface TreeViewItem {
  id: number | string;
  text: string;
  expanded?: boolean;
  modelId?: number | string;
  type?: string;
  items?: TreeViewItem[];
  nonSelectable?: boolean;
}

const initialItems: TreeViewItem[] = [
  {
    id: 1,
    text: 'By KWM',
    expanded: true,
    items: [],
  },
  {
    id: 2,
    text: 'By Supplier',
    expanded: true,
    items: [],
  },
  {
    id: 3,
    text: 'Archived Hits',
    expanded: true,
    items: [
      {
        id: 4,
        text: 'By Keywords',
        expanded: true,
        items: [],
      },
      {
        id: 5,
        text: 'By Supplier',
        expanded: true,
        items: [],
      },
    ],
  },
];

const renderTreeViewItem = (item: TreeViewItem, selectedItem: string | number | null) => {
  const itemClass =
    item.modelId === selectedItem
      ? 'tree-view-item selected'
      : item.nonSelectable
        ? 'tree-view-item non-selectable'
        : item.items && item.items.length > 0
          ? 'tree-view-item title'
          : 'tree-view-item value';
  return <div className={itemClass}>{item.text}</div>;
};

const RiskDiscoveryMenu: React.FC = () => {
  const [items, setItems] = useState<TreeViewItem[]>(initialItems);
  const [selectedItem, setSelectedItem] = useState<string | number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuDiscovery = useSelector((state: any) => state.discovery.keywordModel);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (Object.keys(menuDiscovery).length === 0) {
        const data = await RiskDiscoveryApi.getRiskDiscoveryMenu();
        dispatch(setKeywordModel(data));
      }

      const kwmItems = Object.keys(menuDiscovery.byKeywordModel)
        .map((key, index) => ({
          id: `kwm-${index + 1}`,
          text: key,
          expanded: true,
          modelId: menuDiscovery.byKeywordModel[key].id,
          type: 'kwm',
          items: [],
        }))
        .sort((a, b) => a.text.localeCompare(b.text));

      const suppliersItems = Object.keys(menuDiscovery.bySupplier)
        .map((key, index) => ({
          id: `supplier-${index + 1}`,
          text: key,
          expanded: true,
          modelId: menuDiscovery.bySupplier[key].id,
          type: 'supplier',
          items: [],
        }))
        .sort((a, b) => a.text.localeCompare(b.text));

      const archivedHitsByKeywordModelItems = Object.keys(menuDiscovery.archivedHits.byKeywordModel)
        .map((key, index) => ({
          id: `archived-kwm-${index + 1}`,
          text: key,
          expanded: true,
          modelId: menuDiscovery.archivedHits.byKeywordModel[key].id,
          type: 'archived-keyword',
          items: [],
        }))
        .sort((a, b) => a.text.localeCompare(b.text));

      const archivedHitsBySupplierItems = Object.keys(menuDiscovery.archivedHits.bySupplier)
        .map((key, index) => ({
          id: `archived-supplier-${index + 1}`,
          text: key,
          expanded: true,
          modelId: menuDiscovery.archivedHits.bySupplier[key].id,
          type: 'archived-supplier',
          items: [],
        }))
        .sort((a, b) => a.text.localeCompare(b.text));

      const updatedItems = [...initialItems];

      if (updatedItems[0].items) updatedItems[0].items = kwmItems;
      if (updatedItems[1].items) updatedItems[1].items = suppliersItems;
      if (updatedItems[2].items && updatedItems[2].items[0])
        updatedItems[2].items[0].items = archivedHitsByKeywordModelItems;
      if (updatedItems[2].items && updatedItems[2].items[1])
        updatedItems[2].items[1].items = archivedHitsBySupplierItems;

      setItems(updatedItems);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [menuDiscovery]);

  const handleItemClick = (e: { itemData: TreeViewItem }) => {
    const itemData = e.itemData as TreeViewItem;

    setSelectedItem(itemData.modelId ?? itemData.id);

    if (itemData.modelId) {
      let url = '';
      if (itemData.type === 'kwm') {
        url = `/scrm/discovery/kwm/${itemData.modelId}`;
      } else if (itemData.type === 'supplier') {
        url = `/scrm/discovery/supplier/${itemData.modelId}`;
      } else if (itemData.type === 'archived-supplier') {
        url = `/scrm/discovery/archived-supplier/${itemData.modelId}`;
      } else if (itemData.type === 'archived-keyword') {
        url = `/scrm/discovery/archived-keyword/${itemData.modelId}`;
      }
      return navigate(url);
    }
  };

  return (
    <div id="treeViewContainer" className="tree-view-container">
      {isLoading ? (
        <div className="spinner">
          <LoadIndicator />
        </div>
      ) : (
        <TreeView
          items={items}
          width={300}
          itemRender={(item) => renderTreeViewItem(item, selectedItem)}
          onItemClick={handleItemClick as any}
          selectByClick={true}
        />
      )}
    </div>
  );
};

export default RiskDiscoveryMenu;
