import Articles from './articles';
import Collection from './collection';
import SupplierArticle from './supplierArticle';

const ArticlesAPI = {
  ...Articles,
  ...Collection,
  ...SupplierArticle,
};

export default ArticlesAPI;
