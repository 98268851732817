import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './RiskCasePopup.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setRiskCase } from '../../../slices/discoverySlice';
import { IRiskCase } from '../types/RiskCase';
import SelectBoxOption from '../../../types/SelectBoxOption';

import { useParams } from 'react-router';
import RiskCaseAccordion from './RiskCaseAccordion';
import getSections from './sections/sections';

// @ts-ignore
import RiskCaseApi from '../../../api/riskCase/riskCaseApi';
import CustomSnackbar from '../../custom-snackbar';
import { AlertColor } from '@mui/material';
import { setMenuRiskCase } from '../../../slices/riskCasesSlice';
import { articleRiskCase } from '../../../slices/hitsSlice';
import { format } from 'date-fns';

interface Measure {
  riskMeasureId: number;
  comments: string;
  availableRiskMeasureLevels: any[];
  riskMeasureName: string;
  selectedLevelId: number;
  isRequired: boolean;
  selectedComment: string;
}

const RiskCasePopup = () => {
  const { id: keywordModelId } = useParams<{ id: string }>();

  const {
    data: riskCase,
    isPopupVisible,
    articleIds,
  } = useSelector((state: any) => state.discovery.riskCase);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    ...riskCase,
    keywordModels: [],
    suppliers: [],
    caseLogs: { caseLogs: [] },
  });
  const [owners, setOwners] = useState<SelectBoxOption[] | null>(null);
  const [suppliers, setSuppliers] = useState<SelectBoxOption[] | null>(null);
  const [kwm, setKwm] = useState<SelectBoxOption[] | null>(null);
  const [subscribers, setSubscribers] = useState<SelectBoxOption[] | null>(null);
  const [measures, setMeasures] = useState<Measure[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');

  const articles = useSelector((state: any) => state.hits.articleRiskCase || []);

  const fetchDataDropdown = async () => {
    try {
      const data = await RiskCaseApi.getOwnersDropdown();
      setOwners([...data]);

      const dataSubscribers = await RiskCaseApi.getSubscriberDropdown();
      setSubscribers([...dataSubscribers]);

      const dataSuppliers = await RiskCaseApi.getSuppliersDropdown();
      setSuppliers([...dataSuppliers]);

      const dataKwm = await RiskCaseApi.getKeywordModelsDropdown();
      setKwm([...dataKwm]);
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
    }
  };

  const loadPopupData = async () => {
    setIsLoading(true);
    try {
      const data = await RiskCaseApi.getRiskCaseTemplate(articleIds);
      setMeasures(data.measures.riskMeasures);
      setFormData({
        ...JSON.parse(JSON.stringify(riskCase)),
        subscribers: [...data.generalInformation.subscribers],
        suppliers: [...data.generalInformation.suppliers],
        keywordModels: [...(data.generalInformation?.keywordModels || [])],
        owner: data.generalInformation.owner,
        isActive: true,
      });

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching risk case template:', error);
    }
  };

  useEffect(() => {
    if (riskCase === null) return;
    fetchDataDropdown();
    loadPopupData();
  }, [riskCase]);

  const handleEdit = (updatedMeasure: Measure) => {
    setMeasures((prevMeasures) =>
      prevMeasures.map((measure) =>
        measure.riskMeasureId === updatedMeasure.riskMeasureId
          ? { ...measure, ...updatedMeasure }
          : measure
      )
    );
  };

  const sections = getSections({
    formData,
    setFormData,
    owners: owners || [],
    subscribers: subscribers || [],
    suppliers: suppliers || [],
    kwm: kwm || [],
    measures: measures || [],
    handleEdit,
    articles: articles || [],
  });

  const handleSave = async () => {
    if (!formData.riskCaseName.trim()) {
      setSnackbarMessage('The risk case name is required');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (!formData.owner) {
      setSnackbarMessage('You must select an owner');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const requiredMeasures = measures.filter((measure) => measure.isRequired);
    const incompleteMeasures = requiredMeasures.filter((measure) => !measure.selectedLevelId);

    if (incompleteMeasures.length > 0) {
      setSnackbarMessage(
        'You must complete the severity for the following measures: ' +
          incompleteMeasures.map((m) => m.riskMeasureName).join(', ')
      );
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const body = {
      generalInformation: {
        riskCaseId: formData.id,
        dateAdded: formData.dateCreated || new Date().toISOString(),
        dateUpdated: new Date().toISOString(),
        dateDeleted: null,
        isActive: !!formData.isActive,
        isDeleted: false,
        riskCaseName: formData.riskCaseName,
        comments: formData.comments,
        description: formData.description,
        owner: formData.owner
          ? {
              key: formData.owner.key,
              name: formData.owner.name || '',
            }
          : null,
        subscribers: Array.isArray(formData.subscribers)
          ? formData.subscribers.map((subscriber: any) => ({
              key: subscriber.key,
              name: subscriber.name,
            }))
          : [],
        suppliers: Array.isArray(formData.suppliers)
          ? formData.suppliers.map((supplier: any) => {
              if (typeof supplier === 'object' && supplier !== null) {
                return {
                  id: supplier.id,
                  label: supplier.label || '',
                };
              }
              const foundSupplier = suppliers?.find((s) => s.id === supplier);
              return {
                id: supplier,
                label: foundSupplier?.label || '',
              };
            })
          : [],
        keywordModels: Array.isArray(formData.keywordModels)
          ? formData.keywordModels.map((kwm: any) => ({
              id: kwm.id,
              label: kwm.label,
            }))
          : [],
      },
      measures: {
        riskMeasures: Array.isArray(measures)
          ? measures.map((measure) => {
              return {
                riskMeasureId: measure.riskMeasureId,
                riskMeasureName: measure.riskMeasureName,
                selectedLevelId: measure?.selectedLevelId,
                comments: measure?.comments || null,
                isRequired: measure.isRequired || false,
                availableRiskMeasureLevels: Array.isArray(measure.availableRiskMeasureLevels)
                  ? measure.availableRiskMeasureLevels.map((level) => ({
                      id: level.id,
                      label: level.label,
                    }))
                  : [],
              };
            })
          : [],
      },
      caseLogs: {
        caseLogs:
          formData.caseLogs?.caseLogs?.map((log: any) => ({
            riskCaseLogEntryId: log.riskCaseLogEntryId || 0,
            // date: format(new Date(log.date), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx') || new Date().toISOString(),
            authorFullName: log.authorFullName,
            entryText: log.entryText,
            files:
              log.files?.map((file: any) => ({
                riskCaseLogEntryId: log.riskCaseLogEntryId || 0,
                filename: file.filename,
                operationType: file.operationType,
                base64Data: file.base64Data,
              })) || [],
          })) || [],
      },
      articles: {
        articles: articles.map((article: any) => ({
          id: article.id,
          articleId: article.articleId,
          title: article.title,
          addDate: article.addDate,
          articleCategories: [],
          articleCompanies: [],
          articleEntities: [],
          articleKeywords: [],
          articleLinks: [],
          articleRiskCases: article.articleRiskCase,
          authorByLine: article.authorByLine,
          content: article.content,
          dateAdded: article.dateAdded,
          dateDeleted: article.dateDeleted,
          dateUpdated: article.dateUpdated,
          description: article.description,
          imageUrl: article.imageUrl,
          isActive: article.isActive,
          isDeleted: article.isDeleted,
          language: article.language,
          medium: article.medium,
          negativeSentiment: article.negativeSentiment,
          neutralSentiment: article.neutralSentiment,
          positiveSentiment: article.positiveSentiment,
          pubDate: article.pubDate,
          refreshDate: article.refreshDate,
          score: article.score,
          semanticSummary: article.semanticSummary,
          sourceDomain: article.sourceDomain,
          sourceLocation: article.sourceLocation,
          supplierArticles: [],
          url: article.url,
        })),
      },
    };

    setIsSaving(true);

    try {
      const data = await RiskCaseApi.updateRiskCase(body);
      dispatch(
        setRiskCase({
          data: JSON.parse(JSON.stringify(formData)),
          isPopupVisible: true,
        })
      );

      Promise.all([
        (async () => {
          const data = await RiskCaseApi.getRiskCasesMenu();
          dispatch(setMenuRiskCase(data));
        })(),
      ]);

      closePopup();

      setSnackbarMessage('Changes saved successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error(error);
      setSnackbarMessage('Failed to save changes');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsSaving(false);
    }
  };

  const closePopup = () => {
    dispatch(setRiskCase({ isPopupVisible: false, data: null, articleIds: null }));
    dispatch(articleRiskCase({ articles: [] }));
  };

  return (
    <>
      <Dialog
        open={isPopupVisible}
        onClose={closePopup}
        maxWidth="md"
        fullWidth
        className="risk-case-popup"
        PaperProps={{
          sx: {
            height: '600px',
            maxHeight: '600px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="div" sx={{ fontSize: '22px' }}>
            Risk Case
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closePopup}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            p: 2,
            overflowY: 'scroll',
            flex: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 transparent',
          }}
        >
          {isLoading ? (
            <div className="spinner">
              <CircularProgress />
            </div>
          ) : (
            <RiskCaseAccordion
              dataSource={sections}
              itemTitleRender={(item) => <Typography>{item.title}</Typography>}
              itemRender={(item) => <div>{item.content}</div>}
              id="accordion-container"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closePopup}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSaving}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default RiskCasePopup;
