import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedMenuItem: null,
};

const siteAdminSlice = createSlice({
  name: 'siteAdmin',
  initialState,
  reducers: {
    setSelectedMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload;
    },
  },
});

export const { setSelectedMenuItem } = siteAdminSlice.actions;
export default siteAdminSlice.reducer;
