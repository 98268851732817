import React from 'react';
import { Grid, Typography } from '@mui/material';
import AutoFittingTypography from './utils/AutoFittingTypography';

interface MetricCardProps {
  title: string;
  children: React.ReactNode;
  lastMonthText: string;
  lastMonthValue: number;
  isPositive: boolean | null;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  children,
  lastMonthText,
  lastMonthValue,
  isPositive,
}) => {
  return (
    <Grid item xs={12} sm={6} md={2}>
      <Typography
        variant="h6"
        align="center"
        sx={{
          lineHeight: '1.2', // Line height for each line
          height: '2.4em', // Total height to fit two lines based on the line height and font size
        }}
      >
        {title}
      </Typography>
      <AutoFittingTypography maxFontSize={60} minFontSize={16}>
        {children}
      </AutoFittingTypography>
      {lastMonthText && (
        <Typography align="center">
          <Typography
            component="span"
            color={isPositive === null ? 'inherit' : isPositive ? 'success.main' : 'error'}
            display="inline"
          >
            {lastMonthValue !== 0 && (lastMonthValue > 0 ? '▲ ' : '▼ ')}
            {lastMonthText}
          </Typography>{' '}
          from last month
        </Typography>
      )}
    </Grid>
  );
};

export default MetricCard;
