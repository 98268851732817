// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, WatchedPartDto } from '../types/types';
import { isNullOrWhitespace } from '../../utils/validation-helpers';
const { DMSMS_SERVICE_URL } = config;

const WatchedParts = {
  /**
   * Retrieves all watched parts
   * @returns
   */
  getWatchedParts: async function (
    supplierName?: string | null,
    isAtRisk?: boolean | null,
    lifeStage?: string | null
  ): Promise<ResponseDto<WatchedPartDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPart`;

    var needsQ = true;

    if (typeof supplierName !== 'undefined' && !isNullOrWhitespace(supplierName)) {
      addPrefix();
      url += `supplierName=${supplierName}`;
    }
    if (typeof lifeStage !== 'undefined' && !isNullOrWhitespace(lifeStage)) {
      addPrefix();
      url += `lifeStage=${lifeStage}`;
    }

    if (typeof isAtRisk !== 'undefined' && isAtRisk != null) {
      addPrefix();
      url += `isAtRisk=${isAtRisk}`;
    }

    const { data } = await httpClient.get(url);
    return data as ResponseDto<WatchedPartDto[]>;

    function addPrefix() {
      if (needsQ) {
        url += '?';
        needsQ = false;
      } else {
        url += '&';
      }
    }
  },

  /**
   * Retrieves all watched parts that have a specific action
   * @returns
   */
  getWatchedPartsForAction: async function (
    actionId: number
  ): Promise<ResponseDto<WatchedPartDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPart/ForAction/${actionId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<WatchedPartDto[]>;
  },

  /**
   * Retrieves a watched part.
   * @returns
   */
  getWatchedPart: async function (watchedPartId: number): Promise<ResponseDto<WatchedPartDto>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPart/${watchedPartId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<WatchedPartDto>;
  },

  updateWatchedPart: async function (part: WatchedPartDto): Promise<ResponseDto<WatchedPartDto>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPart`;
    const { data } = await httpClient.put(url, part);
    return data as ResponseDto<WatchedPartDto>;
  },

  /**
   * Updates the 'at tisk' status of a part.
   * @returns
   */
  updateWatchedPartAtRisk: async function (
    watchedPartId: number,
    isAtRisk: boolean
  ): Promise<ResponseDto<WatchedPartDto>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPart/UpdateAtRisk/${watchedPartId}/${isAtRisk}`;
    const { data } = await httpClient.patch(url);
    return data as ResponseDto<WatchedPartDto>;
  },
  /**
   * Updates the life stage of a part.
   * @returns
   */
  updateWatchedPartLifeStage: async function (
    watchedPartId: number,
    lifeStage: string
  ): Promise<ResponseDto<WatchedPartDto>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPart/UpdateLifeStage/${watchedPartId}/${lifeStage}`;
    const { data } = await httpClient.patch(url);
    return data as ResponseDto<WatchedPartDto>;
  },

  /**
   * Adds an action to selected watche parts.
   * @returns
   */
  addActionToWatchedParts: async function (
    actionId: number,
    watchedPartIds: number[]
  ): Promise<ResponseDto<WatchedPartDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPart/AddAction/${actionId}/ToParts`;

    const formData = new FormData();

    // Costruct items list for form data.
    for (var i = 0; i < watchedPartIds.length; i++) {
      const item = watchedPartIds[i];
      formData.append(`watchedPartIds[${i}]`, `${item}`);
    }

    const { data } = await httpClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data as ResponseDto<WatchedPartDto[]>;
  },
};

export default WatchedParts;
