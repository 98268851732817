// @ts-ignore
import SuppliersAPI from '../../api/suppliers/suppliersAPI';
// @ts-ignore
import { addSuppliers, toggleShowOnlyActiveSwitch } from '../../slices/suppliersSlice';
import React, { useEffect, useState } from 'react';
import { Button, Box, Collapse, Switch, FormControlLabel } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from 'react-redux';

const SuppliersFilter = () => {
  const showOnlyActive = useSelector((state: any) => state.suppliers.showOnlyActive);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const toggleFilters = () => {
    setFiltersOpen((prev) => !prev);
  };

  const toggleActiveSwitch = () => {
    dispatch(toggleShowOnlyActiveSwitch());
  };

  const fetchSuppliers = async () => {
    try {
      const data = await SuppliersAPI.getAllSuppliers(showOnlyActive);
      dispatch(addSuppliers({ allSuppliers: data }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, [showOnlyActive]);

  return (
    <Box sx={{ mb: 3 }}>
      <Button
        variant="outlined"
        color={filtersOpen ? 'primary' : 'inherit'}
        startIcon={<TuneIcon />}
        onClick={toggleFilters}
      >
        Filters
      </Button>

      <Collapse in={filtersOpen} timeout="auto">
        <Box sx={{ mt: 3, p: 1 }}>
          <FormControlLabel
            control={<Switch checked={showOnlyActive} onChange={toggleActiveSwitch} />}
            label="Show only Active suppliers"
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default SuppliersFilter;
