import { Box, Typography } from '@mui/material';
import React from 'react';
import ColorGrid from './ColorGrid';

const OtherColors = () => {
  return (
    <>
      <Box pb={2} pt={5}>
        <Typography variant="h6" fontWeight="bold">
          Other Colors
        </Typography>
        <Typography variant="body2" pb={2}>
          Other colors can be used to color code Altruity elements such as Keyword Models, Risk
          Measures, Supplier Weights. You can create as many Other Colors as you would like.
        </Typography>

        <ColorGrid />
      </Box>
    </>
  );
};

export default OtherColors;
