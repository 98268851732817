import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
  Button,
  IconButton,
  Paper,
  Divider,
  Tooltip,
  Snackbar,
  Alert,
  SnackbarCloseReason,
  Grid,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import { AppResourceDto, RoleDto, RoleResourceDto, UserDto } from '../../../../api/types/types';
import UserAdminAPI from '../../../../api/userAdmin/userAdminAPI';
import { SnackbarProps } from '../../../../types/Snackbar';
import '../RolesTable.scss';
import DeleteConfirmationDialog from '../../../data-grid/DeleteConfirmationDialog';

interface PermissionsAndUsersProps {
  role: RoleDto;
  appResources: AppResourceDto[];
  roleResources: RoleResourceDto[];
  setRoleResources: React.Dispatch<React.SetStateAction<RoleResourceDto[]>>;
}

const PermissionsAndUsers = ({
  role,
  appResources,
  roleResources,
  setRoleResources,
}: PermissionsAndUsersProps) => {
  const [snackbar, setSnackbar] = useState<SnackbarProps>();
  const [roleUsers, setRoleUsers] = useState<UserDto[]>([]);
  const [users, setUsers] = useState<UserDto[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);

  const handleCheckboxChange = (resourceId: number, permission: keyof RoleResourceDto) => {
    setRoleResources((prevResources) =>
      prevResources.map((resource) =>
        resource.id === resourceId ? { ...resource, [permission]: !resource[permission] } : resource
      )
    );
  };

  const cleanForm = () => {
    setIsAdding(false);
    setSelectedUser(null);
  };

  const savePermissions = async () => {
    try {
      const response = await UserAdminAPI.updateRoleResources(
        role.id,
        roleResources.filter((rr) => rr.roleId === role.id)
      );

      setSnackbar({
        open: true,
        severity: 'success',
        message: response.message.length ? response.message : 'Entity updated.',
      });
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || 'An error occurred';

      setSnackbar({
        open: true,
        severity: 'error',
        message: errorMessage,
      });
    }
  };

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  const fetchRoleUsers = async () => {
    const roleUsersResponse = await UserAdminAPI.getAllUsersByRole(role.id);
    setRoleUsers(roleUsersResponse);
  };

  useEffect(() => {
    if (!role) return;

    fetchRoleUsers();

    UserAdminAPI.getAllUsers().then((response) => {
      setUsers(response as UserDto[]);
    });
  }, [role]);

  const handleRemoveUser = async () => {
    try {
      await UserAdminAPI.removeUserFromRole(deleteUserId!, role.id);

      fetchRoleUsers();

      setSnackbar({
        open: true,
        severity: 'success',
        message: 'User removed from role',
      });
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || 'An error occurred';

      setSnackbar({
        open: true,
        severity: 'error',
        message: errorMessage,
      });
    }
  };

  const handleAddNewUser = async () => {
    if (!selectedUser) return;

    try {
      await UserAdminAPI.addSingleUserToRole(selectedUser, role.id);

      setSnackbar({
        open: true,
        severity: 'success',
        message: 'User added to role.',
      });

      cleanForm();

      fetchRoleUsers();
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || 'An error occurred';

      setSnackbar({
        open: true,
        severity: 'error',
        message: errorMessage,
      });
    }
  };

  const closeDialog = () => {
    setDeleteUserId(null);
    setOpenDeleteDialog(false);
  };

  const confirmDelete = async () => {
    if (deleteUserId != null) {
      handleRemoveUser();

      closeDialog();
    }
  };

  const cancelDelete = () => {
    closeDialog();
  };

  return (
    <>
      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={snackbar?.severity === 'error' ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />

      <Box display="flex" justifyContent="space-between" py={3}>
        {/* Permissions Table */}
        <Box width="48%">
          <Typography variant="h6" gutterBottom pb={1}>
            Permissions for {role.roleName}
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ height: '55px' }}>
                  <TableCell></TableCell>
                  <TableCell align="center">Add/Edit</TableCell>
                  <TableCell align="center">View</TableCell>
                  <TableCell align="center">Archive</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appResources.map((appResource: AppResourceDto) => {
                  const roleResource = roleResources.find(
                    (rr) => rr.appResourceId === appResource.id && rr.roleId === role.id
                  );

                  return (
                    <TableRow key={appResource.id}>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {appResource.resourceName}
                          {appResource.resourceName === 'Article Hits' && (
                            <Tooltip
                              title={`The system will surface Article Hits. They are not created by users. The Edit permission here allows a user to create Hit Collection`}
                              placement="right"
                            >
                              <HelpIcon sx={{ fontSize: 18, ml: 0.5, color: 'gray' }} />
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {appResource.hasAddEdit && (
                          <Checkbox
                            checked={roleResource?.canAddEdit}
                            onChange={() => handleCheckboxChange(roleResource!.id, 'canAddEdit')}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {appResource.hasView && (
                          <Checkbox
                            checked={roleResource?.canView}
                            onChange={() => handleCheckboxChange(roleResource!.id, 'canView')}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {appResource.hasArchive && (
                          <Checkbox
                            checked={roleResource?.canArchive}
                            onChange={() => handleCheckboxChange(roleResource!.id, 'canArchive')}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" pt={3}>
            <Button variant="contained" color="primary" onClick={savePermissions}>
              Save
            </Button>
          </Box>
        </Box>

        {/* Vertical Divider */}
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

        {/* Risk Owner Users */}
        <Box width="48%">
          <Grid container pb={1}>
            <Grid item xs={11}>
              <Typography variant="h6" pt={'2px'}>
                {role.roleName} Users
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box display="flex" justifyContent="flex-end">
                <Tooltip title="Add a row">
                  <IconButton
                    onClick={() => {
                      setIsAdding(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ height: '55px' }}>
                  <TableCell>Name</TableCell>
                  {isAdding && <TableCell>Actions</TableCell>}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isAdding && (
                  <TableRow className="permissions-table-new-row">
                    <TableCell style={{ width: '100%' }}>
                      <FormControl fullWidth>
                        <Select
                          value={selectedUser}
                          fullWidth
                          onChange={(e) => {
                            setSelectedUser(Number(e.target.value));
                          }}
                        >
                          {users.map((user: UserDto) => (
                            <MenuItem key={user.id} value={user.id}>
                              {user.fullName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap', width: '1%' }}>
                      <Button onClick={handleAddNewUser} disabled={!selectedUser}>
                        Save
                      </Button>
                      <Button onClick={cleanForm}>Cancel</Button>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {roleUsers.length
                  ? roleUsers.map((user: UserDto) => (
                      <TableRow key={user.id}>
                        <TableCell height={'55px'}>{user.fullName}</TableCell>
                        <TableCell align="right">
                          <Button
                            color="primary"
                            onClick={() => {
                              setDeleteUserId(user.id);
                              setOpenDeleteDialog(true);
                            }}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  : !isAdding && (
                      <TableRow>
                        <TableCell colSpan={12} sx={{ textAlign: 'center', height: '50px' }}>
                          No Data Row
                        </TableCell>
                      </TableRow>
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default PermissionsAndUsers;
