import React from 'react';
import { TextField, FormControl, Box } from '@mui/material';
import HelpIcon from '../help/HelpIcon.jsx';

export default function AltruityTextArea({
  label,
  width,
  onChange,
  helpConent,
  inputRef,
  rows,
  ...props
}) {
  return (
    <FormControl className="altruity-field" style={{ width: width }}>
      <Box display="flex">
        <TextField
          {...props}
          label={label}
          multiline
          rows={rows ?? 4}
          onChange={onChange}
          inputRef={inputRef}
        />{' '}
        {helpConent ? <HelpIcon label={label}>{helpConent}</HelpIcon> : null}
      </Box>
    </FormControl>
  );
}
