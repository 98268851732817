import React, { useEffect, useRef, useState } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface AutoFittingTypographyProps extends Omit<TypographyProps, 'variant'> {
  maxFontSize?: number;
  minFontSize?: number;
}

const AutoFittingTypography: React.FC<AutoFittingTypographyProps> = ({
  children,
  maxFontSize = 48,
  minFontSize = 12,
  ...props
}) => {
  const [fontSize, setFontSize] = useState(maxFontSize);
  const textRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const checkTextFit = () => {
    const element = textRef.current;
    const container = containerRef.current;
    if (!element || !container) return;

    // Reset font size to max to get proper measurements
    let currentSize = maxFontSize;
    element.style.fontSize = `${currentSize}px`;

    // Binary search for the right font size
    let low = minFontSize;
    let high = maxFontSize;

    while (low <= high) {
      currentSize = Math.floor((low + high) / 2);
      element.style.fontSize = `${currentSize}px`;

      if (element.scrollWidth <= container.clientWidth) {
        // Text fits, try a larger size
        if (low === high) break;
        low = currentSize + 1;
      } else {
        // Text doesn't fit, try a smaller size
        high = currentSize - 1;
      }
    }

    // Set to the last known good size
    currentSize = high;
    setFontSize(currentSize);
  };

  useEffect(() => {
    checkTextFit();

    const handleResize = () => {
      checkTextFit();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [children, maxFontSize, minFontSize]);

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      <Typography
        ref={textRef}
        variant="h3"
        align="center"
        {...props}
        style={{
          fontSize: `${fontSize}px`,
          whiteSpace: 'nowrap',
          ...props.style,
        }}
      >
        {children}
      </Typography>
    </div>
  );
};

export default AutoFittingTypography;
