import React, { useEffect, useState } from 'react';
import SelectBoxOption from '../../../types/SelectBoxOption';
import { Button, Box, CircularProgress } from '@mui/material';
import RiskCaseApi from '../../../api/riskCase/riskCaseApi';
import { useParams } from 'react-router';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import MeasuresTableRiskCase from './measureTableRiskCase';
import GeneralInformationRiskCase from './generalInformation';
import { ConstructionOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { setRiskCurrentCaseName, updateRiskCaseName } from '../../../slices/riskCasesSlice';
import CustomSnackbar from '../../custom-snackbar';
import { AlertColor } from '@mui/material';

interface Measure {
  riskMeasureId: number;
  description: string;
  availableRiskMeasureLevels: RiskMeasureLevel[];
  riskMeasureName: string;
  isRequired?: boolean;
}
interface RiskMeasureLevel {
  id: number;
  label: string;
  comments: string;
  selectedLevelId: number;
  isRequired: boolean;
}
interface Owner {
  key: string;
  name: string;
}

interface RiskCaseOverviewProps {
  generalInformation: {
    riskCaseId: number;
    dateAdded: string;
    dateUpdated: string;
    dateDeleted: null;
    isActive: boolean;
    isDeleted: boolean;
    riskCaseName: string;
    comments: string;
    description: string;
    owner: Owner | null;
    subscribers: any[];
    suppliers: any[];
    keywordModels: any[];
  };
  measures: Measure[];
  updateGeneralInformation: (newData: Partial<RiskCaseOverviewProps['generalInformation']>) => void;
  updateMeasures: (newMeasures: Measure[]) => void;
}

const RiskCaseOverview: React.FC<RiskCaseOverviewProps> = ({
  generalInformation,
  measures,
  updateGeneralInformation,
  updateMeasures,
}) => {
  const [suppliers, setSuppliers] = useState<SelectBoxOption[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');

  const { id } = useParams();

  useEffect(() => {
    setIsLoading(false);
  }, [generalInformation, measures]);

  const numericId = Number(id);

  const formatKwm = (kwmData: any[]) => {
    return kwmData.map((item) => (typeof item === 'object' ? item : { id: item, label: '' }));
  };

  const fetchRiskCase = async () => {
    try {
      setIsLoading(true);
      const data = await RiskCaseApi.getRiskCaseById(numericId);
      updateMeasures(data.measures.riskMeasures);

      const formattedSuppliers = data?.generalInformation?.suppliers
        ? [].concat(...data.generalInformation.suppliers)
        : [];

      updateGeneralInformation({
        riskCaseId: data?.generalInformation?.riskCaseId,
        riskCaseName: data?.generalInformation?.riskCaseName,
        suppliers: formattedSuppliers,
        owner: data?.generalInformation?.owner,
        dateAdded: data?.generalInformation.dateAdded,
        isActive: data?.generalInformation.isActive,
        description: data?.generalInformation.description,
        keywordModels: formatKwm(data?.generalInformation.keywordModels),
        subscribers: data?.generalInformation.subscribers,
      });
    } catch (error) {
      console.error('Error fetching risk case:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRiskCase();
  }, []);

  const handleEdit = (updatedMeasure: Partial<Measure>) => {
    const updatedMeasures = measures.map((measure) =>
      measure.riskMeasureId === updatedMeasure.riskMeasureId
        ? { ...measure, ...updatedMeasure }
        : measure
    );
    updateMeasures(updatedMeasures);
  };

  return (
    <div className="overview-container">
      {isLoading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <GeneralInformationRiskCase
            isLoading={isLoading}
            formData={generalInformation}
            setFormData={updateGeneralInformation}
          />
          <h3 className="measures-title" style={{ paddingLeft: '20px' }}>
            Measures
          </h3>
          <MeasuresTableRiskCase measures={measures} onEdit={handleEdit} />
          <CustomSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={() => setSnackbarOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

export default RiskCaseOverview;
