// import React, { useState } from 'react';
// import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceDot } from 'recharts';
// import { HitsAgingViewModel } from '../../../../api/types/types';
// import PopupArticle from '../../../../components/risk-discovery/risk-discovery-popup/PopupArticle';

// const HitsKeywordModelChart = ({ hitsAgingData }: { hitsAgingData: HitsAgingViewModel }) => {
//   const [selectedArticle, setSelectedArticle] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const [hoveredCircleData, setHoveredCircleData] = useState<{
//     x: number;
//     y: number;
//     value: number;
//   } | null>(null);

//   const data = hitsAgingData.byKeywordModel.map((keywordModel) => ({
//     name: keywordModel.name,
//     avgDays: keywordModel.avgDays,
//     oldestHitDays: keywordModel.oldestHitDays,
//     id: keywordModel.id,
//     oldestHit: keywordModel.oldestHit,
//   }));

//   const handleBarClick = (data: any) => {
//     window.open(`/scrm/discovery/kwm/${data.id}`, '_blank');
//   };

//   const handleCircleClick = (article: any) => {
//     setSelectedArticle(article);
//     setShowPopup(true);
//   };

//   const handleMouseEnterCircle = (x: number, y: number, value: number) => {
//     setHoveredCircleData({ x, y, value });
//   };

//   const handleMouseLeaveCircle = () => {
//     setHoveredCircleData(null);
//   };

//   return (
//     <div className="chart-container" style={{ position: 'relative' }}>
//       <ResponsiveContainer width="100%" height={300}>
//         <BarChart
//           data={data}
//           layout="vertical"
//           margin={{ top: 0, right: 30, left: 25, bottom: 20 }}
//           barCategoryGap="10%"
//         >
//           <XAxis
//             type="number"
//             domain={[0, 90]}
//             ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90]}
//             label={{ value: 'Days', position: 'insideBottom', offset: -15 }}
//           />
//           <YAxis
//             dataKey="name"
//             type="category"
//             tick={{ fontSize: 12 }}
//             tickFormatter={(name) => (name.length > 25 ? `${name.substring(0, 25)}...` : name)}
//           />
//           <Tooltip
//             cursor={{ fill: 'transparent' }}
//             content={({ active, payload }) =>
//               active &&
//               payload &&
//               payload.length &&
//               payload[0] &&
//               payload[0].value &&
//               payload[0].payload &&
//               payload[0].payload.avgDays ? (
//                 <div
//                   style={{
//                     backgroundColor: '#fff',
//                     padding: '5px',
//                     border: '1px solid #ccc',
//                     borderRadius: '4px',
//                   }}
//                 >
//                   {payload[0].value <= payload[0].payload.avgDays
//                     ? `${payload[0].value} Days`
//                     : null}
//                 </div>
//               ) : null
//             }
//           />

//           <Bar
//             dataKey="avgDays"
//             fill="#005B8C"
//             barSize={20}
//             onClick={handleBarClick}
//             className="no-shadow"
//             style={{ cursor: 'pointer' }}
//           />
//           {data.map((entry, index) => (
//             <ReferenceDot
//               key={`circle-${index}`}
//               x={entry.oldestHitDays}
//               y={entry.name}
//               r={5}
//               fill="#0095C8"
//               onClick={() => handleCircleClick(entry.oldestHit)}
//               onMouseEnter={() =>
//                 handleMouseEnterCircle(
//                   (entry.oldestHitDays / 90) * 100,
//                   index * 40 + 100,
//                   entry.oldestHitDays
//                 )
//               }
//               onMouseLeave={handleMouseLeaveCircle}
//             />
//           ))}
//         </BarChart>
//       </ResponsiveContainer>
//       {hoveredCircleData && (
//         <div
//           style={{
//             position: 'absolute',
//             top: hoveredCircleData.y + 10,
//             left: `${hoveredCircleData.x}%`,
//             backgroundColor: '#fff',
//             padding: '5px 8px',
//             border: '1px solid #ccc',
//             borderRadius: '4px',
//             pointerEvents: 'none',
//             fontSize: '12px',
//             color: '#333',
//             transform: 'translateX(10px)',
//             width: '64px',
//           }}
//         >
//           {`${hoveredCircleData.value} Days`}
//         </div>
//       )}
//       {showPopup && selectedArticle && (
//         <PopupArticle
//           onClose={() => setShowPopup(false)}
//           articles={[selectedArticle]}
//           currentIndex={0}
//           setCurrentIndex={() => {}}
//         />
//       )}
//     </div>
//   );
// };

// export default HitsKeywordModelChart;

import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceDot } from 'recharts';
import { HitsAgingViewModel } from '../../../../api/types/types';
import PopupArticle from '../../../../components/risk-discovery/risk-discovery-popup/PopupArticle';

const HitsKeywordModelChart = ({ hitsAgingData }: { hitsAgingData: HitsAgingViewModel }) => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [hoveredCircleData, setHoveredCircleData] = useState<{
    x: number;
    y: number;
    value: number;
  } | null>(null);
  const [hoveredBar, setHoveredBar] = useState(false); // Estado para saber si estamos sobre una barra

  const data = hitsAgingData.byKeywordModel.map((keywordModel) => ({
    name: keywordModel.name,
    avgDays: keywordModel.avgDays,
    oldestHitDays: keywordModel.oldestHitDays,
    id: keywordModel.id,
    oldestHit: keywordModel.oldestHit,
  }));

  const handleBarClick = (data: any) => {
    window.open(`/scrm/discovery/kwm/${data.id}`, '_blank');
  };

  const handleCircleClick = (article: any) => {
    setSelectedArticle(article);
    setShowPopup(true);
  };

  const handleMouseEnterCircle = (x: number, y: number, value: number) => {
    setHoveredCircleData({ x, y, value });
  };

  const handleMouseLeaveCircle = () => {
    setHoveredCircleData(null);
  };

  const handleMouseEnterBar = () => {
    setHoveredBar(true);
  };

  const handleMouseLeaveBar = () => {
    setHoveredBar(false);
  };

  return (
    <div className="chart-container" style={{ position: 'relative' }}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{ top: 0, right: 30, left: 25, bottom: 20 }}
          barCategoryGap="10%"
        >
          <XAxis
            type="number"
            domain={[0, 90]}
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90]}
            label={{ value: 'Days', position: 'insideBottom', offset: -15 }}
          />
          <YAxis
            dataKey="name"
            type="category"
            tick={{ fontSize: 12 }}
            tickFormatter={(name) => (name.length > 25 ? `${name.substring(0, 25)}...` : name)}
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={({ active, payload }) =>
              active &&
              hoveredBar && // Mostrar el tooltip solo si estamos sobre la barra
              payload &&
              payload.length &&
              payload[0] &&
              payload[0].value &&
              payload[0].payload &&
              payload[0].payload.avgDays ? (
                <div
                  style={{
                    backgroundColor: '#fff',
                    padding: '5px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                  }}
                >
                  {payload[0].value <= payload[0].payload.avgDays
                    ? `${payload[0].value} Days`
                    : null}
                </div>
              ) : null
            }
          />

          <Bar
            dataKey="avgDays"
            fill="#005B8C"
            barSize={20}
            onClick={handleBarClick}
            onMouseEnter={handleMouseEnterBar} // Detectar cuando el mouse entra en la barra
            onMouseLeave={handleMouseLeaveBar} // Detectar cuando el mouse sale de la barra
            className="no-shadow"
            style={{ cursor: 'pointer' }}
          />
          {data.map((entry, index) => (
            <ReferenceDot
              key={`circle-${index}`}
              x={entry.oldestHitDays}
              y={entry.name}
              r={5}
              fill="#0095C8"
              onClick={() => handleCircleClick(entry.oldestHit)}
              onMouseEnter={() =>
                handleMouseEnterCircle(
                  (entry.oldestHitDays / 90) * 90,
                  index + 70,
                  entry.oldestHitDays
                )
              }
              onMouseLeave={handleMouseLeaveCircle}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      {hoveredCircleData && (
        <div
          style={{
            position: 'absolute',
            top: hoveredCircleData.y + 10,
            left: `${hoveredCircleData.x}%`,
            backgroundColor: '#fff',
            padding: '5px 8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            pointerEvents: 'none',
            fontSize: '12px',
            color: '#333',
            transform: 'translateX(10px)',
            width: '64px',
          }}
        >
          {`${hoveredCircleData.value} Days`}
        </div>
      )}
      {showPopup && selectedArticle && (
        <PopupArticle
          onClose={() => setShowPopup(false)}
          articles={[selectedArticle]}
          currentIndex={0}
          setCurrentIndex={() => {}}
        />
      )}
    </div>
  );
};

export default HitsKeywordModelChart;
