import React from 'react';
import { Typography } from '@mui/material';
import './index.scss';
import VendorAutoLoad from '../../components/scrm/admin/vendor-auto-load/VendorAutoLoad';

const VendorAutoLoadAdmin = () => {
  return (
    <div className="admin">
      <Typography className="title">Vendor Auto-Load</Typography>
      <VendorAutoLoad />
    </div>
  );
};

export default VendorAutoLoadAdmin;
