// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, SubstitutePartDto } from '../types/types';
const { DMSMS_SERVICE_URL } = config;

const SubstituteParts = {
  /**
   * Retrieves all substitute parts.
   * @returns
   */
  getSubstituteParts: async function (): Promise<ResponseDto<SubstitutePartDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/SubstitutePart`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<SubstitutePartDto[]>;
  },
  /**
   * Retrieves all substitute parts.
   * @returns
   */
  getSubstitutePartsForWatchedPart: async function (
    watchedPartId: number
  ): Promise<ResponseDto<SubstitutePartDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/SubstitutePart/ForWatchedPart/${watchedPartId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<SubstitutePartDto[]>;
  },
  /**
   * Retrieves a substitute parts.
   * @returns
   */
  getSubstitutePart: async function (
    substitutePartId: number
  ): Promise<ResponseDto<SubstitutePartDto>> {
    var url = `${DMSMS_SERVICE_URL}/SubstitutePart/${substitutePartId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<SubstitutePartDto>;
  },

  /**
   * Creates a substitute part
   * @param part
   */
  createSubstitutePart: async function (
    part: SubstitutePartDto
  ): Promise<ResponseDto<SubstitutePartDto>> {
    var url = `${DMSMS_SERVICE_URL}/SubstitutePart`;
    const { data } = await httpClient.post(url, part);
    return data as ResponseDto<SubstitutePartDto>;
  },

  /**
   * Updates a substitute part
   * @param part
   */
  updateSubstitutePart: async function (
    part: SubstitutePartDto
  ): Promise<ResponseDto<SubstitutePartDto>> {
    var url = `${DMSMS_SERVICE_URL}/SubstitutePart`;
    const { data } = await httpClient.put(url, part);
    return data as ResponseDto<SubstitutePartDto>;
  },

  /**
   * Deletes a substitute part
   * @param part
   */
  deleteSubstitutePart: async function (substitutePartId: number): Promise<ResponseDto<any>> {
    var url = `${DMSMS_SERVICE_URL}/SubstitutePart/${substitutePartId}`;
    const { data } = await httpClient.delete(url);
    return data as ResponseDto<any>;
  },
};

export default SubstituteParts;
