import { Typography } from '@mui/material';
import RolesTable from '../../components/Site-Admin/Roles/RolesTable';
import React from 'react';
// import "./Roles.scss";

const Roles = () => {
  return (
    <div className="site-admin-container">
      <Typography className="title">Roles</Typography>
      <RolesTable />
    </div>
  );
};

export default Roles;
