import React, { useEffect, useState } from 'react';
import {
  UserDto,
  WatchedPartCaseLogEntryDto,
  WatchedPartCaseLogEntryFileDto,
} from '../../../../api/types/types';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridDensity,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
  GridRowsProp,
  GridSlots,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import {
  Button,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SnackbarCloseReason,
  Tooltip,
} from '@mui/material';
import { Add, AttachFile, Delete, Edit, GetApp } from '@mui/icons-material';
import { ValidationSnackbar } from '../../../../components/form';
import ConfirmDeleteDialog from '../../../../components/form/dialogs/ConfirmDeleteDialog';
import { CaseLogDialog, WatchedPartCaseLogEntry, FileData } from './dialogs/CaseLogDialog';
import PermissionsApi from '../../../../api/permissions/permissionsAPI';
import DmsmsApi from '../../../../api/dmsms/dmsmsAPI';
import { createColumn } from '../../../../utils/data-grid';
import { useSelector } from 'react-redux';

interface PartActionsGridGridProps {
  watchedPartId: number;
  density?: GridDensity | undefined;
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

export default function CaseLogGrid({ watchedPartId, density }: PartActionsGridGridProps) {
  const name = useSelector((state: any) => state.auth.user);
  const defaultEntry: WatchedPartCaseLogEntry = {
    watchedPartCaseLogEntryId: 0,
    date: new Date().toLocaleDateString(),
    authorFullName: name,
    entryText: '',
    files: [],
  };
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
    severity: 'error',
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null);
  const [caseLogOpen, setCaseLogOpen] = useState(false);
  const [caseLogEntry, setCaseLogEntry] = useState<WatchedPartCaseLogEntry>(defaultEntry);
  const [users, setUsers] = useState<UserDto[]>([]);

  function handleCaseLogClose() {
    setCaseLogOpen(false);
  }

  /***
   * Saves cage log.
   */
  async function handleCaseLogSave(newEntry: WatchedPartCaseLogEntry) {
    const id = newEntry.watchedPartCaseLogEntryId;
    try {
      // Create
      if (id === 0) {
        const response = await DmsmsApi.createWatchedPartCaseLogEntry(
          getDtoFromGridRowModel(newEntry)
        );

        if (response.isSuccess) {
          const updatedRow = { ...response.result };
          setRows((prevState) => [...prevState, updatedRow]);
          setSnackBar('Row added', 'success');
          setCaseLogOpen(false);
        } else {
          console.error(response.message);
          setSnackBar(response.message);
        }
        // Update
      } else {
        const response = await DmsmsApi.updateWatchedPartCaseLogEntry(
          getDtoFromGridRowModel(newEntry)
        );

        if (response.isSuccess) {
          const updatedRow = { ...response.result };
          setRows(rows.map((row) => (row.id === id ? updatedRow : row)));
          setSnackBar('Row updated', 'success');
          setCaseLogOpen(false);
        } else {
          console.error(response.message);
          setSnackBar(response.message);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackBar('An error occurred.');
    }
  }

  /**
   * Closes the validation SnackBar
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
      message: '',
    }));
  };

  /**
   *
   * @param props Tootlar for grid
   * @returns
   */
  function EditToolbar(props: EditToolbarProps) {
    const handleClick = () => {
      setCaseLogEntry(defaultEntry);
      setCaseLogOpen(true);
    };

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          sx={{ m: 1 }}
          variant="contained"
          startIcon={<Add />}
          onClick={handleClick}
        >
          Add record
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  /**
   * Converts DTO to object for dialog
   * @param entry
   */
  const handleEditClick = (id: GridRowId) => () => {
    const watchedPartCaseLogEntryId: number = id as number;
    const entry = rows.find((c) => c.id === watchedPartCaseLogEntryId);

    if (!entry) {
      setCaseLogEntry(defaultEntry);
      setCaseLogOpen(true);
      return;
    }

    const author = users.find((u) => u.id === entry.authorId);

    const editEntry: WatchedPartCaseLogEntry = {
      watchedPartCaseLogEntryId: entry.id,
      entryText: entry.entryText,
      date: new Date(entry.dateAdded).toLocaleDateString(),
      authorFullName: author?.fullName ?? '',
      files: [],
    };

    for (var i = 0; i < entry.files.length; i++) {
      const file = entry.files[i] as WatchedPartCaseLogEntryFileDto;
      const editFile: FileData = {
        filename: file.filename,
        watchedPartCaseLogEntryFileId: file.id,
        base64Data: file.data,
        watchedPartCaseLogEntryId: entry.id,
        operationType: '',
      };

      editEntry.files.push(editFile);
    }

    setCaseLogEntry(editEntry);
    setCaseLogOpen(true);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setConfirmDeleteId(id as number);
    setOpenConfirmDialog(true);
  };

  async function deleteLogEntry(id: number) {
    try {
      const response = await DmsmsApi.deleteWatchedPartCaseLogEntry(id as number);

      if (response.isSuccess) {
        setRows(rows.filter((row) => row.id !== id));
        setConfirmDeleteId(null);
        setOpenConfirmDialog(false);
        setSnackBar('Row deleted', 'success');
      } else {
        console.error(response.message);
        setSnackBar(response.message);
      }
    } catch (error) {
      console.error(error);
      setSnackBar('An error occurred.');
    }
  }

  /**
   *
   * @param id
   */
  const handleDelete = async (id: number) => {
    deleteLogEntry(id);
  };

  /**
   * Transforms a GridRowModel into a SubstitutePartDto
   * @param row
   * @returns
   */
  function getDtoFromGridRowModel(entry: WatchedPartCaseLogEntry): WatchedPartCaseLogEntryDto {
    const newEntry = {
      id: entry.watchedPartCaseLogEntryId,
      entryText: entry.entryText,
      watchedPartId: watchedPartId,
      files: [] as WatchedPartCaseLogEntryFileDto[],
    } as WatchedPartCaseLogEntryDto;

    for (var i = 0; i < entry.files.length; i++) {
      const file = entry.files[i];
      newEntry.files.push(getFileDtoFromGridRowModel(entry.watchedPartCaseLogEntryId, file));
    }

    return newEntry;
  }

  /**
   * Transforms a GridRowModel into a SubstitutePartDto
   * @param row
   * @returns
   */
  function getFileDtoFromGridRowModel(
    watchedPartCageLogEntryId: number,
    entry: FileData
  ): WatchedPartCaseLogEntryFileDto {
    return {
      id: entry.watchedPartCaseLogEntryFileId,
      watchedPartCageLogEntryId: watchedPartCageLogEntryId,
      filename: entry.filename,
      data: entry.base64Data,
    } as WatchedPartCaseLogEntryFileDto;
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /**
   * Triggers the snackbar
   * @param message
   * @param severity
   */
  function setSnackBar(message: string, severity: string = 'error') {
    setSnackBarState((oldState) => ({
      ...oldState,
      open: true,
      message: message,
      severity: severity,
    }));
  }

  const handleDownload = async (file: any) => {
    try {
      const response = await DmsmsApi.getWatchedPartCaseLogEntryFile(file.id);

      if (response.isSuccess) {
        const base64Data = response.result.data;

        const base64String = base64Data.split(',')[1] || base64Data;
        const byteCharacters = atob(base64String);

        const byteNumbers = new Uint8Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], {
          type: 'application/octet-stream',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = response.result.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setSnackBar(response.message);
      }
    } catch (error) {
      console.error('Error en la descarga:', error);
      setSnackBar('An error occurred');
    }
  };

  /**
   * Retrieve grid data
   */
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await DmsmsApi.getCaseLogEntriesForWatchedPart(watchedPartId);

        if (response.isSuccess) {
          setRows(response.result);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    async function fetchUsers() {
      try {
        const response = await PermissionsApi.getAllUsers(false);
        setUsers(response.result);
      } catch (error) {
        console.error(error);
      }
    }
    fetchUsers();
    fetchData();
  }, [watchedPartId]);

  // Columns for grid
  const columns: GridColDef[] = [
    {
      ...createColumn('dateAdded', 'Date'),
      width: 200,
      flex: 0,
      editable: true,
      valueFormatter: (value: string) => {
        if (value) {
          value += 'Z';
          return new Date(value).toLocaleString();
        } else {
          return undefined;
        }
      },
    },
    {
      field: 'entryText',
      headerName: 'Text',
      flex: 1,
    },
    {
      field: 'authorId',
      headerName: 'Author',
      type: 'singleSelect',
      width: 200,
      flex: 0,
      valueOptions: users,
      getOptionLabel: (value: any) => `${value.fullName}`,
      getOptionValue: (value: any) => value.id,
    },
    {
      field: 'files',
      headerName: 'Files',

      renderCell: (params) => {
        const entry = params.row;

        if (entry.files && entry.files.length > 0) {
          return (
            <Tooltip
              title={
                <List dense>
                  {entry.files.map((file: FileData, fileIndex: number) => (
                    <ListItemButton
                      key={fileIndex}
                      onClick={() => {
                        handleDownload(file);
                      }}
                    >
                      <ListItemIcon>
                        <GetApp fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={file.filename} />
                    </ListItemButton>
                  ))}
                </List>
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    pointerEvents: 'auto',
                  } as const,
                },
              }}
            >
              <IconButton size="small">
                <AttachFile />
              </IconButton>
            </Tooltip>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div style={{ height: '370px' }}>
      <DataGridPro
        loading={isLoading}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        rows={rows}
        columns={columns}
        density={density}
        // pagination
        // pageSizeOptions={[5, 10, 20, { value: -1, label: 'All' }]}
        slots={{
          toolbar: EditToolbar as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
      />
      <ValidationSnackbar
        open={snackBarState.open}
        message={snackBarState.message}
        severity={snackBarState.severity}
        onClose={handleSnackBarClose}
      />
      <ConfirmDeleteDialog
        deleteId={confirmDeleteId}
        open={openConfirmDialog}
        onClose={setOpenConfirmDialog}
        onDelete={handleDelete}
      />
      <CaseLogDialog
        open={caseLogOpen}
        onClose={handleCaseLogClose}
        onSave={handleCaseLogSave}
        watchedPartCaseLogEntry={caseLogEntry}
      />
    </div>
  );
}
