// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';

const { ALTRUITY_API_URL } = config;

const RiskMeasure = {
  getAllRiskMeasure: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Admin/RiskMeasures/RiskMeasure`);
    return data.result;
  },
};

export default RiskMeasure;
