// @ts-ignore: Implicit any module
import SuppliersAPI from '../../../../api/suppliers/suppliersAPI';
// @ts-ignore: Implicit any module
import { addSuppliers } from '../../../../slices/suppliersSlice';

import React, { useEffect, useState } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { Alert, Button, Snackbar, SnackbarCloseReason } from '@mui/material';
import { VendorDto } from '../../../../api/types/types';
import { SnackbarProps } from '../../../../types/Snackbar';
import { useDispatch, useSelector } from 'react-redux';

const columns: GridColDef[] = [
  { field: 'companyName', headerName: 'Company Name', flex: 1, minWidth: 150 },
  { field: 'companyWebsite', headerName: 'Company Website', flex: 1, minWidth: 200 },
  { field: 'streetAddress1', headerName: 'Street Address 1', flex: 1, minWidth: 200 },
  { field: 'streetAddress2', headerName: 'Street Address 2', flex: 1, minWidth: 200 },
  { field: 'city', headerName: 'City', flex: 1, minWidth: 120 },
  { field: 'state', headerName: 'State', flex: 1, minWidth: 80 },
  { field: 'zipCode', headerName: 'ZIP Code', flex: 1, minWidth: 120 },
  { field: 'country', headerName: 'Country', flex: 1, minWidth: 80 },
  { field: 'yearIncorporated', headerName: 'Year Incorporated', flex: 1, minWidth: 140 },
  { field: 'stateIncorporated', headerName: 'State Incorporated', flex: 1, minWidth: 140 },
  { field: 'uniqueIdentityId', headerName: 'Unique Identity ID', flex: 1, minWidth: 160 },
  { field: 'ein', headerName: 'EIN', flex: 1, minWidth: 100 },
  { field: 'duns', headerName: 'DUNS', flex: 1, minWidth: 100 },
  { field: 'cageCode', headerName: 'CAGE Code', flex: 1, minWidth: 140 },
  { field: 'congressionalDistrict', headerName: 'Congressional District', flex: 1, minWidth: 180 },
  {
    field: 'isSmallBusiness',
    headerName: 'Small Business',
    flex: 1,
    minWidth: 140,
    type: 'boolean',
  },
  {
    field: 'isRegisteredInSam',
    headerName: 'Registered in SAM',
    flex: 1,
    minWidth: 160,
    type: 'boolean',
  },
];

const VendorAutoLoad: React.FC = () => {
  const [vendors, setVendors] = useState<VendorDto[]>([]);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarProps>();
  const showOnlyActive = useSelector((state: any) => state.suppliers.showOnlyActive);
  const dispatch = useDispatch();

  const handleRowSelection = (selectionModel: GridRowSelectionModel) => {
    setSelectedRows(selectionModel);
  };

  const CustomToolbar: React.FC = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        variant="contained"
        color="primary"
        onClick={addVendorsToSCRM}
        disabled={selectedRows.length === 0}
        style={{ marginLeft: 'auto' }}
      >
        Add Vendors to SCRM
      </Button>
    </GridToolbarContainer>
  );

  const fetchSuppliers = async () => {
    try {
      const data = await SuppliersAPI.getAllSuppliers(showOnlyActive);
      dispatch(addSuppliers({ allSuppliers: data }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    setLoading(true);

    const response = await SuppliersAPI.getVendors();
    setVendors(response);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addVendorsToSCRM = async () => {
    try {
      setLoading(true);
      const response = await SuppliersAPI.createSuppliersFromVendors(selectedRows);

      setSnackbar({
        open: true,
        severity: 'success',
        message: response.message.length ? response.message : 'Successfully created.',
      });
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || 'An error occurred';

      setSnackbar({
        open: true,
        severity: 'error',
        message: errorMessage,
      });
    } finally {
      fetchData();
      fetchSuppliers();
      setSelectedRows([]);
    }
  };

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <>
      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={snackbar?.severity === 'error' ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <div style={{ height: 600, width: '100%' }}>
        <DataGridPro
          rows={vendors}
          columns={columns}
          getRowId={(row) => row.vendorId}
          checkboxSelection
          onRowSelectionModelChange={handleRowSelection}
          rowSelectionModel={selectedRows}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          loading={loading}
          isRowSelectable={(params) => !params.row.isDisabled}
        />
      </div>
    </>
  );
};

export default VendorAutoLoad;
