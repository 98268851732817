import httpClient from '../configs/axiosConfig';
import config from '../../config';

const { ALTRUITY_API_URL } = config;

const SupplierWeight = {
  getAllSupplierWeights: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Supplier/GetAllSupplierWeights`);
    return data.result;
  },

  updateSupplierWeight: async function (updateSupplierWeightBody) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/Supplier/UpdateSupplierWeight`,
      updateSupplierWeightBody
    );
    return data;
  },
};

export default SupplierWeight;
