import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { DataGrid, GridRowSelectionModel, GridCallbackDetails, GridColDef } from '@mui/x-data-grid';
import {
  Box,
  Checkbox,
  CircularProgress,
  LinearProgress,
  SnackbarCloseReason,
} from '@mui/material';
import { ResponseDto, NsnSearchMultipleDto, NsnSearchResultDto } from '../../../api/types/types';
import { NsnSearchResponseDto } from '../../../api/types/types';
import { createColumn } from '../../../utils/data-grid';
import { b64toBlob } from '../../../utils/files';
import NsnApi from '../../../api/nsn/nsnAPI';
import fileDownload from 'js-file-download';
import FileDownloadToolbar from '../../../components/data-grid/FileDownloadToolbar';
import { ValidationSnackbar, FileDownloadSnackbar } from '../../../components/form/index';
import { ExportDialog } from '../../../components/modal/nsn/ExportDialog';

const initalData: NsnSearchResultDto[] = [];

// Create column definitions
const columns: GridColDef[] = [
  { ...createColumn('cage', 'CAGE'), width: 70 },
  { ...createColumn('cageName', 'CAGE Name'), flex: 1 },
  {
    ...createColumn('cageStatus', 'CAGE Active?', 'boolean'),
    flex: 0,
    width: 105,
    filterable: true,
    valueGetter: (value, row) => {
      if (!value) {
        return false;
      }
      return row.cageStatus?.indexOf('ACTIVE') >= 0 || row.cageStatus?.indexOf('SPECIALIZED');
    },
    renderCell: (params) => (
      <Checkbox
        checked={
          params.row.cageStatus?.indexOf('ACTIVE') >= 0 ||
          params.row.cageStatus?.indexOf('SPECIALIZED')
        }
        disabled
      />
    ),
  },
  { ...createColumn('part', 'Part Number'), minWidth: 100, flex: 1 },
  { ...createColumn('nsn', 'NSN'), width: 130 },
  { ...createColumn('itemName', 'Item Name'), flex: 1 },
  { ...createColumn('rncc', 'RNCC'), width: 55 },
  { ...createColumn('rnvc', 'RNVC'), width: 55 },
  { ...createColumn('rnsc', 'RNSC'), width: 55 },
  { ...createColumn('amscG', 'AMSC-G'), width: 80 },
  {
    ...createColumn('dtNiinAsgmt', 'DT NIIN ASGMT'),
    width: 120,
    flex: 0,
  },
  {
    ...createColumn('niinStatus', 'NSN Status'),
    width: 100,
    flex: 0,
  },
  {
    ...createColumn('replacementNsn', 'Replacement NSN'),
    width: 150,
    flex: 0,
  },
];

export default function NsnSearchResultsPage() {
  // Get data from Location
  const location = useLocation();
  const locState = location.state;
  const query = locState?.query as NsnSearchMultipleDto;

  const navigate = useNavigate();

  // Config States
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
  });

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: initalData,
  });

  const [fileSnackBarState, setFileSnackBarState] = useState({
    isLoading: false,
    type: 'csv',
  });

  const [pageFirstLoad, setPageFirstLoad] = useState(true);

  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [exportType, setExportType] = useState('csv');

  function CustomToolbar() {
    return <FileDownloadToolbar onExportClick={handleFileDownload} />;
  }

  function handleFileDownload(type: string) {
    setExportDialogOpen(true);
    setExportType(type);
  }

  function handleExportDialogClose() {
    setExportDialogOpen(false);
  }

  /**
   * Downloads a file to the user's computer
   */
  async function downloadFile(type: string, includeMetadata: boolean) {
    try {
      setExportDialogOpen(false);
      // Show 'File downloading' snackbar
      setFileSnackBarState((prevState) => ({
        ...prevState,
        isLoading: true,
        type: type,
      }));

      // Get all rows.
      const response = await NsnApi.getSearchFile(type, includeMetadata, query);

      const mime: string =
        type === 'csv'
          ? 'text/csv'
          : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      if (response.isSuccess) {
        // Download file to user.
        const blob = b64toBlob(response.result[1], mime);
        setFileSnackBarState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        fileDownload(blob, response.result[0]);
      } else {
        setFileSnackBarState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));

        setSnackBarState({
          open: true,
          message: response.message,
        });
      }
    } catch (e) {
      setFileSnackBarState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setSnackBarState({
        open: true,
        message: 'Error downloading file.',
      });
      console.error(e);
    }
  }

  /**
   * Load data on page state change
   */
  useEffect(() => {
    /**
     * Displays error message
     * @param message
     * @param error
     */
    const showError = (message: string, error?: any) => {
      setPageState((oldState) => ({
        ...oldState,
        isLoading: false,
      }));

      if (error) {
        console.error(message, error);
      }
      setSnackBarState({
        open: true,
        message: message,
      });
    };

    /**
     * / Fetch new when new page or new page size.
     */
    const fetchData = async () => {
      setPageState((oldState) => ({ ...oldState, isLoading: true }));

      try {
        // Get paginated data from the server.
        const response: ResponseDto<NsnSearchResponseDto> = await NsnApi.searchFull(query);

        if (response.isSuccess === true) {
          setPageState((oldState) => ({
            ...oldState,
            isLoading: false,
            data: response.result?.results,
          }));
        } else {
          showError(response.message);
        }
      } catch (error) {
        showError('Error sending query!', error);
      }
      setPageFirstLoad(false);
    };

    fetchData();
  }, [query]);

  /**
   * Closes the validation SnackBar
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState({ open: false, message: '' });
  };
  return (
    <>
      <Box className="page-container">
        <h4 className="page-title">Search Results</h4>
        <DataGrid
          density="compact"
          getRowId={(row) => row.id}
          rows={pageState.data}
          loading={pageState.isLoading}
          pageSizeOptions={[25, 50, 100]}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          onRowSelectionModelChange={async (
            rowSelectionModel: GridRowSelectionModel,
            details: GridCallbackDetails
          ) => {
            const row = details.api.getRow(rowSelectionModel[0]);

            navigate('/nsn/details', {
              state: {
                nsn: row.nsn,
              },
            });
          }}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
        {pageFirstLoad && <LinearProgress sx={{ width: '100%' }} />}
      </Box>
      <ValidationSnackbar
        open={snackBarState.open}
        onClose={handleSnackBarClose}
        message={snackBarState.message}
        severity="error"
      />
      <FileDownloadSnackbar isLoading={fileSnackBarState.isLoading} type={fileSnackBarState.type} />
      <ExportDialog
        open={exportDialogOpen}
        onSubmit={downloadFile}
        onClose={handleExportDialogClose}
        type={exportType}
      />
    </>
  );
}
