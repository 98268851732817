import React, { useState } from 'react';
import Button from 'devextreme-react/button';
import logo from '../../utils/rheinmetall.png';
import './SubMenu.scss';
import SuppliersMenu from '../suppliers-menu/SuppliersMenu';
import RiskDiscoveryMenu from '../risk-Discovery-menu/RiskDiscoveryMenu';
import RiskModelsMenu from '../risk-Models/risk-models-nav/RiskModelsMenu';
import NsnQueryMenu from '../nsn/side-menu/NsnQueryMenu';
import RiskCasesMenu from '../risk-cases-menu/RiskCasesMenu';
import AdminMenu from '../admin-menu/AdminMenu';
import DmsmsMenu from '../dmsms/side-menu/DmsmsMenu';
import SiteAdminMenu from '../site-admin-menu/SiteAdminMenu';
import { Menu } from 'devextreme-react';
import { useNavigate } from 'react-router-dom';

const products = [
  {
    id: '1',
    icon: 'menu',
    items: [
      {
        id: '1_1',
        name: 'Site Admin',
        route: '/site-admin',
      },
      {
        id: '1_2',
        name: 'SCRM',
        route: '/scrm/dashboard',
      },
      {
        id: '1_3',
        name: 'DMSMS',
        route: '/dmsms/admin',
      },
      {
        id: '1_4',
        name: 'NSN Query',
        route: '/nsn/search',
      },
    ],
  },
];

const SubMenu = ({ returnToRootMenu, text, setSelectedMenuOption }) => {
  const [subMenuInput, setSubMenuInput] = useState('');

  const filterSuppliers = (e) => {
    setSubMenuInput(e.target.value);
  };

  const clearInput = () => {
    setSubMenuInput('');
  };

  const drawMenuItems = () => {
    if (text === 'Suppliers') {
      return <SuppliersMenu subMenuInput={subMenuInput} />;
    } else if (text === 'Risk Models') {
      return <RiskModelsMenu />;
    } else if (text === 'Discovery') {
      return <RiskDiscoveryMenu />;
    } else if (text === 'Risk Cases') {
      return <RiskCasesMenu />;
    } else if (text === 'NSN Query') {
      return <NsnQueryMenu />;
    } else if (text === 'Admin') {
      return <AdminMenu />;
    } else if (text === 'DMSMS') {
      return <DmsmsMenu />;
    } else if (text === 'Site Admin') {
      return <SiteAdminMenu />;
    }
  };

  const navigate = useNavigate();

  return (
    <div className="root-menu-container">
      <div className="root-menu-top-wrapper">
        {text === 'Site Admin' ? (
          <div className="root-menu-header-wrapper">
            <div className="root-menu-title">
              <h5 className="root-menu-title-primary">Altruity</h5>
              <h6 className="root-menu-title-secondary">Site Admin</h6>
            </div>
            <div>
              <Menu
                dataSource={products}
                displayExpr="name"
                showFirstSubmenuMode={{
                  name: 'onClick',
                  delay: { show: 300, hide: 300 },
                }}
                orientation={'horizontal'}
                hideSubmenuOnMouseLeave={false}
                onItemClick={(e) => {
                  navigate(e.itemData.route);
                }}
                rtlEnabled={true}
              />
            </div>
          </div>
        ) : (
          <div className="submenu-return-container">
            <Button
              text={text}
              onClick={returnToRootMenu}
              icon="back"
              stylingMode="text"
              className="submenu-return-button"
            />
          </div>
        )}
        {text === 'Suppliers' && (
          <div className="submenu-input-container">
            <input
              value={subMenuInput}
              onChange={filterSuppliers}
              placeholder={'Search'}
              className="submenu-input-search"
            />
          </div>
        )}
        {drawMenuItems()}
      </div>
      <img className="root-menu-logo" src={logo} alt="Rheinmetall-Logo" />
    </div>
  );
};

export default SubMenu;
