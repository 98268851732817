import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AllOwners {
  id: number;
  label: string;
}

export interface User {
  id: number;
  fullName: string;
}

export interface Role {
  id: number;
  name: string;
}

const initialState = {
  owners: [] as AllOwners[],
  users: [] as User[],
  roles: [] as Role[],
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    addOwners: (state, action: PayloadAction<AllOwners[]>) => {
      state.owners = [...state.owners, ...action.payload];
    },
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
  },
});

export const { addOwners, setUsers, setRoles } = permissionsSlice.actions;
export default permissionsSlice.reducer;
