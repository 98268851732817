import React from 'react';
import ColorsComponent from '../../components/Site-Admin/Colors/Colors';
import './Colors.scss';
import { Typography } from '@mui/material';

const Colors = () => {
  return (
    <div className="colors-page">
      <Typography className="title">Colors</Typography>
      <ColorsComponent />
    </div>
  );
};

export default Colors;
