import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useNavigate } from 'react-router';

const drawerWidth = 240;

const initialItems = [
  { id: 1, text: 'Dashboard', path: '/dmsms/dashboard' },
  { id: 2, text: 'Parts', path: '/dmsms/parts' },
  { id: 3, text: 'Log', path: '/dmsms/log' },
  { id: 4, text: 'Admin Settings', path: '/dmsms/admin' },
];

const DmsmsMenu: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const navigate = useNavigate();

  const handleItemClick = (id: number, path: string) => {
    setSelectedItem(id);
    navigate(path);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#1a1a1a',
          color: '#ffffff',
        },
      }}
    >
      <List>
        {initialItems.map((item) => (
          <ListItem
            button
            key={item.id}
            onClick={() => handleItemClick(item.id, item.path)}
            selected={selectedItem === item.id}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#007bff',
                color: '#ffffff',
              },
              '&:hover': {
                backgroundColor: '#333',
              },
            }}
          >
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Drawer>
  );
};

export default DmsmsMenu;
