import React, { useState } from 'react';
import TreeView from 'devextreme-react/tree-view';
import './SiteAdminMenu.scss';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMenuItem } from '../../slices/siteAdminSlice';
import { useLocation } from 'react-router-dom';

interface TreeViewItem {
  id: number | string;
  text: string;
  expanded?: boolean;
  modelId?: number | string;
  type?: string;
  items?: TreeViewItem[];
  nonSelectable?: boolean;
}

const initialItems: TreeViewItem[] = [
  {
    id: '100',
    text: 'Permissions',
    expanded: true,
    items: [
      { id: '101', text: 'Users' },
      { id: '102', text: 'Roles' },
      { id: '103', text: 'Activities' },
    ],
  },
  {
    id: '200',
    text: 'Site Settings',
    expanded: true,
    items: [{ id: '201', text: 'Colors' }],
  },
];

const renderTreeViewItem = (
  item: TreeViewItem,
  selectedItem: string | number | null,
  location: any
) => {
  const itemClass =
    item.id === selectedItem
      ? 'tree-view-item selected'
      : item.nonSelectable
        ? 'tree-view-item non-selectable'
        : item.items && item.items.length > 0
          ? 'tree-view-item title'
          : 'tree-view-item value';
  return <div className={itemClass}>{item.text}</div>;
};

const SiteAdminMenu: React.FC = () => {
  const [items, setItems] = useState<TreeViewItem[]>(initialItems);
  const selectedItem = useSelector((state: any) => state.siteAdmin.selectedMenuItem);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleItemClick = (e: { itemData: TreeViewItem }) => {
    const itemData = e.itemData as TreeViewItem;

    dispatch(setSelectedMenuItem(itemData.modelId ?? itemData.id));

    if (itemData.id) {
      let url = '';
      if (itemData.text === 'Users') {
        url = `/site-admin/permissions/users`;
      } else if (itemData.text === 'Roles') {
        url = `/site-admin/permissions/roles`;
      } else if (itemData.text === 'Activities') {
        url = `/site-admin/permissions/activities`;
      } else if (itemData.text === 'Colors') {
        url = `/site-admin/site-settings/colors`;
      }

      return navigate(url);
    }
  };

  return (
    <div
      id="treeViewContainer"
      className="tree-view-container"
      style={location.pathname.startsWith('/site-admin') ? { marginTop: 0 } : {}}
    >
      <TreeView
        items={items}
        width={300}
        itemRender={(item) => renderTreeViewItem(item, selectedItem, location)}
        onItemClick={handleItemClick as any}
        selectByClick={true}
      />
    </div>
  );
};

export default SiteAdminMenu;
