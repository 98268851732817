import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generateMonochromaticScale } from '../components/Site-Admin/Colors/utils/generateMonochromaticScale';

const useMonochromaticScale = (): string[] => {
  const primaryColor = useSelector((state: any) => state.theme.primaryColor);

  const colorScale = useMemo(() => {
    return generateMonochromaticScale(primaryColor);
  }, [primaryColor]);

  return colorScale;
};

export default useMonochromaticScale;
