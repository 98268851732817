// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, PartActionDto } from '../types/types';
const { DMSMS_SERVICE_URL } = config;

const PartActions = {
  /**
   * Retrieves all part forecasts
   * @returns
   */
  getPartActions: async function (): Promise<ResponseDto<PartActionDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/PartAction`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<PartActionDto[]>;
  },
  /**
   * Retrieves all actions for a watched part.
   * @returns
   */
  getPartActionsForWatchedPart: async function (
    watchedPartId: number
  ): Promise<ResponseDto<PartActionDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/PartAction/ForWatchedPart/${watchedPartId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<PartActionDto[]>;
  },
  /**
   * Retrieves a part action
   * @returns
   */
  getPartAction: async function (partActionId: number): Promise<ResponseDto<PartActionDto>> {
    var url = `${DMSMS_SERVICE_URL}/PartAction/${partActionId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<PartActionDto>;
  },

  /**
   * Creates a part action
   * @param action
   */
  createPartAction: async function (action: PartActionDto): Promise<ResponseDto<PartActionDto>> {
    var url = `${DMSMS_SERVICE_URL}/PartAction`;
    const { data } = await httpClient.post(url, action);
    return data as ResponseDto<PartActionDto>;
  },

  /**
   * Deletes a part forecast
   * @param part
   */
  deletePartAction: async function (partActionId: number): Promise<ResponseDto<any>> {
    var url = `${DMSMS_SERVICE_URL}/PartAction/${partActionId}`;
    const { data } = await httpClient.delete(url);
    return data as ResponseDto<any>;
  },
};
export default PartActions;
