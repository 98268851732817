// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, RoleDto } from '../types/types';
const { PERMISSIONS_SERVICE_URL } = config;
const Role = {
  /**
   * Gets information for a particular cage code.
   * @param cage
   * @returns
   */
  getAllRoles: async function (activeOnly: boolean): Promise<ResponseDto<RoleDto[]>> {
    var url = `${PERMISSIONS_SERVICE_URL}/Role?activeOnly=${activeOnly}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<RoleDto[]>;
  },
};

export default Role;
