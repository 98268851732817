import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import MetricCard from './MetricCard';
import { DashboardKeyMetricsViewModelDto, RiskCaseViewModelDto } from '../../../api/types/types';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { PieChart } from '@mui/x-charts/PieChart/PieChart';
import useMonochromaticScale from '../../../hooks/useMonochromaticScale';
import PopupRiskCases from '../../risk-discovery/popup-risk-cases/PopupRiskCases';

interface MuiPieChartItem {
  id?: number;
  label: string;
  value: number;
}

interface KeyMetricsUIProps {
  metrics: DashboardKeyMetricsViewModelDto | null;
}

const KeyMetricsUI: React.FC<KeyMetricsUIProps> = ({ metrics }) => {
  // Colors in the pie chart get assigned from first to last.
  const colors = useMonochromaticScale().slice(3);

  const [popupRiskCasesVisible, setPopupRiskCasesVisible] = useState(false);
  const [riskCasesForPopup, setForPopupRiskCases] = useState<RiskCaseViewModelDto[]>([]);

  const removeSign = (value: number | null | undefined): number | null | undefined => {
    if (value == null) return value;

    return value < 0 ? value * -1 : value;
  };

  const formatPercentile = (value: number | null | undefined): number => {
    return value != null ? Math.trunc(value * 100) : 0;
  };

  const formatSparkLineData = (value: number[] | null | undefined) => {
    return value ? value.slice().reverse() : [0, 0, 0, 0, 0, 0];
  };

  const formatPieChartData = (
    value: { [key: string]: any[] } | null | undefined
  ): MuiPieChartItem[] => {
    return Object.entries(value ?? {})
      .sort(([, a], [, b]) => b.length - a.length) // Sort by array length in descending order
      .map(([label, items], index) => ({
        id: index + 1,
        label,
        value: items.length, // Use the length of each array as the pie chart value
      }));
  };

  const handleRiskCasesClose = () => {
    setPopupRiskCasesVisible(false);
    setForPopupRiskCases([]);
  };

  const handleSliceClick = (event: any, itemData: any) => {
    const i = itemData.dataIndex;
    const categoryKeys = Object.keys(metrics?.topRiskCategories ?? {});
    const selectedCategoryKey = categoryKeys[i];
    const selectedRiskCases = metrics?.topRiskCategories?.[selectedCategoryKey] || [];

    setForPopupRiskCases(selectedRiskCases);
    setPopupRiskCasesVisible(true);
  };

  return (
    <>
      <Box p={2}>
        <Grid container spacing={4} justifyContent="center">
          {/* Number of Suppliers */}
          <MetricCard
            title="Number of Suppliers"
            lastMonthText=""
            lastMonthValue={0}
            isPositive={null}
          >
            {metrics?.currentSuppliersCount ?? '-'}
          </MetricCard>

          {/* Suppliers with Risk */}
          <MetricCard
            title="Suppliers with Risk"
            lastMonthText={`${removeSign(metrics?.suppliersWithRiskLastMonthIncrease) ?? '--'}`}
            lastMonthValue={metrics?.suppliersWithRiskLastMonthIncrease ?? 0}
            isPositive={
              metrics
                ? metrics.suppliersWithRiskLastMonthIncrease > 0
                  ? false
                  : metrics.suppliersWithRiskLastMonthIncrease < 0
                    ? true
                    : null
                : null
            }
          >
            {metrics?.currentSuppliersWithRisk ?? '-'}
          </MetricCard>

          {/* Avg. Supplier Health */}
          <MetricCard
            title="Avg. Supplier Health"
            lastMonthText={`${removeSign(formatPercentile(metrics?.avgSuppliersHealthLastMonthIncrease))}%`}
            lastMonthValue={metrics?.avgSuppliersHealthLastMonthIncrease ?? 0}
            isPositive={
              metrics
                ? metrics.avgSuppliersHealthLastMonthIncrease > 0
                  ? true
                  : metrics.avgSuppliersHealthLastMonthIncrease < 0
                    ? false
                    : null
                : null
            }
          >
            {metrics ? `${formatPercentile(metrics.currentAvgSuppliersHealth)}%` : '-'}
          </MetricCard>

          {/* Number of Risk Cases */}
          <MetricCard
            title="Number of Risk Cases"
            lastMonthText=""
            lastMonthValue={0}
            isPositive={null}
          >
            {metrics?.riskCasesCountHistory.length ?? '-'}
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
              <SparkLineChart
                data={formatSparkLineData(metrics?.riskCasesCountHistory)}
                height={30}
                width={221}
                colors={colors}
                showHighlight={true}
                showTooltip={true}
              />
            </Box>
          </MetricCard>

          {/* Top Risk Categories */}
          <MetricCard
            title="Top Risk Categories"
            lastMonthText=""
            lastMonthValue={0}
            isPositive={null}
          >
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
              <PieChart
                colors={colors}
                series={[
                  {
                    data: formatPieChartData(metrics?.topRiskCategories),
                    cx: '95%',
                  },
                ]}
                width={221}
                height={100}
                slotProps={{
                  legend: { hidden: true },
                }}
                onItemClick={handleSliceClick} // Set the onItemClick event to open PopupRiskCases
              />
            </Box>
          </MetricCard>

          {/* Avg. Risk Case Age */}
          <MetricCard
            title="Avg. Risk Case Age"
            lastMonthText={`${removeSign(metrics?.avgRiskCaseAgeLastMonthIncrease) ?? '--'} days`}
            lastMonthValue={metrics?.avgRiskCaseAgeLastMonthIncrease ?? 0}
            isPositive={
              metrics
                ? metrics.avgRiskCaseAgeLastMonthIncrease > 0
                  ? false
                  : metrics.avgRiskCaseAgeLastMonthIncrease < 0
                    ? true
                    : null
                : null
            }
          >
            {metrics?.currentAvgRiskCaseAge ?? '-'} days
          </MetricCard>
        </Grid>
      </Box>

      {/* Render PopupRiskCases when visible */}
      {popupRiskCasesVisible && (
        <PopupRiskCases
          popupRiskCasesVisible={popupRiskCasesVisible}
          handleRiskCasesClose={handleRiskCasesClose}
          riskCases={riskCasesForPopup}
        />
      )}
    </>
  );
};

export default KeyMetricsUI;
