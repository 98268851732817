import { createPortal } from 'react-dom';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function HelpModal({ open, title, onClose, children }) {
  return createPortal(
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
        }}
        id="dialog-title"
        style={{
          backgroundColor: '#306EB6',
          fontSize: '20px',
          fontWeight: 'bold',
          color: 'white',
          paddingRight: '60px',
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 10,
          color: 'white',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText id="dialog-description">{children}</DialogContentText>
      </DialogContent>
    </Dialog>,
    document.getElementById('app-modal')
  );
}
