import React from 'react';
import { labelStyles, inputProps_textField } from '../styles';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectFieldOption } from '../../form/index';

interface SelectFieldProps {
  name: string;
  label?: string | null;
  defaultValue?: any;
  value: any;
  options: SelectFieldOption[];
  placeholder?: string | null;
  onChange: (field: string, value: any) => void;
}

export default function AltruitySelectField({
  label,
  name,
  defaultValue,
  value,
  options,
  onChange,
  placeholder,
}: SelectFieldProps) {
  var randomId = (Math.random() * 1000).toFixed(0);
  const selectLabelId = `select-label-${randomId}`;
  return (
    <FormControl fullWidth>
      <InputLabel id={selectLabelId}>{label}</InputLabel>
      <Select
        label={label}
        labelId={selectLabelId}
        displayEmpty
        name={name}
        value={value ?? ''}
        defaultValue={defaultValue}
        onChange={(e) => onChange(name, e.target.value)}
        renderValue={(selected) => {
          if (selected === '' && placeholder) {
            return <em>{placeholder}</em>;
          }
          return options.find((c) => c.value === selected)?.text || '';
        }}
      >
        {/* {placeholder && (
					<MenuItem disabled value="">
						<em>{placeholder}</em>
					</MenuItem>
				)} */}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
