// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';

const { ALTRUITY_API_URL } = config;

const Permissions = {
  getAllOwners: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Permissions/GetAllOwners`);
    return data.result;
  },
};

export default Permissions;
