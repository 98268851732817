import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/store';
import './index.css';
import App from './App';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  '16a7881b177e895a30a9962d75169d44Tz05OTM1MSxFPTE3NTk2MDgyMjgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
);

async function deferRender() {
  // Start the mocking when in development mode
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  if (process.env.REACT_APP_USE_MOCKS !== 'true') {
    return;
  }

  const { worker } = await import('./mocks/browser');
  return worker.start();
}

deferRender().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
});
