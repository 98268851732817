import chroma from 'chroma-js';

export const colorScale = chroma
  .scale([
    '#001C2C', // Primary 800
    '#003A5B', // Primary 700
    '#015688', // Primary 600
    '#0773B5', // Primary 500
    '#4596C7', // Primary 400
    '#83B9DB', // Primary 300
    '#C1DCED', // Primary 200
  ])
  .domain([0, 100]);

export const getColor = (score: number): string => {
  return colorScale(score).hex();
};
