import httpClient from '../configs/axiosConfig';
import config from '../../config';

const { ALTRUITY_API_URL } = config;

const SupplierModels = {
  runPerigonFlowBySupplier: async function (supplierId) {
    const body = {
      SupplierId: supplierId,
    };
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/Supplier/RunPerigonFlowBySupplier`,
      body
    );
    if (!data.isSuccess) throw new Error(data.message);

    return data.result;
  },
};

export default SupplierModels;
