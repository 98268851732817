const formatDate = (dateString: string | undefined): string => {
  if (!dateString) {
    return 'Unknown Date';
  }
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
};

export default formatDate;
