import { Snackbar, Alert } from '@mui/material';
import { createPortal } from 'react-dom';

export default function ValidationSnackbar({ open, message, severity, onClose, ...props }) {
  return createPortal(
    <Snackbar
      {...props}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      //key={snackBarState.message}
    >
      <Alert variant="filled" severity={severity}>
        {message}
      </Alert>
    </Snackbar>,
    document.getElementById('app-snackbar')
  );
}
