import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Typography,
  TextField,
  IconButton,
  Box,
  Paper,
  AlertColor,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DmsmsApi from '../../../api/dmsms/dmsmsAPI';
import { ActionDto } from '../../../api/types/types';
import CustomSnackbar from '../../../components/custom-snackbar';
import './ActionTypes.scss';

export default function ActionTypes() {
  const [actionTypes, setActionTypes] = useState<ActionDto[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  //const [showSaveCancel, setShowSaveCancel] = useState(false);
  const [disabledLabels, setDisabledLabels] = useState<number[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedActionTypeId, setSelectedActionTypeId] = useState<number | null>(null);
  const newInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchActionTypes();
  }, []);

  useEffect(() => {
    if (newInputRef.current) {
      newInputRef.current.focus();
    }
  }, [actionTypes.length]);

  const fetchActionTypes = async () => {
    try {
      const response = await DmsmsApi.getActions();
      if (response.isSuccess && Array.isArray(response.result)) {
        setActionTypes(response.result as ActionDto[]);
        const loadedIds = response.result.map((action) => action.id);
        setDisabledLabels(loadedIds);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddActionType = () => {
    const newActionType: Partial<ActionDto> = {
      id: Date.now(),
      entryText: '',
    };
    setActionTypes([...actionTypes, newActionType as ActionDto]);
    //setShowSaveCancel(true);
  };

  const handleDeleteActionType = async (id: number) => {
    try {
      const response = await DmsmsApi.deleteAction(id);
      if (response.isSuccess) {
        setActionTypes(actionTypes.filter((actionType) => actionType.id !== id));
        setDisabledLabels(disabledLabels.filter((label) => label !== id));
        setOpenConfirmDialog(false);
        setSnackbarMessage('Record deleted successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        console.error(response.message);
        setSnackbarMessage(response.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage('Error deleting record');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDeleteActionType = async (id: number) => {
    setOpenConfirmDialog(true);
    setSelectedActionTypeId(id);
  };

  const handleActionTypeLabelChange = (id: number, newLabel: string) => {
    setActionTypes((prevState) =>
      prevState.map((actionType) =>
        actionType.id === id ? { ...actionType, entryText: newLabel } : actionType
      )
    );
  };

  const handleCancel = () => {
    const lastActionType = actionTypes[actionTypes.length - 1];
    if (!disabledLabels.includes(lastActionType.id)) {
      setActionTypes((prevState) => prevState.slice(0, -1));
    }
    //setShowSaveCancel(false);
  };

  const handleSave = async (id: number) => {
    setIsSaving(true);
    const body = {
      id: 0,
      entryText: actionTypes.find((actionType) => actionType.id === id)?.entryText || '',
      watchedPartId: 0,
      authorId: 0,
    };
    try {
      const response = await DmsmsApi.createAction(body);

      if (response.isSuccess) {
        // Update ID of existing action
        setActionTypes((prevData) => {
          const newData = [...prevData];
          const newActionIndex = newData.findIndex((c) => c.id === id);

          if (newActionIndex !== -1) {
            newData[newActionIndex].id = response.result.id;
            newData[newActionIndex].authorId = response.result.authorId;
          }

          return newData;
        });

        setDisabledLabels((prev) => [...prev, response.result.id]);
        //setShowSaveCancel(false);
        setSnackbarMessage('Action added successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        console.error(response.message);
        setSnackbarMessage(response.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage('Error deleting record');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setIsSaving(false);
  };

  return (
    <Paper
      className="action-types-container"
      elevation={3}
      sx={{
        padding: '20px',
        marginTop: '15px',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
        Action Types
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={handleAddActionType}
          sx={{
            color: '#1976d2',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          ADD RECORD
        </Button>
      </Box>
      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#666' }}>
        Label
      </Typography>
      {actionTypes.map((actionType, index) => (
        <Box
          key={actionType.id}
          className="action-type-box"
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: index < actionTypes.length - 1 ? '1px solid #dedcdc' : 'none',
            backgroundColor: '#f5f5f5',
          }}
        >
          <TextField
            className="action-type-label"
            fullWidth
            value={actionType.entryText || ''}
            onChange={(e) => handleActionTypeLabelChange(actionType.id, e.target.value)}
            variant="outlined"
            size="small"
            multiline
            inputRef={index === actionTypes.length - 1 ? newInputRef : null}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
          />

          {index === actionTypes.length - 1 && !disabledLabels.includes(actionType.id) && (
            <Box sx={{ display: 'flex', ml: 2 }}>
              <Button
                variant="contained"
                onClick={() => handleSave(actionType.id)}
                disabled={isSaving}
                sx={{
                  margin: '6px',
                  fontSize: '10px',
                  padding: '4px 4px',
                  backgroundColor: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#1565c0',
                  },
                }}
              >
                {isSaving ? 'Saving...' : 'SAVE'}
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  color: '#1976d2',
                  borderColor: '#1976d2',
                  margin: '6px',
                  fontSize: '10px',
                }}
              >
                CANCEL
              </Button>
            </Box>
          )}

          {disabledLabels.includes(actionType.id) && (
            <Box sx={{ display: 'flex', ml: 2, backgroundColor: '#fff' }}>
              <IconButton
                onClick={() => handleConfirmDeleteActionType(actionType.id)}
                sx={{ color: '#1976d2', ml: 2 }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
          {'Are you sure you want to delete this action type?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              selectedActionTypeId !== null && handleDeleteActionType(selectedActionTypeId)
            }
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </Paper>
  );
}
