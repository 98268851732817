import React from 'react';
import { SupplierDto, WatchedPartDto } from '../../../../api/types/types';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Box } from '@mui/material';
import MetricCard from '../MetricCard';
import './DmsmsSuppliersChart.scss';
const DmsmsSuppliersChart = ({ partsData }: { partsData: WatchedPartDto[] }) => {
  const atRiskParts = partsData.filter((part) => part.isAtRisk);

  // Supplier name is now included in WatchedPartDto
  const supplierCounts = atRiskParts.reduce(
    (acc, part) => {
      const key = part.supplierName ?? 'Unknown';
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    },
    {} as Record<string, number>
  );

  const chartData = Object.entries(supplierCounts).map(([supplierName, count]) => {
    return {
      name: supplierName,
      value: count,
    };
  });

  const COLORS = [
    '#003B5C',
    '#005B8C',
    '#007BA8',
    '#0095C8',
    '#00B2E2',
    '#00C4E1',
    '#00D6E0',
    '#00E8DF',
  ];

  const handleSupplierClick = (supplierName: string) => {
    localStorage.setItem('selectedSupplier', supplierName);
    localStorage.setItem('lifeStage', '');
    window.open(`/dmsms/parts?supplierName=${supplierName}&atRisk=true`, '_blank');
  };

  const renderCustomLabel = ({ cx, cy, midAngle, outerRadius, percent, name }: any) => {
    const RADIAN = Math.PI / 180;
    const x = cx + (outerRadius + 20) * Math.cos(-midAngle * RADIAN);
    const y = cy + (outerRadius + 30) * Math.sin(-midAngle * RADIAN);

    return percent > 0 ? (
      <text
        x={x}
        y={y}
        fill="#333"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: '13px', fontWeight: '500' }}
      >
        {name}
      </text>
    ) : null;
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="suppliers-chart-container"
    >
      <MetricCard
        title="Suppliers with At Risk Parts"
        lastMonthText=""
        lastMonthValue={0}
        isPositive={null}
      >
        <PieChart width={450} height={300} style={{ outline: 'none', cursor: 'pointer' }}>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            labelLine={true}
            outerRadius={80}
            label={renderCustomLabel}
            dataKey="value"
            onClick={(data) => handleSupplierClick(data.name)}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip
            content={({ active, payload }) =>
              active && payload && payload.length ? (
                <div
                  style={{
                    backgroundColor: '#f5f5f5',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    fontSize: '16px',
                    fontWeight: '400',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor:
                        COLORS[chartData.findIndex((entry) => entry.name === payload[0].name)],
                      marginRight: '8px',
                    }}
                  />
                  <div>{payload[0].name}</div>: {payload[0].value}
                </div>
              ) : null
            }
          />
        </PieChart>
      </MetricCard>
    </Box>
  );
};

export default DmsmsSuppliersChart;
