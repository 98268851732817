import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Switch,
  FormControlLabel,
  AlertColor,
} from '@mui/material';
import { DmsmsAdminSettingsDto } from '../../../api/types/types';
import CustomSnackbar from '../../../components/custom-snackbar';
import JobApi from '../../../api/job/JobApi';
import { Role, User } from '../../../slices/permissionsSlice';
import {
  AltruitySelectField,
  AltruityTagBox,
  SelectFieldOption,
  TagBoxOption,
} from '../../../components/form';
import DmsmsGidepModal from '../../../components/modal/dmsms/DmsmsGidepModal';

const gidepFrequency = [
  { text: 'Weekly on Mondays', value: 0 },
  { text: 'Monthly on first day of month', value: 1 },
  { text: 'Quarterly on first day of quarter', value: 2 },
];
const plj2LoadOptions: SelectFieldOption[] = [
  { text: 'Daily', value: 0 },
  { text: 'Weekly', value: 1 },
  { text: 'Monthly', value: 2 },
];

interface GidepSettingsProps {
  formData: DmsmsAdminSettingsDto;
  onFieldChange: (field: string, e: any) => void;
  onChipDelete: (field: string, value: any) => void;
  users: User[];
  roles: Role[];
}

export default function GidepSettings({
  formData,
  onFieldChange,
  onChipDelete,
  users,
  roles,
}: GidepSettingsProps) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');
  const [sendingSate, setSendingState] = useState({
    isLoadingData: false,
    isSaving: false,
    isRunningPlj2Load: false,
    isRunningGidepScan: false,
  });
  const [gidepOpen, setGidepOpen] = useState(false);
  const [usersOptions, setUsersOptions] = useState<TagBoxOption[]>([]);
  const [rolesOptions, setRolesOptions] = useState<TagBoxOption[]>([]);

  useEffect(() => {
    setUsersOptions(
      users.map((u: User) => ({
        text: u.fullName,
        value: u.id,
      }))
    );
    setRolesOptions(
      roles.map((u: Role) => ({
        text: u.name,
        value: u.id,
      }))
    );
  }, [users, roles]);

  const handleSave = async () => {
    setSendingState((prevState) => ({ ...prevState, isSaving: true }));
    try {
      if (formData) {
        const response = await JobApi.saveDmsmsSettings(formData);

        if (response.isSuccess === true) {
          setSnackbarOpen(true);
          setSnackbarMessage('Gidep Settings saved successfully');
          setSnackbarSeverity('success');
        } else {
          console.error(response.message);
          setSnackbarOpen(true);
          setSnackbarMessage(response.message);
          setSnackbarSeverity('error');
        }
      }
    } catch (error) {
      console.error('Error saving Gidep Settings:', error);
      setSnackbarOpen(true);
      setSnackbarMessage('Error saving Gidep Settings');
      setSnackbarSeverity('error');
    }
    setSendingState((prevState) => ({ ...prevState, isSaving: false }));
  };

  function handleRunGidep() {
    setGidepOpen(true);
  }
  function handleGidepModelClose() {
    setGidepOpen(false);
  }

  return (
    <Paper
      elevation={3}
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        marginTop: '15px',
      }}
      className="container"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            GIDEP Settings
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <AltruitySelectField
            name="frequencyOfPlj2Loads"
            label="Frequency of Incremental PLJ2 Loads"
            options={plj2LoadOptions}
            value={formData.frequencyOfPlj2Loads}
            onChange={onFieldChange}
          />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                className="switch"
                checked={formData?.initiateGidepScanOnLoadedParts || false}
                onChange={(event) =>
                  onFieldChange('initiateGidepScanOnLoadedParts', event.target.checked)
                }
                color="primary"
                sx={{ marginLeft: '20px' }}
              />
            }
            label={
              <Typography variant="body1">
                Initiate GIDEP Scan on Incrementally Loaded Parts?
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                className="switch"
                checked={formData?.automaticallyRemoveParts || false}
                onChange={(event) =>
                  onFieldChange('automaticallyRemoveParts', event.target.checked)
                }
                color="primary"
                sx={{ marginLeft: '20px' }}
              />
            }
            label={
              <Typography variant="body1">Automatically Remove Parts No Longer in PLJ2?</Typography>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AltruityTagBox
            name="usersToNotify"
            label="Users to Notify"
            values={formData.usersToNotify}
            onChange={onFieldChange}
            onDeleteOption={onChipDelete}
            options={usersOptions}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AltruityTagBox
            name="rolesToNotify"
            label="Roles to Notify"
            values={formData.rolesToNotify}
            onChange={onFieldChange}
            onDeleteOption={onChipDelete}
            options={rolesOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AltruitySelectField
            name="frequencyOfGidepScanNonTaggedParts"
            label="Frequency of GIDEP scan on Non-Tagged Parts"
            value={formData.frequencyOfGidepScanNonTaggedParts}
            onChange={onFieldChange}
            options={gidepFrequency}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AltruitySelectField
            name="frequencyOfGidepScanTaggedParts"
            label="Frequency of GIDEP scan on Tagged Parts"
            value={formData.frequencyOfGidepScanTaggedParts}
            onChange={onFieldChange}
            options={gidepFrequency}
          />
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2, display: 'flex', justifyContent: 'end' }}>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleRunGidep}
              disabled={sendingSate.isRunningGidepScan}
              endIcon={sendingSate.isRunningGidepScan ? <CircularProgress size={16} /> : null}
            >
              Run GIDEP Scan
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={sendingSate.isSaving}
              endIcon={sendingSate.isSaving ? <CircularProgress size={16} /> : null}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      <DmsmsGidepModal open={gidepOpen} onClose={handleGidepModelClose} />
    </Paper>
  );
}
