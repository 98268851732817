import React, { useState } from 'react';
import { ThemeColorDto } from '../../../api/types/types';
import { Box, Grid, ListItemText, MenuItem, MenuItemProps, Select } from '@mui/material';

interface ColorThemePickerProps {
  colorThemes: ThemeColorDto[];
  selectedThemeId: number | null;
  isDisabled: boolean;
  onChange: (selectedThemeId: number | null) => void;
}

const ColorThemePicker: React.FC<ColorThemePickerProps> = ({
  colorThemes,
  selectedThemeId,
  isDisabled,
  onChange,
}) => {
  const [newThemeId, setNewThemeId] = useState<number | null>(() => {
    const theme = colorThemes.find((t) => t.id === selectedThemeId);
    return theme ? theme.id : null;
  });

  const handleThemeChange = (e: any) => {
    const themeId = e.target.value === '' ? null : (e.target.value as number);
    setNewThemeId(themeId);
    onChange(themeId);
  };

  return (
    <Select
      disabled={isDisabled}
      value={newThemeId !== null ? newThemeId : ''}
      onChange={handleThemeChange}
      sx={{ width: 250, height: '2em' }}
      renderValue={(value) => {
        if (value == null) {
          return <em>None</em>;
        }
        const theme = colorThemes.find((t) => t.id === value);
        return theme ? <ThemeDisplay theme={theme} /> : null;
      }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {colorThemes.map((theme: ThemeColorDto) => (
        <ThemeSelectMenuItem value={theme.id} key={theme.id} theme={theme} />
      ))}
    </Select>
  );
};

export default ColorThemePicker;

// Custom MenuItem component for rendering themes in the Select dropdown
interface ThemeSelectMenuItemProps extends MenuItemProps {
  theme: ThemeColorDto;
}

const ThemeSelectMenuItem: React.FC<ThemeSelectMenuItemProps> = ({ theme, ...props }) => {
  return (
    <MenuItem {...props}>
      {/* Display the swatches inline in the dropdown */}
      <ThemeDisplay theme={theme} />
    </MenuItem>
  );
};

// Component for displaying a theme (name + swatches)
interface ThemeDisplayProps {
  theme: ThemeColorDto;
}

export const ThemeDisplay: React.FC<ThemeDisplayProps> = ({ theme }) => {
  // First, compute complementaries for the entire scale
  const complementaries = theme.monochromaticScale.map((swatch, index) =>
    computeComplementaries(theme.monochromaticScale, index)
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}>
      <ListItemText primary={theme.name} sx={{ marginRight: '20px', width: '50%' }} />
      <Grid container spacing={0.75}>
        {theme &&
          theme.monochromaticScale &&
          getSampleOfFive(complementaries).map(({ bgColor, strokeColor, textColor }, index) => {
            return (
              <Grid item key={index}>
                <Box
                  sx={{
                    width: 15,
                    height: 15,
                    backgroundColor: bgColor,
                    border: `1px solid ${strokeColor}`,
                    color: textColor,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '0.75rem',
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

// Function to compute complementary colors based on the original index in the scale
function computeComplementaries(monochromaticScale: string[], bgColorIndex: number) {
  const bgColor = monochromaticScale[bgColorIndex];
  const strokeColor = monochromaticScale[2]; // Assuming 2 is used for strokes
  const textColor = bgColorIndex <= 4 ? monochromaticScale[8] : monochromaticScale[2];

  return { bgColor, strokeColor, textColor };
}

// Function to get a sample of 5 complementary colors
function getSampleOfFive(
  complementaries: { bgColor: string; strokeColor: string; textColor: string }[]
) {
  if (complementaries.length < 9) {
    throw new Error('Array must contain at least 9 elements');
  }

  return [
    complementaries[0],
    complementaries[2],
    complementaries[4],
    complementaries[6],
    complementaries[8],
  ];
}
