import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, DataGrid, Form, LoadIndicator } from 'devextreme-react';
import { GroupItem, Item, SimpleItem } from 'devextreme-react/cjs/form';
import { Column, MasterDetail } from 'devextreme-react/cjs/data-grid';
import './index.scss';
import { formatDate } from '../../../../utils/format-helpers';
import DnbAPI from '../../../../api/dnb/dnbAPI';

const PrincipalsDetails = ({ principal, caption = '' }) => {
  return (
    <Form>
      <GroupItem caption={caption} colSpan={6} colCount={6}>
        <SimpleItem
          dataField=""
          label={{ text: 'FULL NAME' }}
          colSpan={2}
          editorOptions={{
            readOnly: true,
            value: principal?.fullName,
          }}
        />
        <SimpleItem
          dataField=""
          label={{ text: 'GENDER' }}
          colSpan={1}
          editorOptions={{
            readOnly: true,
            value: principal?.gender,
          }}
        />
        <SimpleItem
          dataField=""
          label={{ text: 'BIRTHDATE' }}
          editorType="dxDateBox"
          colSpan={1}
          editorOptions={{
            readOnly: true,
            value: principal?.birthdate,
          }}
        />
        <SimpleItem
          dataField=""
          label={{ text: 'BIRTH PLACE' }}
          colSpan={1}
          editorOptions={{
            readOnly: true,
            value: principal?.birthPlace,
          }}
        />
        <SimpleItem
          dataField=""
          label={{ text: 'NATIONALITY' }}
          colSpan={1}
          editorOptions={{
            readOnly: true,
            value: principal?.nationality,
          }}
        />
        <SimpleItem
          dataField=""
          label={{ text: 'RESPONSIBLE AREAS' }}
          colSpan={3}
          editorOptions={{
            readOnly: true,
            value: principal?.responsibleAreas,
          }}
        />
        <SimpleItem
          dataField=""
          label={{ text: 'PAST BANKRUPTCY?' }}
          colSpan={1}
          editorOptions={{
            readOnly: true,
            value: principal?.hasPastBankruptcy ? 'Yes' : 'No',
          }}
        />
        <SimpleItem
          dataField=""
          label={{ text: 'DISQUAL. DIRECTOR?' }}
          colSpan={1}
          editorOptions={{
            readOnly: true,
            value: principal?.isDisqualifiedDirector ? 'Yes' : 'No',
          }}
        />
        <SimpleItem
          dataField=""
          label={{ text: 'SIGNING AUTH.?' }}
          colSpan={1}
          editorOptions={{
            readOnly: true,
            value: principal?.isSigningAuthority ? 'Yes' : 'No',
          }}
        />

        <GroupItem colSpan={3}>
          <p className="filing-datagrid-primary-title">Job Titles</p>
          <DataGrid dataSource={principal?.dnBPersonJobTitles} showBorders={true}>
            <Column dataField="title" caption="Title" />
          </DataGrid>
        </GroupItem>

        <GroupItem colSpan={3}>
          <p className="principals-datagrid-primary-title">Management Responsibilities</p>
          <DataGrid dataSource={principal?.dnBPersonManagementResponsibilities} showBorders={true}>
            <Column dataField="title" caption="Title" />
          </DataGrid>
        </GroupItem>

        <GroupItem colSpan={3}>
          <p className="principals-datagrid-primary-title">Positions</p>
          <DataGrid dataSource={principal?.dnBPersonPositions} showBorders={true}>
            <Column
              dataField="startDate"
              caption="Start Date"
              cellRender={(cellData) => formatDate(cellData.data.startDate)}
            />
            <Column dataField="position" caption="Position" />
          </DataGrid>
        </GroupItem>

        <GroupItem colSpan={3}>
          <p className="principals-datagrid-primary-title">Associations</p>
          <DataGrid dataSource={principal?.dnBPersonAssociations} showBorders={true}>
            <Column
              dataField="startDate"
              caption="Start Date"
              cellRender={(cellData) => formatDate(cellData.data.startDate)}
            />
            <Column dataField="name" caption="Name" />
            <Column dataField="duns" caption="DUNS" />
          </DataGrid>
        </GroupItem>

        <GroupItem colSpan={3}>
          <p className="principals-datagrid-primary-title">Education</p>
          <DataGrid dataSource={principal?.dnBPersonEducations} showBorders={true}>
            <Column dataField="status" caption="Status" />
            <Column dataField="qualification" caption="Qualification" />
          </DataGrid>
        </GroupItem>

        <GroupItem colSpan={3}>
          <p className="principals-datagrid-primary-title">Disqualifications</p>
          <DataGrid dataSource={principal?.dnBPersonDisqualifications} showBorders={true}>
            <Column
              dataField="startDate"
              caption="Start Date"
              cellRender={(cellData) => formatDate(cellData.data.startDate)}
            />
            <Column dataField="description" caption="Description" />
            <Column
              dataField="endDate"
              caption="End Date"
              cellRender={(cellData) => formatDate(cellData.data.endDate)}
            />
          </DataGrid>
        </GroupItem>

        <GroupItem colSpan={3}>
          <p className="principals-datagrid-primary-title">Hobbies</p>
          <DataGrid dataSource={principal?.dnBPersonHobbies} showBorders={true}>
            <Column dataField="hobby" caption="Hobby" />
          </DataGrid>
        </GroupItem>
      </GroupItem>
    </Form>
  );
};

const Principals = () => {
  const [principalsData, setPrincipalsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  const fetchData = async (refresh) => {
    if (!refresh) setIsLoading(true);

    try {
      const data = await DnbAPI.getPrincipalsDataById(id, refresh);
      setPrincipalsData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = () => {
    fetchData(true);
  };

  const mostSeniorPrincipal = principalsData?.dnBPrincipalPeople.find(
    (person) => person.isMostSenior
  );

  const otherPrincipals = principalsData?.dnBPrincipalPeople.filter(
    (person) => !person.isMostSenior
  );

  if (error) return <div>No Data</div>;

  const renderDetails = ({ data }) => <PrincipalsDetails principal={data.data} />;

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadIndicator />
        </div>
      ) : (
        <>
          <Form formData={principalsData} colCount={6}>
            <GroupItem caption="Summary" colSpan={6} colCount={6}>
              <Item
                dataField="currentCount"
                label={{ text: 'CURRENT CNT.' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <Item
                dataField="detrimentalCount"
                label={{ text: 'DETRIMENTAL CNT.' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <Item
                dataField="detrimentalPercent"
                label={{ text: 'DETRIMENTAL %' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <Item
                dataField="favorableInactiveAssociationsCount"
                label={{ text: 'FAV. INACTIVE CNT.' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <GroupItem colSpan={2} colCount={4} cssClass="custom-item">
                <SimpleItem
                  dataField="dateUpdated"
                  editorType="dxDateBox"
                  label={{ text: 'LAST UPDATED' }}
                  colSpan={3}
                  editorOptions={{ readOnly: true }}
                />
                <Item colSpan={1}>
                  <Button
                    icon="refresh"
                    type="normal"
                    onClick={handleRefresh}
                    hint="Refresh"
                    style={{ marginTop: '32px', marginLeft: '10px' }}
                  />
                </Item>
              </GroupItem>
              <Item
                dataField="favorableInactiveAssociationsCount"
                label={{ text: 'INACTIVE ASSOC.' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <Item
                dataField="otherAssociationsCount"
                label={{ text: 'OTHER ASSOC.' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <Item
                dataField="unfavorableInactiveAssociationsCount"
                label={{ text: 'UNFAV. INACTIVE' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
            </GroupItem>
          </Form>
          <PrincipalsDetails principal={mostSeniorPrincipal} caption="Most Senior Principal" />
          <Form>
            <GroupItem caption="Other Current Principals">
              <DataGrid
                dataSource={otherPrincipals}
                showBorders={true}
                keyExpr="dnBPrincipalPersonId"
              >
                <Column dataField="fullName" caption="Full Name" />
                <Column
                  dataField="dnBPersonJobTitles"
                  caption="Job Titles"
                  cellRender={({ data }) =>
                    data.dnBPersonJobTitles.map((title) => title.title).join(', ')
                  }
                />
                <MasterDetail enabled={true} component={renderDetails} />
              </DataGrid>
            </GroupItem>
          </Form>
        </>
      )}
    </>
  );
};

export default Principals;
