import React from 'react';
import DataGrid, { Column } from 'devextreme-react/cjs/data-grid';
import './index.scss';

const OtherItemsComponent = ({ otherItemsData }) => {
  const renderIcon = (value) => {
    return value ? (
      <span className="icon green">&#10004;</span>
    ) : (
      <span className="icon red">&#10008;</span>
    );
  };

  return (
    <>
      <p className="filing-datagrid-primary-title">Other Items</p>
      <DataGrid
        dataSource={[otherItemsData]}
        showBorders={true}
        columnAutoWidth={true}
        width="100%"
      >
        <Column
          dataField="hasCriminalProceedings"
          caption="Criminal Proceedings"
          cellRender={(cellData) => renderIcon(cellData.data.hasCriminalProceedings)}
        />
        <Column
          dataField="hasOpenCriminalProceedings"
          caption="Open Criminal Proceedings"
          cellRender={(cellData) => renderIcon(cellData.data.hasOpenCriminalProceedings)}
        />
        <Column
          dataField="hasFinancialEmbarrassment"
          caption="Financial Embarrassment"
          cellRender={(cellData) => renderIcon(cellData.data.hasFinancialEmbarrassment)}
        />
        <Column
          dataField="hasOpenFinancialEmbarrassment"
          caption="Open Financial Embarrassment"
          cellRender={(cellData) => renderIcon(cellData.data.hasOpenFinancialEmbarrassment)}
        />
      </DataGrid>
    </>
  );
};

export default OtherItemsComponent;
