import React, { useEffect, useRef, useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Snackbar,
  Alert,
  SnackbarCloseReason,
  CircularProgress,
} from '@mui/material';
import chroma from 'chroma-js';
import { useDispatch, useSelector } from 'react-redux';
import { setPrimaryColor } from '../../../slices/themeSlice';
import { generateMonochromaticScale } from './utils/generateMonochromaticScale';
import MonochromaticScale from './MonochromaticScale';
import AdminSettingsApi from '../../../api/adminSettings/adminSettingsApi';
import { ThemeColorDto } from '../../../api/types/types';
import { SnackbarProps } from '../../../types/Snackbar';

const ColorPicker: React.FC = () => {
  const allColors = useSelector((state: any) => state.theme.colors);
  const primaryColor = useSelector((state: any) => state.theme.primaryColor);
  const [hexCode, setHexCode] = useState<string>(primaryColor);
  const [monochromaticScale, setMonochromaticScale] = useState<string[]>(
    generateMonochromaticScale(primaryColor)
  );
  const [snackbar, setSnackbar] = useState<SnackbarProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const primaryColorRef = useRef(primaryColor);

  const resetPrimaryColor = () => {
    setHexCode(primaryColor);
    setMonochromaticScale(generateMonochromaticScale(primaryColor));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
        setHexCode(primaryColorRef.current);
        setMonochromaticScale(generateMonochromaticScale(primaryColorRef.current));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    resetPrimaryColor();
    primaryColorRef.current = primaryColor;
  }, [primaryColor]);

  const dispatch = useDispatch();

  const handleHexChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let inputHex = event.target.value;

    if (
      (inputHex.startsWith('#') && inputHex.length > 7) ||
      (!inputHex.startsWith('#') && inputHex.length > 6)
    )
      return;

    if (!inputHex.startsWith('#')) {
      inputHex = `#${inputHex}`;
    }

    setHexCode(inputHex);

    if (chroma.valid(inputHex)) {
      setMonochromaticScale(generateMonochromaticScale(inputHex));
    }
  };

  const updatePrimaryColor = async () => {
    try {
      setLoading(true);
      await AdminSettingsApi.updateColor({
        ...allColors.find((c: ThemeColorDto) => c.isPrimaryColor === true),
        hex: hexCode,
      });
      dispatch(setPrimaryColor(hexCode));
      setSnackbar({
        open: true,
        severity: 'success',
        message: 'Primary color updated successfully.',
      });
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || 'An error occurred';

      setSnackbar({
        open: true,
        severity: 'error',
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <>
      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={snackbar?.severity === 'error' ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          boxShadow: 1,
          borderRadius: '10px',
          height: loading ? '338.53px' : 'auto',
          width: loading ? '212px' : 'fit-content',
          alignContent: loading ? 'center' : '',
          justifyItems: loading ? 'center' : '',
        }}
        ref={componentRef}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box
              sx={{
                backgroundColor: hexCode,
                height: '70px',
                borderRadius: '10px 10px 0px 0px',
                color: '#FFFFFF',
              }}
              alignContent={'center'}
              p={1}
            >
              <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
                {hexCode}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                padding: 1,
                borderRadius: '0px 0px 10px 10px',
              }}
            >
              <Typography variant="body2">Dashboard Elements</Typography>

              <MonochromaticScale
                monochromaticScale={monochromaticScale}
                onClick={(color: string) => {
                  setHexCode(color);
                  setMonochromaticScale(generateMonochromaticScale(color));
                }}
              />

              <Typography variant="body2" pt={1}>
                Navigation
              </Typography>
              <Box
                sx={{
                  backgroundColor: monochromaticScale[1],
                  padding: 1,
                  borderRadius: '10px',
                  textAlign: 'center',
                  height: '60px',
                  color: monochromaticScale[4],
                }}
                alignContent={'center'}
              >
                Selected Nav Item
              </Box>

              <Box my={2}>
                <TextField label="Hex Code" value={hexCode} onChange={handleHexChange} fullWidth />
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Button
                  sx={{
                    backgroundColor: monochromaticScale[1],
                    color: '#FFF',
                    width: '45%',
                  }}
                  onClick={resetPrimaryColor}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    backgroundColor: monochromaticScale[4],
                    color: '#FFF',
                    width: '45%',
                  }}
                  onClick={updatePrimaryColor}
                >
                  Publish
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ColorPicker;
