import React, { useEffect, useState } from 'react';
import { DashboardKeyMetricsViewModelDto } from '../../../api/types/types';
import DashboardApi from '../../../api/dashboard/dashboardApi';
import KeyMetricsUI from './KeyMetricsUI';

const KeyMetrics = () => {
  const [metrics, setMetrics] = useState<DashboardKeyMetricsViewModelDto | null>(null);

  useEffect(() => {
    const fetchKeyMetrics = async () => {
      try {
        const data = await DashboardApi.getKeyMetrics();
        setMetrics(data);
      } catch (error) {
        console.error('Error fetching key metrics:', error);
      }
    };

    fetchKeyMetrics();
  }, []);

  return <KeyMetricsUI metrics={metrics}></KeyMetricsUI>;
};

export default KeyMetrics;
