import React from 'react';
import { Card, CardContent, Typography, Box, Grid, Tooltip } from '@mui/material';
import { RiskCaseViewModelDto, SupplierHealthDto } from '../../../api/types/types';
import { getColor } from './utils/colorScale';

interface SupplierCardProps {
  score: SupplierHealthDto;
  onSupplierClick: (riskCases: RiskCaseViewModelDto[]) => void;
}

const SupplierCard: React.FC<SupplierCardProps> = ({ score, onSupplierClick }) => {
  const healthPercentage = score.score;

  const handleTooltipClick = (event: React.MouseEvent, kwmId: number) => {
    event.stopPropagation();
    onSupplierClick(
      score.riskCases.filter((rc) =>
        rc.generalInformation.keywordModels.some((kwm) => kwm.id === kwmId)
      )
    );
  };

  return (
    <Grid item xs={6}>
      <Card onClick={() => onSupplierClick(score.riskCases)} sx={{ cursor: 'pointer' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {score.supplierName}
          </Typography>
          <Typography variant="h4" color="textPrimary">
            {score.score}%
          </Typography>
          <Box
            sx={{
              display: 'flex',
              marginTop: 1,
              height: 20,
              backgroundColor: '#c2edc1',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            {score.kwmScores?.map((kwmScore, index, array) => (
              <Tooltip
                key={kwmScore.id}
                title={kwmScore.modelName}
                arrow
                onClick={(e) => handleTooltipClick(e, kwmScore.id)}
              >
                <Box
                  sx={{
                    width: `${kwmScore.riskPercentage}%`,
                    backgroundColor: getColor((index / array.length) * 100),
                    transition: 'width 0.3s ease',
                  }}
                />
              </Tooltip>
            ))}

            {healthPercentage > 0 && (
              <Tooltip
                title="Healthy"
                arrow
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Box
                  sx={{
                    width: `${healthPercentage}%`,
                    backgroundColor: '#c2edc1',
                    transition: 'width 0.3s ease',
                  }}
                />
              </Tooltip>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SupplierCard;
