// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';

const { ALTRUITY_API_URL } = config;

const Collection = {
  getCollectionsByKWM: async function (id: number, archived: boolean) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskDiscovery/GetCollectionsByKeywordModel/${id}?archived=${archived}`
    );
    return data.result;
  },
  getCollectionsBySupplier: async function (id: number, archived: boolean) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskDiscovery/GetCollectionsBySupplier/${id}?archived=${archived}`
    );
    return data.result;
  },
};

export default Collection;
