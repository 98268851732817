import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import {
  Button,
  TextField,
  FormGroup,
  Box,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  SnackbarCloseReason,
} from '@mui/material';
import {
  AltruityCheckbox,
  AltruityTextArea,
  HelpIcon,
  ValidationSnackbar,
} from '../../../components/form/index';
import { textAreaStyles } from '../../../components/form/styles';
// @ts-ignore: Implicit any module
import informationPng from '../../../assets/information.png';
import { isNullOrWhitespace } from '../../../utils/validation-helpers';
import { NsnSearchMultipleDto } from '../../../api/types/types';
import {
  niinHelp,
  cageHelp,
  partHelp,
  sosHelp,
  amscHelp,
  querySyntaxHelp,
  combineHelp,
  // @ts-ignore: Implicit any module
} from './help-content';
import './index.scss';

export default function NsnPage() {
  const navigate = useNavigate();
  const cageRef = useRef<HTMLInputElement>();
  const pnRef = useRef<HTMLInputElement>();
  const nameRef = useRef<HTMLInputElement>();

  // For state
  const [formData, setFormData] = useState<NsnSearchMultipleDto>({
    nsns: [],
    cages: [],
    partNumbers: [],
    doSosOnly: false,
    doAmscgOnly: false,
    doFullContent: false,
    keywordSearch: '',
    combine: false,
    page: 1,
    rowsPerPage: 50,
  });

  // Validation snackbar state
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
  });

  /**
   * Closes the validation SnackBar
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState({ open: false, message: '' });
  };

  /***
   * Updates the form data state
   */
  function handleFormFieldChange(e: any, fieldName: string) {
    var { value } = e.target;

    /*
		if (fieldName === "nsns") {
			if (isNullOrWhitespace(value)) {
				cageRef.current!.disabled = false;
				pnRef.current!.disabled = false;
				nameRef.current!.disabled = false;
			} else {
				cageRef.current!.disabled = true;
				pnRef.current!.disabled = true;
				nameRef.current!.disabled = true;
			}
		}
		*/

    // Use checkbox 'checked' prop to determine value.
    if (e.target.type === 'checkbox') {
      value = e.target.checked ? true : false;
    } else if (fieldName === 'nsns' || fieldName === 'cages' || fieldName === 'partNumbers') {
      value = value.split('\n');
      // Filter empty lines.
      value = value.filter((v: string) => {
        return !isNullOrWhitespace(v);
      });
    }

    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
  }

  /**
   * Submits search parameters
   */
  async function handleSubmit() {
    if (
      formData.nsns.length === 0 &&
      formData.cages.length === 0 &&
      formData.partNumbers.length === 0 &&
      isNullOrWhitespace(formData.keywordSearch)
    ) {
      setSnackBarState({
        open: true,
        message: 'No Search Criteria Provided!',
      });
      return;
    }

    // Check that lists contain the same number of items.
    if (formData.combine === true) {
      var checkLengths = [];
      if (formData.nsns.length > 0) {
        checkLengths.push(formData.nsns.length);
      }
      if (formData.partNumbers.length > 0) {
        checkLengths.push(formData.partNumbers.length);
      }
      if (formData.cages.length > 0) {
        checkLengths.push(formData.cages.length);
      }

      const hasDifferentItem = checkLengths.some((item, index, arr) => item !== arr[0]);

      if (hasDifferentItem === true) {
        setSnackBarState({
          open: true,
          message: 'Lists must contain the same number of items if they are populated.',
        });
        return;
      }
    }

    // If NSN was entered, go directly to details page. Else, go to search results.
    if (
      formData.nsns.length === 1 &&
      formData.cages.length === 0 &&
      formData.partNumbers.length === 0
    ) {
      navigate('/nsn/details', {
        state: {
          nsn: formData.nsns[0],
        },
      });
    } else {
      navigate('/nsn/results', {
        state: {
          query: formData,
        },
      });
    }
  }

  const itemsParentBoxSX = {
    position: 'relative',
    width: '100px',
  };

  const itemsBoxSX = {
    position: 'absolute',
    bottom: '0',
    overflow: 'visible',
    fontSize: '0.9rem',
  };

  return (
    <>
      <Box className="nsn-query-container page-container">
        <h4 className="page-title">NSN Query</h4>
        <Grid container>
          <Grid item xs={12} sm={6} style={{ minWidth: '500px' }}>
            <Grid
              container
              direction="column"
              rowSpacing={1}
              className="rcorners"
              style={{
                width: '98%',
                maxWidth: '500px',
                margin: 'auto',
                marginTop: '20px',
              }}
              sx={{
                marginTop: '0px',
                marginBottom: '0px',
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  fontSize: '20px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  color: 'black',
                  textAlign: 'center',
                }}
              >
                <b>Enter FLIS Search Criteria</b>
              </Grid>
              <Grid item>
                <FormGroup>
                  <AltruityCheckbox
                    label="Combine Parameters"
                    name="combine"
                    defaultChecked={formData.combine}
                    onChange={(e: any) => handleFormFieldChange(e, 'combine')}
                    helpConent={combineHelp}
                  />
                </FormGroup>
              </Grid>
              <Grid item>
                <Box display="flex">
                  <AltruityTextArea
                    label="NSN/NIIN"
                    width="50%"
                    onChange={(e: any) => handleFormFieldChange(e, 'nsns')}
                    fullWidth
                    rows={4}
                    id="nsn"
                    name="nsn"
                    helpConent={niinHelp}
                  />
                  <Box sx={itemsParentBoxSX}>
                    <Box sx={itemsBoxSX}>
                      <span>Items: {formData.nsns.length}</span>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex">
                  <AltruityTextArea
                    label="Part Number"
                    width="50%"
                    rows={4}
                    onChange={(e: any) => handleFormFieldChange(e, 'partNumbers')}
                    fullWidth
                    id="part"
                    name="part"
                    helpConent={partHelp}
                    inputRef={pnRef}
                  />
                  <Box sx={itemsParentBoxSX}>
                    <Box sx={itemsBoxSX}>
                      <span>Items: {formData.partNumbers.length}</span>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex">
                  <AltruityTextArea
                    label="Cage Code"
                    width="30%"
                    onChange={(e: any) => handleFormFieldChange(e, 'cages')}
                    id="cage"
                    name="cage"
                    rows={4}
                    helpConent={cageHelp}
                    inputRef={cageRef}
                  />
                  <Box sx={itemsParentBoxSX}>
                    <Box sx={itemsBoxSX}>
                      <span>Items: {formData.cages.length}</span>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <FormGroup>
                  <AltruityCheckbox
                    label="Include only Source of Supply parts"
                    name="doSosOnly"
                    defaultChecked={formData.doSosOnly}
                    onChange={(e: any) => handleFormFieldChange(e, 'sos')}
                    helpConent={sosHelp}
                  />
                  <AltruityCheckbox
                    label="Include only NSNs that have an
												AMSC of 'G'"
                    name="doAmscgOnly"
                    defaultChecked={formData.doAmscgOnly}
                    onChange={(e: any) => handleFormFieldChange(e, 'amsc')}
                    helpConent={amscHelp}
                  />
                </FormGroup>
              </Grid>
              <Grid item>
                <Box display="flex" className="typography-container">
                  <Typography>Item Name Keyword(s)</Typography>
                  <HelpIcon label="Keyword Search Syntax">{querySyntaxHelp}</HelpIcon>
                </Box>
                <RadioGroup
                  row
                  name="doFullContent"
                  onChange={(e: any) => handleFormFieldChange(e, 'fullContent')}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label=" Item Name & Characteristics"
                  />
                  <FormControlLabel value={false} control={<Radio />} label="Item Name Only" />
                </RadioGroup>
                <TextField
                  id="keywordSearch"
                  name="keywordSearch"
                  inputRef={nameRef}
                  fullWidth
                  multiline
                  rows={4}
                  onChange={(e: any) => handleFormFieldChange(e, 'keywordSearch')}
                />
              </Grid>
              <Grid item>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button variant="contained" onClick={handleSubmit}>
                    GO
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p>
              NSN Query is provided as a service to support the Procurement, Maintenance and Support
              of Government supply items. It utilizes the Public Federal Logistics Information
              System Data made available by the Defense Logistics Agency.
            </p>
            <p>
              Please note that NSN Query is <b>NOT</b> affiliated with the U.S. Government.
            </p>
            <p>
              For an explantion of each of the Query fields, click on the{' '}
              <img src={informationPng} alt="Help icon" /> icon next to each field.
            </p>
            <p>
              For a description of the Results in the Output Result tables, click on the column
              heading.
            </p>
            <p>
              To see the Cage detail for a part, click on the cage code in the Reference/Part Number
              Table.
            </p>
            <p>
              Lines in <b>Bold</b> in the Reference/Part Number Table are considered the Source of
              Supply, based on the RNCC and RNVC values.
            </p>
          </Grid>
        </Grid>
      </Box>
      <ValidationSnackbar
        open={snackBarState.open}
        message={snackBarState.message}
        onClose={handleSnackBarClose}
        severity="error"
      />
    </>
  );
}
