const groupMenuOptions = (options) => {
  const alphabeticallyGroupedOptions = [
    { id: '1', characterGroup: 'A - D', items: [] },
    { id: '2', characterGroup: 'E - H', items: [] },
    { id: '3', characterGroup: 'I - L', items: [] },
    { id: '4', characterGroup: 'M - R', items: [] },
    { id: '5', characterGroup: 'S - Z', items: [] },
  ];
  const regexAD = /^[a-dA-D]/;
  const regexEH = /^[e-hE-H]/;
  const regexIL = /^[i-lI-L]/;
  const regexMR = /^[m-rM-R]/;
  const regexSZ = /^[s-zS-Z]/;

  options.forEach((option) => {
    if (regexAD.test(option.supplierName)) {
      alphabeticallyGroupedOptions[0].items.push({
        id: option.id,
        supplierName: option.supplierName,
      });
    } else if (regexEH.test(option.supplierName)) {
      alphabeticallyGroupedOptions[1].items.push({
        id: option.id,
        supplierName: option.supplierName,
      });
    } else if (regexIL.test(option.supplierName)) {
      alphabeticallyGroupedOptions[2].items.push({
        id: option.id,
        supplierName: option.supplierName,
      });
    } else if (regexMR.test(option.supplierName)) {
      alphabeticallyGroupedOptions[3].items.push({
        id: option.id,
        supplierName: option.supplierName,
      });
    } else if (regexSZ.test(option.supplierName)) {
      alphabeticallyGroupedOptions[4].items.push({
        id: option.id,
        supplierName: option.supplierName,
      });
    }
  });
  return alphabeticallyGroupedOptions;
};

export default groupMenuOptions;
