import React, { useState } from 'react';
import { TableContainer, Paper, Tabs, Tab, Box } from '@mui/material';
import { PartForecastDto } from '../../../api/types/types';
import './ExpandedTableContent.scss';
import SubstitutePartsGrid from './part-detail-grids/SubstitutePartsGrid';
import PartActionsGrid from './part-detail-grids/PartActionsGrid';
import CaseLogGrid from './part-detail-grids/CaseLogGrid';
import PartHistoryGrid from './part-detail-grids/PartHistoryGrid';
import ForecastedUsageGrid from './part-detail-grids/ForecastedUsageGrid';
import { GridDensity } from '@mui/x-data-grid-pro';
export interface ForecastedUsage extends PartForecastDto {
  extCost: number;
}
interface ExpandedTableContentProps {
  watchedPartId: number;
  density: GridDensity;
}

const ExpandedRowContent: React.FC<ExpandedTableContentProps> = ({ watchedPartId, density }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function setTabContent(tabValue: number, watchedPartId: number) {
    switch (tabValue) {
      case 0:
        return <ForecastedUsageGrid watchedPartId={watchedPartId} />;
      case 1:
        return <SubstitutePartsGrid watchedPartId={watchedPartId} />;
      case 2:
        return <PartActionsGrid watchedPartId={watchedPartId} />;
      case 3:
        return <CaseLogGrid watchedPartId={watchedPartId} />;
      case 4:
        return <PartHistoryGrid watchedPartId={watchedPartId} density={density} />;
    }
    return <></>;
  }

  return (
    <Box sx={{ width: 'calc(100% - 20px)' }} className={`expanded-table-content ${density}`}>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="part details tabs">
        <Tab label="FORECASTED PART USAGE" />
        <Tab label="SUBSTITUTE PARTS" />
        <Tab label="ACTIONS" />
        <Tab label="CASE LOG" />
        <Tab label="HISTORY" />
      </Tabs>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Box sx={{ p: 2 }}>{setTabContent(tabValue, watchedPartId)}</Box>
      </TableContainer>
    </Box>
  );
};

export default ExpandedRowContent;
