import React, { ReactNode } from 'react';
import './Card.scss';

interface ICardProps {
  children: ReactNode;
}

const Card = ({ children }: ICardProps) => {
  return <div className="dx-card custom-card">{children}</div>;
};

export default Card;
