import httpClient from '../configs/axiosConfig';
import config from '../../config';

const { ALTRUITY_API_URL } = config;

const Reports = {
  getHealthScores: async function () {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/Supplier/Reports/GetHealthScores?includeInactiveSuppliers=false`
    );
    return data.result;
  },
};

export default Reports;
