import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
} from '@mui/material';
import { RiskCaseViewModelDto, SupplierHealthDto } from '../../../api/types/types';
import { getColor } from './utils/colorScale';

interface HeatRankingProps {
  scores: SupplierHealthDto[];
  onSupplierClick: (riskCases: RiskCaseViewModelDto[]) => void;
}

const HeatRanking: React.FC<HeatRankingProps> = ({ scores, onSupplierClick }) => {
  const [orderBy, setOrderBy] = useState<keyof SupplierHealthDto>('score');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');

  const handleSort = (property: keyof SupplierHealthDto) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderBy(property);
    setOrderDirection(isAsc ? 'desc' : 'asc');
  };

  const sortedSuppliers = [...scores].sort((a, b) => {
    if (orderDirection === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
  });

  return (
    <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={orderBy === 'supplierName' ? orderDirection : false}>
              <TableSortLabel
                active={orderBy === 'supplierName'}
                direction={orderBy === 'supplierName' ? orderDirection : 'asc'}
                onClick={() => handleSort('supplierName')}
              >
                Supplier
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'score' ? orderDirection : false}>
              <TableSortLabel
                active={orderBy === 'score'}
                direction={orderBy === 'score' ? orderDirection : 'asc'}
                onClick={() => handleSort('score')}
              >
                Score
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'riskCasesCount' ? orderDirection : false}>
              <TableSortLabel
                active={orderBy === 'riskCasesCount'}
                direction={orderBy === 'riskCasesCount' ? orderDirection : 'asc'}
                onClick={() => handleSort('riskCasesCount')}
              >
                Current Cases
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedSuppliers.map((supplier, index) => (
            <TableRow
              key={index}
              onClick={() => onSupplierClick(supplier.riskCases)}
              style={{ cursor: 'pointer' }}
            >
              <TableCell>{supplier.supplierName}</TableCell>
              <TableCell sx={{ backgroundColor: getColor(supplier.score) }}>
                <Typography color="white" textAlign={'center'}>
                  {supplier.score}%
                </Typography>
              </TableCell>
              <TableCell>{supplier.riskCasesCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HeatRanking;
