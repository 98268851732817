import React from 'react';
import { Box } from '@mui/material';

interface MonochromaticScaleProps {
  monochromaticScale: string[];
  onClick: (color: string) => void;
}

const MonochromaticScale = ({ monochromaticScale, onClick }: MonochromaticScaleProps) => {
  return (
    <Box display="flex">
      {monochromaticScale.map((color, index) => (
        <Box
          key={index}
          sx={{
            width: 16,
            height: 20,
            backgroundColor: color,
            marginRight: index < 9 ? '4px' : '0px',
            cursor: 'pointer',
            border: `2px solid ${index === 4 ? '#000' : 'transparent'}`,
            borderRadius: 1,
          }}
          onClick={() => onClick(color)}
        />
      ))}
    </Box>
  );
};

export default MonochromaticScale;
