import { Button, ButtonProps, createSvgIcon, Menu, MenuItem } from '@mui/material';
import {
  GridToolbarContainer,
  // GridToolbarDensitySelector,
  // GridToolbarFilterButton,
} from '@mui/x-data-grid';
import React, { useState } from 'react';
const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt'
);

interface ToolbarProps {
  onExportClick: (type: string) => void;
}

export default function FileDownloadToolbar(props: ToolbarProps) {
  const { onExportClick } = props;

  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLButtonElement>(null);
  const openMenu = Boolean(anchorElMenu);

  const buttonBaseProps: ButtonProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };

  return (
    <GridToolbarContainer>
      {/* <GridToolbarFilterButton />
			<GridToolbarDensitySelector /> */}
      <Button
        {...buttonBaseProps}
        onClick={(event: any) => {
          setAnchorElMenu(event.currentTarget);
        }}
      >
        Export
      </Button>
      <Menu
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={() => {
          setAnchorElMenu(null);
        }}
      >
        <MenuItem>
          <Button onClick={() => onExportClick('csv')}>CSV</Button>
        </MenuItem>
        <MenuItem>
          <Button onClick={() => onExportClick('xlsx')}>Excel</Button>
        </MenuItem>
      </Menu>
    </GridToolbarContainer>
  );
}
