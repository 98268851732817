import React from 'react';
import { FormControl, InputLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { labelStyles } from '../styles';

export interface AltruityToggleButtonProps {
  name: string;
  value: any;
}

interface AltruityToggleProps {
  name: string;
  label?: string | null;
  buttons: AltruityToggleButtonProps[];
  value: any;
  defaultValue?: any | null;
  onChange: (field: string, e: any) => void;
}

export default function AltruityToggleButtons({
  name,
  label,
  buttons,
  value,
  defaultValue,
  onChange,
}: AltruityToggleProps) {
  const labelStylesToggle = {
    ...labelStyles,
    marginBottom: '10px',
    fontWeight: '400',
    fontSize: '1.2rem',
  };

  return (
    <FormControl fullWidth>
      {label ? (
        <InputLabel shrink sx={labelStylesToggle}>
          {label}
        </InputLabel>
      ) : null}
      <ToggleButtonGroup
        color="primary"
        value={value}
        exclusive
        defaultValue={defaultValue}
        onChange={(event, newValue) => {
          onChange(name, newValue);
        }}
        aria-label="Platform"
        sx={{
          '.MuiToggleButton-root': {
            border: '1px solid #1976d2',
            color: '#1976d2',
            '&.Mui-selected': {
              backgroundColor: '#1976d2',
              color: 'white',
            },
            fontSize: '0.8rem',
            padding: '4px 8px',
            marginRight: '8px',
            borderRadius: '4px',
          },
        }}
      >
        {buttons.map((btn: AltruityToggleButtonProps) => (
          <ToggleButton key={btn.value} value={btn.value}>
            {btn.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </FormControl>
  );
}
