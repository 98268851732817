import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from '@mui/material';
import { DataGrid, GridCallbackDetails, GridCellParams, MuiEvent } from '@mui/x-data-grid';
import { createColumn } from '../../../utils/data-grid';
import CageDetailsModal from '../../modal/CageDetailsModal';
import CloseIcon from '@mui/icons-material/Close';
// @ts-ignore: Implicit any module
import NsnApi from '../../../api/nsn/nsnAPI';
// @ts-ignore: Implicit any module
import { NsnSolicitationsResultDto } from '../../api/types/types';
interface BatchProps {
  open: boolean;
  batchId: number;
  onClose: any;
}

const initialContent: NsnSolicitationsResultDto | null = null;

export default function NsnBatchResultsModal({ open, batchId, onClose }: BatchProps) {
  const [modalState, setModalState] = useState({
    title: 'Batch Results',
    isLoading: true,
    content: initialContent,
  });

  const [cageModalState, setCageModelState] = useState({
    open: false,
    cage: '',
  });

  /**
   * Handles CAGE CD cell click
   */
  async function handleCellClick(
    params: GridCellParams<any>,
    event: MuiEvent<React.MouseEvent<HTMLElement>>,
    details: GridCallbackDetails
  ) {
    if (params.field === 'soscage') {
      setCageModelState((oldState) => ({
        ...oldState,
        open: true,
        cage: params.row.soscage,
      }));
    } else if (params.field === 'awardee') {
      setCageModelState((oldState) => ({
        ...oldState,
        open: true,
        cage: params.row.awardee,
      }));
    }
  }

  /**
   * Handle cage modal close
   */
  function handleModalClose() {
    setCageModelState((oldState) => ({
      ...oldState,
      open: false,
    }));
  }
  useEffect(() => {
    async function fetchData() {
      if (batchId <= 0) return;

      setModalState((oldState) => ({
        ...oldState,
        isLoading: true,
      }));

      try {
        const response = await NsnApi.getBatchResults(batchId);

        if (response.isSuccess) {
          setModalState((oldState) => ({
            ...oldState,
            isLoading: false,
            title: 'Batch Results',
            content: response.result,
          }));
        } else {
          setModalState((oldState) => ({
            ...oldState,
            isLoading: false,
          }));
        }
      } catch {
        setModalState((oldState) => ({
          ...oldState,
          isLoading: false,
        }));
      }
    }

    fetchData();
  }, [batchId]);

  // Create column definitions
  const columns = [
    { ...createColumn('solicitation', 'Solicitation'), minWidth: 150 },
    { ...createColumn('nsn', 'NSN'), minWidth: 150 },
    { ...createColumn('ref_num', 'PART NUM'), minWidth: 200 },
    { ...createColumn('postdate', 'POST DATE') },
    {
      ...createColumn('unit_pr', 'UNIT PR', 'number'),
      valueFormatter: (value: number | null) => {
        if (value == null) return '';

        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(value);
      },
    },
    { ...createColumn('qty', 'QTY', 'number'), width: 80, flex: 0 },
    {
      ...createColumn('estvalue', 'EST VALUE', 'number'),
      valueFormatter: (value: number | null) => {
        if (value == null) return '';

        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(value);
      },
    },
    {
      ...createColumn('soscage', 'SOS CAGE'),
      cellClassName: () => {
        return 'cell-clickable bold-row';
      },
    },
    {
      ...createColumn('nomenclature', 'NOMENCLATURE'),
      minWidth: 150,
      flex: 1,
    },
    {
      ...createColumn('awardee', 'AWARDEE'),
      cellClassName: () => {
        return 'cell-clickable bold-row';
      },
    },
    {
      ...createColumn('total_contract_price', 'AWARDED CONTRACT', 'number'),
      minWidth: 175,
      valueFormatter: (value: number | null) => {
        if (value == null) return '';

        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(value);
      },
    },
    {
      ...createColumn('healthScore', 'Health', 'number'),
      width: 80,
      flex: 0,
    },
  ];

  return createPortal(
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
          }}
          id="dialog-title"
          style={{
            backgroundColor: '#306EB6',
            fontSize: '20px',
            fontWeight: 'bold',
            color: 'white',
            paddingRight: '60px',
          }}
        >
          {modalState.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {modalState.isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
              <LinearProgress sx={{ width: '100%' }} />
            </Box>
          ) : (
            <DialogContentText id="dialog-description">
              {modalState.content && (
                <DataGrid
                  autoHeight
                  getRowId={() => 10000 * Math.random()}
                  density="compact"
                  rows={modalState.content}
                  columns={columns}
                  onCellClick={handleCellClick}
                />
              )}
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
      <CageDetailsModal
        open={cageModalState.open}
        cage={cageModalState.cage}
        onClose={handleModalClose}
      />
    </>,
    document.getElementById('app-modal-2')!
  );
}
