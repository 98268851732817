import React, { useState, useEffect } from 'react';
import {
  ArticleDto,
  CaseLogsViewModelDto,
  GeneralInformationViewModelDto,
  MeasuresViewModelDto,
  RiskCaseViewModelDto,
} from '../../../../api/types/types';
import { RiskCaseViewDropdownsData } from '../../models/RiskCaseViewDropdownsData';
import GeneralInformationRiskCaseForm from './GeneralInformationForm';
import RiskCaseAccordion from '../../../risk-discovery/risk-case-popup/RiskCaseAccordion';
import { Typography } from '@mui/material';
import MeasuresForm from './MeasuresForm';
import { FormStatus } from '../../models/FormStatus';
import ArticlesTable, { Article } from '../../../risk-Cases-tab/article/RiskCaseArticle';
import CaseLogsForm from './CaseLogsForm';

interface RiskCaseFormProps {
  data: RiskCaseViewModelDto | null;
  dropdownData: RiskCaseViewDropdownsData | null;
  onFormUpdate: (data: RiskCaseViewModelDto, status: FormStatus) => void;
}

const RiskCaseForm = ({ data, dropdownData, onFormUpdate }: RiskCaseFormProps) => {
  // Initialize the form state with the received data or empty values
  const [formData, setFormData] = useState<RiskCaseViewModelDto>({
    generalInformation: data?.generalInformation ?? {
      riskCaseId: 0,
      riskCaseName: '',
      description: '',
      owner: {
        key: 'U:0',
        name: '',
      },
      subscribers: [],
      suppliers: [],
      keywordModels: [],
      dateAdded: new Date(),
      dateUpdated: null,
      dateDeleted: null,
      isActive: true,
      isDeleted: false,
    },
    measures: data?.measures ?? {
      riskMeasures: [],
    },
    caseLogs: data?.caseLogs ?? {
      caseLogs: [],
    },
    articles: data?.articles ?? {
      articles: [],
    },
  });

  // Object to track errors by section
  const [sectionErrors, setSectionErrors] = useState<Record<string, string[]>>({
    generalInformation: [],
    measures: [],
    caseLogs: [],
    articles: [],
  });

  // Function to update errors for a specific section
  const updateSectionErrors = (section: string, status: FormStatus) => {
    setSectionErrors((prevErrors) => ({
      ...prevErrors,
      [section]: status.errorMessages, // Replace errors for this section
    }));
  };

  // Combine all section errors into a single array
  const combineErrors = () => {
    return Object.values(sectionErrors).flat();
  };

  // Emit the aggregated formData and combined errors to the parent component
  useEffect(() => {
    const combinedErrors = combineErrors();
    const formStatus: FormStatus = {
      isValid: combinedErrors.length === 0,
      errorMessages: combinedErrors,
    };
    onFormUpdate(formData, formStatus);
  }, [formData, sectionErrors, onFormUpdate]);

  // Handlers for each section's update
  const handleGeneralInformationEmit = (
    data: GeneralInformationViewModelDto,
    status: FormStatus
  ) => {
    setFormData((prev) => ({
      ...prev,
      generalInformation: data,
    }));
    updateSectionErrors('generalInformation', status);
  };

  const handleMeasuresChange = (data: MeasuresViewModelDto, status: FormStatus) => {
    setFormData((prev) => ({
      ...prev,
      measures: data,
    }));
    updateSectionErrors('measures', status);
  };

  const handleCaseLogsChange = (data: CaseLogsViewModelDto, status: FormStatus) => {
    setFormData((prev) => ({
      ...prev,
      caseLogs: data,
    }));
    updateSectionErrors('caseLogs', status);
  };

  const mapFormDataToArticles = (formData: RiskCaseViewModelDto): Article[] => {
    return formData.articles.articles.map((articleDto: ArticleDto): Article => {
      return {
        id: articleDto.id,
        title: articleDto.title,
        semanticSummary: articleDto.semanticSummary,
        sourceDomain: articleDto.sourceDomain,
        url: articleDto.url,
        sentimentMetrics: {
          positive: articleDto.positiveSentiment,
          neutral: articleDto.neutralSentiment,
          negative: articleDto.negativeSentiment,
        },
        content: articleDto.content,
        imageUrl: articleDto.imageUrl,
        suppliers: articleDto.supplierArticles
          .map((supplierArticle) => supplierArticle.supplier?.supplierName)
          .join(', '), // assuming supplierArticles has supplierName
        positiveSentiment: articleDto.positiveSentiment,
        negativeSentiment: articleDto.negativeSentiment,
      };
    });
  };

  const articlesForComponent: Article[] = mapFormDataToArticles(formData);

  const sections: { title: string; content: React.JSX.Element }[] = [
    {
      title: 'General Information',
      content: (
        <GeneralInformationRiskCaseForm
          dropdownData={dropdownData}
          initialData={formData.generalInformation}
          isLoading={false}
          onFormUpdate={handleGeneralInformationEmit}
        />
      ),
    },
    {
      title: 'Measures',
      content: (
        <MeasuresForm
          initialData={formData.measures}
          isLoading={false}
          onFormUpdate={handleMeasuresChange}
        />
      ),
    },
    {
      title: 'Case Logs',
      content: (
        <CaseLogsForm
          initialData={formData.caseLogs}
          dropdownData={dropdownData}
          isLoading={false}
          onFormUpdate={handleCaseLogsChange}
        />
      ),
    },
    {
      title: 'Articles',
      content: <ArticlesTable articles={articlesForComponent} />,
    },
  ];

  return (
    <div>
      <RiskCaseAccordion
        dataSource={sections}
        itemTitleRender={(item) => <Typography>{item.title}</Typography>}
        itemRender={(item) => <div>{item.content}</div>}
        id="accordion-container"
      />
    </div>
  );
};

export default RiskCaseForm;
