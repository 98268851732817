import React, { useEffect, useState } from 'react';
import {
  Paper,
  Checkbox,
  Select,
  MenuItem,
  Switch,
  Button,
  Box,
  FormControl,
  Typography,
  Stack,
  SnackbarCloseReason,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import ExpandedTableContent from './ExpandedTableContent';
import {
  DataGridPro,
  GridActionsCellItem,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridDensity,
  GridEventListener,
  GridFilterModel,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowSelectionModel,
  GridToolbar,
  GridTreeNode,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import './TableParts.scss';
import DmsmsApi from '../../../api/dmsms/dmsmsAPI';
import { ActionDto, WatchedPartDto } from '../../../api/types/types';
import { Cancel, Edit, Save } from '@mui/icons-material';
import { isNullOrUndefined, isNullOrWhitespace } from '../../../utils/validation-helpers';
import { ValidationSnackbar } from '../../../components/form';
import { useLocation } from 'react-router';

const lifeStages = ['Early', 'Middle', 'Late'];
const StyledIndicator = styled('span')(({ color }) => ({
  backgroundColor: color,
  borderRadius: '12px',
  padding: '4px 8px',
  color: '#fff',
  fontSize: '0.75rem',
  fontWeight: 'bold',
}));

const LifeStageIndicator = ({ stage }: { stage: string }) => {
  const getLifeStageColor = (stage: string) => {
    switch (stage) {
      case 'Early':
        return '#6FCF97';
      case 'Middle':
        return '#FFDD75';
      case 'Late':
        return '#FF6B6B';
      default:
        return '#9e9e9e';
    }
  };

  if (isNullOrWhitespace(stage)) {
    stage = 'Not Set';
  }

  return <StyledIndicator color={getLifeStageColor(stage)}>{stage}</StyledIndicator>;
};

export interface PartWithSupplierName extends WatchedPartDto {
  supplierName: string;
}

const queryParamFilters: GridFilterModel = { items: [] };

const TableParts = () => {
  const apiRef = useGridApiRef();
  // QueryParams
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lifeStageQueryParam = queryParams.get('lifeStage');
  const supplierNameQueryParam = queryParams.get('supplierName');
  const atRiskQueryParam = queryParams.get('atRisk');
  const atRiskBool = atRiskQueryParam === 'true';

  // States
  const [lifeStage, setLifeStage] = useState(lifeStageQueryParam);
  const [supplierName, setSupplierName] = useState(supplierNameQueryParam);
  const [atRiskQ, setAtRiskQ] = useState(atRiskQueryParam);
  const [partsWithActions, setPartsWithActions] = useState(false);
  const [atRiskPartsOnly, setAtRiskPartsOnly] = useState(atRiskBool);
  const [selectedAction, setSelectedAction] = useState(0);
  const [actions, setActions] = useState<{ id: number; entryText: string }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterModel, setFilterModel] = useState<GridFilterModel>(queryParamFilters);
  const [pageFirstLoad, setPageFirstLoad] = useState(true);
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [unfiltered, setUnfilteredRows] = useState<GridRowModel[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
    severity: 'error',
  });
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const [density, setDensity] = useState<GridDensity>('standard');

  /**
   * Retreives initial data
   */
  useEffect(() => {
    fetchActions();
    fetchData();
  }, [supplierName, lifeStage, atRiskQ]);

  /**
   * Applies the filter settings
   */
  useEffect(() => {
    setIsLoading(true);
    var parts = [...unfiltered];

    if (partsWithActions) {
      parts = parts.filter((row) => row.hasActions === true);
    }

    if (atRiskPartsOnly) {
      parts = parts.filter((row) => row.isAtRisk === true);
    }

    setRows(parts);

    setIsLoading(false);
  }, [partsWithActions, atRiskPartsOnly]);

  /**
   * Gets all Watched Parts
   */
  const fetchData = async () => {
    setIsLoading(true);
    try {
      var isAtRisk = !isNullOrWhitespace(atRiskQ) && atRiskQ === 'true' ? true : undefined;
      // Filtering for supplier name, at risk, and life stage is done on the server, so no need to do it here.
      const response = await DmsmsApi.getWatchedParts(supplierName, isAtRisk, lifeStage);

      if (response.isSuccess) {
        setRows(response.result);
        setUnfilteredRows(response.result);
      } else {
        console.error(response.message);
        setSnackBar(response.message);
      }
    } catch (error) {
      console.error('Error fetching parts:', error);
      setSnackBar('Error fetching parts');
    }
    setIsLoading(false);
    setPageFirstLoad(false);
  };

  /**
   * Gets all available part actions.
   */
  const fetchActions = async () => {
    try {
      const response = await DmsmsApi.getActions();
      const actionEntries = response.result.map((action: ActionDto) => ({
        id: action.id,
        entryText: action.entryText,
      }));
      setActions(actionEntries);
    } catch (error) {
      console.error('Error fetching part actions:', error);
    }
  };

  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
      message: '',
    }));
  };

  const handleFilterChange = (newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const handleActionSelect = async () => {
    if (isNullOrUndefined(selectedAction) || selectedAction === 0) {
      setSnackBar('Please choose an action');
      return;
    }

    if (selection.length === 0) {
      setSnackBar('Please select parts');
      return;
    }
    ///
    setIsLoading(true);
    try {
      const watchedPartIds = selection.map((c) => c as number);
      const response = await DmsmsApi.addActionToWatchedParts(selectedAction, watchedPartIds);

      if (response.isSuccess) {
        // Update part in row data.
        var updatedRows = [...rows];
        for (var i = 0; i < response.result.length; i++) {
          const updatedPart = response.result[i];
          updatedRows = updatedRows.map((r) => (r.id === updatedPart.id ? updatedPart : r));
        }

        setRows(updatedRows);
        setUnfilteredRows(updatedRows);
        apiRef.current.selectRows(watchedPartIds, false);
        setSelectedAction(0);
      } else {
        setSnackBar(response.message);
      }
    } catch (error) {
      console.log(error);
      setSnackBar('An error occurred.');
    }

    setIsLoading(false);
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function hendleSwtichFilter(field: string, isOn: boolean) {
    if (field === 'actions') {
      setPartsWithActions(isOn);
    }

    if (field === 'atRisk') {
      setAtRiskPartsOnly(isOn);
    }
  }

  const resetFilters = () => {
    setSupplierName(null);
    setLifeStage(null);
    setAtRiskQ(null);
    setPartsWithActions(false);
    setAtRiskPartsOnly(false);
    setSelectedAction(0);
    setFilterModel({ items: [] });
  };

  const handleExport = () => {
    const csvData = rows.map((part) => ({
      partNumber: part.partNumber,
      nsn: part.nsn,
      description: part.description,
      supplierName: part.supplierName,
      upperLevelAssembly: part.upperLevelAssembly,
      gidep: part.isInGidep,
      activeCage: part.cageStatus.length > 0 ? true : false,
      atRisk: part.isAtRisk,
      lifeStage: part.stageOfLife,
    }));

    const csvRows = [
      [
        'Part Number',
        'NSN',
        'Description',
        'Supplier Name',
        'ULA',
        'Gidep',
        'Active Cage?',
        'At Risk?',
        'Life Stage',
      ],
      ...csvData.map((row) => [
        row.partNumber,
        row.nsn,
        row.description,
        row.supplierName,
        row.upperLevelAssembly,
        row.gidep,
        row.activeCage,
        row.atRisk,
        row.lifeStage,
      ]),
    ];

    const csvString = csvRows.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported_data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Editing Actions ===========================================================
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleCellClick = async (
    params: GridCellParams<any, unknown, unknown, GridTreeNode>, // GridCellParams<any>
    event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>, // MuiEvent<React.MouseEvent<HTMLElement>>
    details: GridCallbackDetails<any> // GridCallbackDetails
  ) => {
    if (params.field === 'supplierName') {
      if (params.row.supplierId > 0) {
        window.open(`/scrm/suppliers/${params.row.supplierId}`, '_blank');
      }
    }
  };

  const columns: GridColDef[] = [
    { field: 'partNumber', headerName: 'Part No.', flex: 1 },
    { field: 'nsn', headerName: 'NSN', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    {
      field: 'supplierName',
      headerName: 'Supplier',
      type: 'string',
      flex: 1,
      cellClassName: (params) => {
        const rowData = params.row;
        var cellClass = '';
        if (rowData.supplierId > 0) {
          cellClass = 'cell-clickable';
        }

        return cellClass;
      },
    },
    { field: 'upperLevelAssembly', headerName: 'ULA', flex: 1 },
    {
      field: 'gidep',
      type: 'boolean',
      headerName: 'GIDEP',
      width: 80,
      flex: 0,
      renderCell: (params) => <Checkbox checked={params.row.isInGidep} disabled />,
    },
    {
      field: 'activeCage',
      headerName: 'Active Cage?',
      type: 'boolean',
      width: 110,
      flex: 0,
      valueGetter: (value, row) => {
        if (!value) {
          return false;
        }
        return row.cageStatus?.indexOf('ACTIVE') >= 0 || row.cageStatus?.indexOf('SPECIALIZED');
      },
      renderCell: (params) => (
        <Checkbox
          checked={
            params.row.cageStatus?.indexOf('ACTIVE') >= 0 ||
            params.row.cageStatus?.indexOf('SPECIALIZED') >= 0
          }
          disabled
        />
      ),
    },
    {
      field: 'atRisk',
      headerName: 'At Risk?',
      type: 'boolean',
      width: 70,
      flex: 0,
      editable: true,
      valueGetter: (value, row) => {
        return row.isAtRisk;
      },
      valueSetter: (value, row) => {
        return { ...row, isAtRisk: value };
      },
      renderCell: (params) => <Checkbox checked={params.row.isAtRisk} disabled />,
    },
    {
      field: 'stageOfLife',
      headerName: 'Life Stage',
      width: 90,
      flex: 0,
      filterable: true,
      editable: true,
      type: 'singleSelect',
      valueOptions: lifeStages,
      renderCell: (params) => <LifeStageIndicator stage={params.row.stageOfLife} />,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const getDetailPanelContent = React.useCallback(
    ({ row }: { row: any }) => (
      <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
        <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
          <ExpandedTableContent watchedPartId={row.id} density={density} />
        </Paper>
      </Stack>
    ),
    [density]
  );

  const getDetailPanelHeight = React.useCallback(() => 400, []);

  /**
   * Send the updated row data to the server
   * @param newRow
   * @param oldRow
   * @returns
   */
  async function processRowUpdate(newRow: GridRowModel, oldRow: GridRowModel) {
    try {
      const response = await DmsmsApi.updateWatchedPart(getDtoFromGridRowModel(newRow));

      if (response.isSuccess) {
        setSnackBar('Row updated', 'success');
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return newRow;
      } else {
        console.error(response.message);
        setSnackBar(response.message);
      }
    } catch (error) {
      console.error(error);
      setSnackBar('An error occurred.');
    }
    return oldRow;
  }

  /**
   * Triggers the snackbar
   * @param message
   * @param severity
   */
  function setSnackBar(message: string, severity: string = 'error') {
    setSnackBarState((oldState) => ({
      ...oldState,
      open: true,
      message: message,
      severity: severity,
    }));
  }
  const handleDensityChange = (newDensity: GridDensity) => {
    setDensity(newDensity);
  };

  /**
   * Transforms a GridRowModel into a WatchedPartDto
   * @param row
   * @returns
   */
  function getDtoFromGridRowModel(row: GridRowModel): WatchedPartDto {
    return {
      id: row.id,
      partNumber: row.partNumber,
      nsn: row.nsn,
      description: row.description,
      upperLevelAssembly: row.upperLevelAssembly,
      supplierId: row.supplierId,
      cageStatus: row.cageStatus,
      isAtRisk: row.isAtRisk,
      stageOfLife: row.stageOfLife,
    } as WatchedPartDto;
  }

  return (
    <div className="table-parts-container">
      <Box className="filters-container">
        <Button variant="contained" size="small" className="filters-button" onClick={resetFilters}>
          RESET FILTERS
        </Button>

        <Box className="filters-row">
          <Box className="filter-item">
            <Typography variant="body2">Parts with Actions</Typography>
            <Switch
              size="small"
              checked={partsWithActions}
              onChange={(e) => hendleSwtichFilter('actions', e.target.checked)}
            />
          </Box>
          <Box className="filter-item">
            <Typography variant="body2">At-risk parts only</Typography>
            <Switch
              size="small"
              checked={atRiskPartsOnly}
              onChange={(e) => hendleSwtichFilter('atRisk', e.target.checked)}
            />
          </Box>
        </Box>

        <Box className="action-row">
          <Typography variant="body2" className="action-label">
            With selected items
          </Typography>
          <Box className="select-and-button">
            <FormControl size="small">
              <Select
                value={selectedAction}
                onChange={(e) => setSelectedAction(e.target.value as number)}
                displayEmpty
                renderValue={(selected) => {
                  const selectedAction = actions.find((action) => action.id === selected);
                  return selectedAction ? selectedAction.entryText : 'Select an action';
                }}
                sx={{
                  width: '300px',
                }}
              >
                <MenuItem value="0" disabled>
                  Select an action
                </MenuItem>
                {actions?.map((action, index) => (
                  <MenuItem key={index} value={action.id}>
                    {action.entryText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="go-button"
              onClick={handleActionSelect}
            >
              GO
            </Button>
          </Box>
        </Box>
      </Box>

      <div className="table-container">
        <DataGridPro
          apiRef={apiRef}
          className="table-container-data-grid"
          loading={isLoading}
          editMode="row"
          onCellClick={handleCellClick}
          onDensityChange={(newDensity) => handleDensityChange(newDensity)}
          onFilterModelChange={handleFilterChange}
          onRowEditStop={handleRowEditStop}
          onRowModesModelChange={handleRowModesModelChange}
          onRowSelectionModelChange={setSelection}
          processRowUpdate={processRowUpdate}
          rowModesModel={rowModesModel}
          rows={rows}
          columns={columns}
          pagination
          filterModel={filterModel}
          checkboxSelection
          pageSizeOptions={[10, 25, 50]}
          slots={{
            toolbar: () => (
              <>
                <GridToolbar />
              </>
            ),
          }}
          getDetailPanelContent={({ row }) => (
            <ExpandedTableContent watchedPartId={row.id} density={density} />
          )}
          getDetailPanelHeight={() => 550}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
        />
        {pageFirstLoad && <LinearProgress sx={{ width: '100%' }} />}
      </div>
      <ValidationSnackbar
        open={snackBarState.open}
        message={snackBarState.message}
        severity={snackBarState.severity}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default TableParts;
