import React from 'react';
import { Typography } from '@mui/material';
import './index.scss';
import SupplierSettings from '../../components/scrm/admin/supplier-settings/SupplierSettings';

const SupplierSettingsAdmin = () => {
  return (
    <div className="admin">
      <Typography className="title">Supplier Settings</Typography>
      <SupplierSettings />
    </div>
  );
};

export default SupplierSettingsAdmin;
