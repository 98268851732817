import React from 'react';
import UsersTable from '../../components/Site-Admin/Users/UsersTable';
import './Users.scss';
import { Typography } from '@mui/material';

const Users = () => {
  return (
    <div className="site-admin-container">
      <Typography className="title">Users</Typography>
      <UsersTable />
    </div>
  );
};

export default Users;
