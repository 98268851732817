import DashboardApi from '../../../api/dashboard/dashboardApi';

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DashboardDmsmsViewModelDto } from '../../../api/types/types';
import { LineChart } from '@mui/x-charts';
import DmsmsLifeStage from './dmsms/DmsmsLifeStage';
import DmsmsContainer from './dmsms/DmsmsContainer';

interface DmsmsAtRiskPartChartData {
  data: number[];
  periods: Date[];
}
const colors = [
  // Colors in the pie chart get assigned from first to last.

  // "#001C2C", // Primary 800
  // "#003A5B", // Primary 700
  '#015688', // Primary 600
  '#0773B5', // Primary 500
  '#4596C7', // Primary 400
  '#83B9DB', // Primary 300
  // "#C1DCED", // Primary 200
];

const Dmsms: React.FC = () => {
  const [atRiskPartHistory, setAtRiskPartHistory] = useState<DmsmsAtRiskPartChartData>({
    data: [],
    periods: [],
  });
  const [atRiskPartLoading, setAtRiskPartLoading] = useState<boolean>(false);

  const formatAtRiskChartData = (value: any[] | null | undefined) => {
    return value ? value.slice().reverse() : [];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAtRiskPartLoading(true);
        const response: DashboardDmsmsViewModelDto = await DashboardApi.getDmsmsData();

        setAtRiskPartHistory({
          data: formatAtRiskChartData(response.atRiskPartCountHistory),
          periods: formatAtRiskChartData(response.atRiskPartCountPeriods),
        });
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setAtRiskPartLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" pr={1}>
          DMSMS
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display={atRiskPartLoading ? 'flex' : 'block'}
          justifyContent={atRiskPartLoading ? 'center' : 'space-between'}
        >
          {atRiskPartLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h6" pr={1}>
                Count of At Risk Parts
              </Typography>
              <LineChart
                colors={colors}
                series={[
                  {
                    curve: 'linear',
                    data: atRiskPartHistory.data,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: atRiskPartHistory.periods,
                  },
                ]}
                height={400}
              />
              <DmsmsContainer />
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Dmsms;
