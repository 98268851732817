import React, { useEffect, useState } from 'react';
import { Button, Popup } from 'devextreme-react';
import ContextMenu from 'devextreme-react/context-menu';
import './PopupArticle.scss';
import formatDate from '../../../utils/format-date';
import { useParams } from 'react-router';
import ArticlesAPI from '../../../api/articles/articlesAPI';
import { setAdditionalsKeywords } from '../../../slices/hitsSlice';
import { useDispatch, useSelector } from 'react-redux';

interface Article {
  id: number;
  title: string;
  pubDate?: string;
  sourceDomain: string;
  url: string;
  sentimentMetrics: string;
  semanticSummary: string;
  content: string;
  imageUrl: string;
  suppliers: string;
  positiveSentiment: number;
  negativeSentiment: number;
  supplierArticles?: SupplierArticle[];
  articleKeywords?: ArticleKeyword[];
}

interface SupplierArticle {
  id: string;
  name: string;
}

interface ArticleKeyword {
  id: string;
  name: string;
}

interface PopupArticleProps {
  onClose: () => void;
  articles: Article[];
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const PopupArticle: React.FC<PopupArticleProps> = ({
  onClose,
  articles,
  currentIndex,
  setCurrentIndex,
}) => {
  const [selectedText, setSelectedText] = useState<string | null>(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  const article = articles[currentIndex];
  const date = formatDate(article.pubDate);
  const additionalsKeywords = useSelector(
    (state: any) => state.hits.additionalKeywords[article.id] || []
  );

  const handleNext = () => {
    if (currentIndex < articles.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const fetchAdditionalKeyword = async () => {
    try {
      const data = await ArticlesAPI.getAdditionalKeywordsByArticleId(article.id);
      dispatch(setAdditionalsKeywords({ articleId: article.id, keywords: data }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAdditionalKeyword();
  }, []);

  const highlightKeywords = (
    text: string,
    additionalsKeywords: ArticleKeyword[] = [],
    articleKeywords: ArticleKeyword[] = []
  ) => {
    if (!text) return text;

    let highlightedText = text;
    const allKeywords = [...additionalsKeywords, ...articleKeywords];

    if (Array.isArray(allKeywords)) {
      allKeywords.forEach((keyword) => {
        const regex = new RegExp(`\\b(${keyword.name})\\b`, 'gi');
        highlightedText = highlightedText.replace(regex, '<mark>$1</mark>');
      });
    }
    return highlightedText;
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    const text = window.getSelection()?.toString();
    if (text) {
      setSelectedText(text);
    }
  };

  const handleAddKeyword = async () => {
    if (selectedText && additionalsKeywords && id !== undefined) {
      // const selectedKeywrod = selectedText.replace(/\s/g, '');
      const selectedKeywrod = selectedText.trim();
      dispatch(
        setAdditionalsKeywords({
          articleId: article.id,
          keywords: [selectedKeywrod],
        })
      );
      try {
        await ArticlesAPI.addKeywordToModel(Number(id), selectedKeywrod);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const menuItems = [
    {
      text: selectedText ? `Add "${selectedText.trim()}" as keyword` : 'Add Keyword',
      disabled: !selectedText,
      onClick: handleAddKeyword,
    },
  ];

  return (
    <Popup
      visible={true}
      onHiding={onClose}
      showCloseButton={true}
      width="auto"
      height="70%"
      position="center"
    >
      <div className="popup-article">
        <div className="popup-header">
          <h2>{article.title}</h2>
          <div className="toolbar-buttons">
            <Button icon="chevronprev" onClick={handlePrev} />
            <Button icon="chevronnext" onClick={handleNext} />
          </div>
        </div>
        <div className="popup-content">
          <div className="content-left">
            <div className="general-info">
              <div className="info-text">
                <p>
                  <strong>General Information</strong>
                </p>
                <p>Date Published: {date}</p>
                <p>Source: {article.sourceDomain}</p>
                <p className="info-text-url">
                  URL: <a href={article?.url}>{article?.url}</a>
                </p>
              </div>
              {article?.imageUrl && (
                <img className="article-image" src={article.imageUrl} alt="Article" />
              )}
            </div>
          </div>
          <div className="summary" onContextMenu={handleContextMenu}>
            <p>
              <strong>Summary</strong>
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: highlightKeywords(
                  article?.semanticSummary || '',
                  additionalsKeywords,
                  article.articleKeywords
                ),
              }}
            />
            <a href={article?.url}>Click here to view the full article</a>
          </div>
          <div className="article-content" onContextMenu={handleContextMenu}>
            <p>
              <strong>Article</strong>
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: highlightKeywords(
                  article?.content || '',
                  additionalsKeywords,
                  article.articleKeywords
                ),
              }}
            />
          </div>
          <div className="supplier-tags">
            {article?.supplierArticles?.map((supplier) => (
              <span key={supplier.id} className="tag">
                {supplier.name}
              </span>
            ))}
          </div>
          <div className="kwm-keywords">
            {[...(additionalsKeywords || []), ...(article.articleKeywords || [])].map(
              (keyword: any) => (
                <span key={keyword.id} className="tag">
                  {keyword.name}
                </span>
              )
            )}
          </div>
        </div>
        <ContextMenu
          target=".summary, .article-content"
          items={menuItems}
          showEvent="dxcontextmenu"
        />
      </div>
    </Popup>
  );
};

export default PopupArticle;
