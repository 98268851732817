import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  FormGroup,
  Typography,
  SnackbarCloseReason,
  Button,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import JobApi from '../../../api/job/JobApi';
import { createPortal } from 'react-dom';
import { AltruityCheckbox, ValidationSnackbar } from '../../../components/form';

interface DmsmsGidepProps {
  open: boolean;
  onClose: any;
}

const inputLabelClass = 'css-oad82q-MuiFormLabel-root-MuiInputLabel-root';

export default function DmsmsGidepModal({ open, onClose }: DmsmsGidepProps) {
  const [isSending, setIsSending] = useState(false);

  const [formData, setFormData] = useState({
    scanTagged: false,
    scanNonTagged: false,
  });

  // Validation snackbar state
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
    severity: 'error',
  });

  /**
   * Closes the validation SnackBar
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
      message: '',
    }));
  };

  /**
   *
   * @returns Submits the scan requests to the server.
   */
  async function onSubmit() {
    if (formData.scanNonTagged === false && formData.scanNonTagged === false) {
      setSnackBarState((prevState) => ({
        ...prevState,
        open: true,
        message: 'Please select an option',
        severity: 'error',
      }));

      return;
    }

    setIsSending(true);

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
    }));

    var nonTaggedScanSucceeded = false;
    var nonTaggedScanMessage = '';

    if (formData.scanNonTagged) {
      const { wasSuccessful, message } = await runScan(false);
      nonTaggedScanSucceeded = wasSuccessful;
      nonTaggedScanMessage = message;
    }

    var taggedScanSucceeded = false;
    var taggedScanMessage = '';

    if (formData.scanTagged) {
      const { wasSuccessful, message } = await runScan(true);
      taggedScanSucceeded = wasSuccessful;
      taggedScanMessage = message;
    }

    if (!taggedScanSucceeded || !nonTaggedScanSucceeded) {
      var alertMessage = '';

      if (!taggedScanSucceeded) alertMessage += taggedScanMessage;

      if (!nonTaggedScanSucceeded) alertMessage += ' ' + nonTaggedScanMessage;

      setSnackBarState((prevState) => ({
        ...prevState,
        open: true,
        message: alertMessage,
        severity: 'error',
      }));
    } else {
      setSnackBarState((prevState) => ({
        ...prevState,
        open: true,
        message: 'Scan(s) Initiated',
        severity: 'success',
      }));

      onClose();
    }

    setIsSending(false);
  }

  /**
   *
   * @param tagged Submits a scan request to the server.
   * @returns
   */
  async function runScan(tagged: boolean): Promise<{ wasSuccessful: boolean; message: string }> {
    try {
      const response = await JobApi.runGidepScanNow(tagged);

      if (response.isSuccess) {
        return { wasSuccessful: true, message: response.message };
      } else {
        console.error(response.message);
        return { wasSuccessful: false, message: response.message };
      }
    } catch (e) {
      console.error(e);
      return { wasSuccessful: false, message: 'Error sending request' };
    }
  }

  /**
   *
   * @param e Updates the form data.
   */
  function handleFormFieldChange(e: any) {
    var { name, value } = e.target;

    // Use checkbox 'checked' prop to determine value.
    if (e.target.type === 'checkbox') {
      value = e.target.checked ? true : false;
      // convert text area to string array
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  }

  return createPortal(
    <>
      <ValidationSnackbar
        key="gidep-snackbar"
        open={snackBarState.open}
        message={snackBarState.message}
        severity={snackBarState.severity}
        onClose={handleSnackBarClose}
      />
      <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
          }}
          id="dialog-title"
          style={{
            backgroundColor: '#306EB6',
            fontSize: '20px',
            fontWeight: 'bold',
            color: 'white',
            paddingRight: '60px',
          }}
        >
          Run GIDEP Scan
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box display="flex" className="typography-container">
            <Typography className={inputLabelClass}>Select SCAN TYPE</Typography>
          </Box>
          <FormGroup>
            <AltruityCheckbox
              label="Scan for non-tagged parts"
              name="scanNonTagged"
              defaultChecked={formData.scanNonTagged}
              onChange={(e: any) => handleFormFieldChange(e)}
            />
            <AltruityCheckbox
              label="Scan for tagged parts"
              name="scanTagged"
              defaultChecked={formData.scanTagged}
              onChange={(e: any) => handleFormFieldChange(e)}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={isSending}
            endIcon={isSending ? <CircularProgress size={16} /> : null}
          >
            OK
          </Button>
          <Button onClick={onClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>,
    document.getElementById('app-modal')!
  );
}
