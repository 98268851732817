import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeywordModelDto } from '../api/types/types';

interface KeywordModel {
  id: string | number;
  modelName: string;
}

interface KeywordModelsMenu {
  id: string | number;
  description: string;
}

interface KeywordModelState {
  allKeywordModel: KeywordModel[];
  selectedKeywordModel: KeywordModel | null;
  keywordsModelsMenu: KeywordModelsMenu[];
  loading: boolean;
}

const initialState: KeywordModelState = {
  allKeywordModel: [],
  selectedKeywordModel: null,
  keywordsModelsMenu: [],
  loading: false,
};

const keywordModelSlice = createSlice({
  name: 'keywordModel',
  initialState,
  reducers: {
    addKeywordModel: (state, action: PayloadAction<{ allKeywordModel: KeywordModel[] }>) => {
      return { ...state, allKeywordModel: [...action.payload.allKeywordModel] };
    },
    addSelectedKeywordModel: (
      state,
      action: PayloadAction<{ selectedKeywordModel: KeywordModel | null }>
    ) => {
      return { ...state, selectedKeywordModel: action.payload.selectedKeywordModel };
    },
    addKeywordModelsMenu: (
      state,
      action: PayloadAction<{ keywordsModelsMenu: KeywordModelsMenu[] }>
    ) => {
      return { ...state, keywordsModelsMenu: [...action.payload.keywordsModelsMenu] };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const { addKeywordModel, addSelectedKeywordModel, addKeywordModelsMenu, setLoading } =
  keywordModelSlice.actions;
export default keywordModelSlice.reducer;
