// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, DmsmsAdminSettingsDto } from '../types/types';
const { JOB_SERVICE_URL } = config;
const Job = {
  /**
   * Gets information for a particular cage code.
   * @param cage
   * @returns
   */
  saveDmsmsSettings: async function (
    queryData: DmsmsAdminSettingsDto
  ): Promise<ResponseDto<DmsmsAdminSettingsDto>> {
    var url = `${JOB_SERVICE_URL}/Job/Dmsms/Settings`;
    const { data } = await httpClient.put(url, queryData);
    return data as ResponseDto<DmsmsAdminSettingsDto>;
  },

  /**
   * Sends message to execute the PowerLog Pull immediately
   * @returns
   */
  runPowerLogScanNow: async function (runGidepAfter: boolean): Promise<ResponseDto<any>> {
    var url = `${JOB_SERVICE_URL}/Job/Dmsms/PowerLogRunNow?runGidepAfter=${runGidepAfter}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<any>;
  },

  /**
   * Sends message to execute the Gidep scan immediately
   * @returns
   */
  runGidepScanNow: async function (tagged: boolean): Promise<ResponseDto<any>> {
    var url = `${JOB_SERVICE_URL}/Job/Dmsms/GidepRunNow?tagged=${tagged}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<any>;
  },
};

export default Job;
