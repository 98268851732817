import React, { useEffect } from 'react';
import PrimaryColor from './PrimaryColor';
import OtherColors from './OtherColors';
import { useDispatch } from 'react-redux';
import { setColors } from '../../../slices/themeSlice';
import AdminSettingsApi from '../../../api/adminSettings/adminSettingsApi';

const Colors = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    AdminSettingsApi.getAllColors().then((response) => {
      dispatch(setColors(response));
    });
  }, []);

  return (
    <>
      <PrimaryColor />
      <OtherColors />
    </>
  );
};

export default Colors;
