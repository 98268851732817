// @ts-ignore: Implicit any module
import SuppliersAPI from '../../../../api/suppliers/suppliersAPI';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import SelectBox from '../../../select-box/SelectBox';
import { IDataGridColumn } from '../../../data-grid/DataGridSupplierSetup';
import { createColumn, createPopupField } from '../../../../utils/data-grid';
import DataGrid from '../../../data-grid/DataGrid';
import './index.scss';

interface IRelatedSuppliersTableProps {
  entityName: string;
  relationTypeId: number;
}

const RelatedSuppliersTable = ({ entityName, relationTypeId }: IRelatedSuppliersTableProps) => {
  const { id: supplierId } = useParams();
  const [tableData, setTableData] = useState([]);
  const [selectBoxData, setSelectBoxData] = useState<any>();

  const columns: IDataGridColumn[] = [
    createColumn('companyName', 'Company Name', undefined, true),
    createColumn('link', 'Link', undefined, undefined, true),
  ];

  const popupFields = [
    createPopupField('companyName', 'Name'),
    createPopupField('supplierRelationType', 'Relationship'),
  ];

  const loadSelectBoxData = async () => {
    setSelectBoxData(await SuppliersAPI.getAllRelatedSuppliers(supplierId, relationTypeId));
  };

  const getRelatedSuppliers = () => {
    return SuppliersAPI.getAllRelatedSuppliersByRelationTypeId(supplierId, relationTypeId);
  };

  const addSupplier = async (selectedValue: any) => {
    await SuppliersAPI.addSupplierRelation(
      selectedValue.supplierName ?? selectedValue.companyName,
      selectedValue.internalId,
      supplierId,
      relationTypeId
    );
  };

  const createSupplierFromTable = async (data: any) => {
    const createSupplierResult = await SuppliersAPI.createSupplier({
      supplierName: data.companyName,
      supplierRelationTypeId: relationTypeId,
    });

    await updateData(data.id, data, createSupplierResult.result.id);
  };

  const loadData = async () => setTableData(await getRelatedSuppliers());

  const createData = async (data: any) => await addSupplier(data);

  const deleteData = async (id: number) => {
    await SuppliersAPI.deleteSupplierRelation(id);
    loadSelectBoxData();
  };

  const updateData = async (id: number, data: any, createdSupplierId?: number) =>
    await SuppliersAPI.updateSupplierRelation(
      id,
      data.companyName,
      createdSupplierId,
      data.supplierId,
      relationTypeId
    );

  return (
    <>
      <SelectBox
        dataSource={selectBoxData}
        className="paddingBottom"
        label={`Add an existing ${entityName}`}
        placeholder="Add an existing supplier"
        displayExpr="supplierName"
        loadOptions={loadSelectBoxData}
        handleAction={async (selectedValue) => {
          await addSupplier({ ...selectedValue, internalId: selectedValue.id });
          loadData();
          loadSelectBoxData();
        }}
      ></SelectBox>

      <DataGrid
        data={tableData}
        columns={columns}
        getLink={(id) => `/scrm/suppliers/${id}`}
        linkKeyExpr="internalId"
        loadData={loadData}
        createData={createData}
        deleteData={deleteData}
        updateData={updateData}
        popupTitle="Create New Supplier Profile"
        popupFields={popupFields}
        popupCreateLabel="Create supplier"
        handleCreate={createSupplierFromTable}
        loading={false}
      />
    </>
  );
};

export default RelatedSuppliersTable;
