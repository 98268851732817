import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createColumn } from '../../../utils/data-grid';
import { NsnDataGridHelpMenu } from './NsnDataGridHelpMenu';
import './NsnDetails.scss';

export default function NsnDetailCharacteristicsDataGrid({ data }) {
  // Create column definitions	// Create column definitions
  const columns = [
    {
      ...createColumn('MRC', 'MRC'),
      width: 100,
      flex: 0,
    },
    {
      ...createColumn('REQUIREMENTS_STATEMENT', 'REQUIREMENTS STATEMENT'),
      minWidth: 200,
      flex: 1,
    },
    {
      ...createColumn('CLEAR_TEXT_REPLY', 'CLEAR TEXT REPLY'),
      minWidth: 200,
      flex: 1,
    },
  ];

  return (
    <Box className="nsn-detail-grid">
      <h5>Characteristics</h5>
      <DataGrid
        autoHeight
        getRowId={() => 10000 * Math.random()}
        density="compact"
        rows={data}
        columns={columns}
        slots={{ columnMenu: NsnDataGridHelpMenu }}
      />
    </Box>
  );
}
