import React, { useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  Paper,
  Divider,
  AlertColor,
  CircularProgress,
} from '@mui/material';
import CustomSnackbar from '../../../components/custom-snackbar';
import JobApi from '../../../api/job/JobApi';
import { DmsmsAdminSettingsDto } from '../../../api/types/types';
import { AltruityToggleButtons, AltruityTagBox } from '../../../components/form';
// import "./PowerLogJ2Settings.scss";
import DmsmsPowerLogModal from '../../../components/modal/dmsms/DmsmsPowerLogModal';

interface PowerLogJ2SettingsProps {
  formData: DmsmsAdminSettingsDto;
  onFieldChange: (field: string, e: any) => void;
  onChipDelete: (field: string, value: any) => void;
}

const includeExcludeButtons = [
  { name: 'Include', value: true },
  { name: 'Exclude', value: false },
];

const rowSpacing = 2;

export default function PowerLogJ2Settings({
  formData,
  onFieldChange,
  onChipDelete,
}: PowerLogJ2SettingsProps) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');
  const [sendingSate, setSendingState] = useState({
    isSaving: false,
    isRunningPlj2Load: false,
  });
  const [powerLogOpen, setPowerLogOpen] = useState(false);

  const handleSave = async () => {
    setSendingState((prevState) => ({ ...prevState, isSaving: true }));
    try {
      const response = await JobApi.saveDmsmsSettings(formData);
      if (response.isSuccess) {
        setSnackbarOpen(true);
        setSnackbarMessage('Settings saved successfully');
        setSnackbarSeverity('success');
      } else {
        console.error(response.message);
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      setSnackbarOpen(true);
      setSnackbarMessage('Error saving settings');
      setSnackbarSeverity('error');
    }
    setSendingState((prevState) => ({ ...prevState, isSaving: false }));
  };

  function handleRunPlj2() {
    setPowerLogOpen(true);
  }

  function handlePowerLogModelClose() {
    setPowerLogOpen(false);
  }
  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '15px' }}>
      <Typography variant="h6" gutterBottom style={{ marginBottom: '25px' }}>
        PowerLog J2 Settings
      </Typography>

      <Grid container rowSpacing={rowSpacing}>
        {/* SMR Codes Section */}
        <Grid item container>
          {/* Toggle Button for Include/Exclude */}
          <Grid item xs={2}>
            <AltruityToggleButtons
              name="smrOption"
              label="SMR Codes"
              buttons={includeExcludeButtons}
              onChange={onFieldChange}
              value={formData.smrOption}
            />
          </Grid>

          {/* Autocomplete with Chips for SMR Codes */}
          <Grid item xs={10} sx={{ position: 'relative' }}>
            <AltruityTagBox
              autoComplete
              name="smrValues"
              label="SMR Codes"
              values={formData.smrValues}
              onChange={onFieldChange}
              onDeleteOption={onChipDelete}
              formControlSx={{
                position: 'absolute',
                bottom: '-10px',
                width: '100%',
              }}
            />
          </Grid>
        </Grid>

        <Divider style={{ margin: '20px 0' }} />

        {/* Essentiality Codes Section */}
        <Grid item xs={2}>
          <AltruityToggleButtons
            name="essentialityOption"
            label="Essentiality Codes"
            buttons={includeExcludeButtons}
            onChange={onFieldChange}
            value={formData.essentialityOption}
          />
        </Grid>
        <Grid item xs={10} sx={{ position: 'relative' }}>
          <AltruityTagBox
            autoComplete
            name="essentialityValues"
            label="Essentiality Codes"
            values={formData.essentialityValues}
            onChange={onFieldChange}
            onDeleteOption={onChipDelete}
            formControlSx={{
              position: 'absolute',
              bottom: '-10px',
              width: '100%',
            }}
          />
        </Grid>

        <Divider style={{ margin: '20px 0' }} />

        {/* FSCN Section */}
        <Grid item container>
          <Grid item xs={2}>
            <AltruityToggleButtons
              name="fscnOption"
              label="FSCN"
              buttons={includeExcludeButtons}
              onChange={onFieldChange}
              value={formData.fscnOption}
            />
          </Grid>
          <Grid item xs={10} sx={{ position: 'relative' }}>
            <AltruityTagBox
              autoComplete
              name="fscnValues"
              label="FSCN"
              values={formData.fscnValues}
              onChange={onFieldChange}
              onDeleteOption={onChipDelete}
              formControlSx={{
                position: 'absolute',
                bottom: '-10px',
                width: '100%',
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '20px 0' }} />

        {/* LRU Section */}
        <Grid item container>
          <Grid item xs={2}>
            <AltruityToggleButtons
              name="lruOption"
              label="LRU"
              buttons={includeExcludeButtons}
              onChange={onFieldChange}
              value={formData.lruOption}
            />
          </Grid>
        </Grid>

        {/* Buttons Section */}
        <Grid container spacing={2} style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
          <Grid item>
            <Button
              variant="contained"
              disabled={sendingSate.isRunningPlj2Load}
              onClick={handleRunPlj2}
              endIcon={sendingSate.isRunningPlj2Load ? <CircularProgress size={16} /> : null}
            >
              Run Incremental PLJ2 Load
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={sendingSate.isSaving}
              endIcon={sendingSate.isSaving ? <CircularProgress size={16} /> : null}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DmsmsPowerLogModal open={powerLogOpen} onClose={handlePowerLogModelClose} />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </Paper>
  );
}
