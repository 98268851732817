import httpClient from '../configs/axiosConfig';
import config from '../../config';

const { SUPPLIERS_SERVICE_URL, ALTRUITY_API_URL } = config;

const Supplier = {
  /**
   * Creates a new supplier.
   * @param {SupplierCreateDto} createSupplierBody The supplier data to create.
   */
  createSupplier: async function (createSupplierBody) {
    const { data } = await httpClient.post(`${SUPPLIERS_SERVICE_URL}/Supplier`, createSupplierBody);
    return data;
  },

  updateSupplier: async function (updateSupplierBody) {
    console.log(updateSupplierBody);
    const { data } = await httpClient.put(`${SUPPLIERS_SERVICE_URL}/Supplier`, updateSupplierBody);
    return data;
  },

  getAllSuppliers: async function (showOnlyActive = false) {
    const { data } = await httpClient.get(
      `${SUPPLIERS_SERVICE_URL}/Supplier/GetAll?showOnlyActive=${showOnlyActive}`
    );
    const response = data.result.sort((a, b) => a.supplierName.localeCompare(b.supplierName));
    return response;
  },

  getSupplier: async function (supplierId) {
    const { data } = await httpClient.get(`${SUPPLIERS_SERVICE_URL}/Supplier/${supplierId}`);
    return data.result;
  },

  getSupplierByCage: async function (cage) {
    const { data } = await httpClient.get(`${SUPPLIERS_SERVICE_URL}/Supplier/ForCage/${cage}`);
    return data.result;
  },

  getAllRelatedSuppliers: async function (supplierId, realtionTypeId) {
    const { data } = await httpClient.get(
      `${SUPPLIERS_SERVICE_URL}/Supplier/GetAllSuppliersToRelate/${supplierId}?supplierRelationTypeId=${realtionTypeId}`
    );
    return data.result;
  },

  getSuppliersById: async function (id) {
    const { data } = await httpClient.get(`${SUPPLIERS_SERVICE_URL}/Supplier/${id}`);
    return data.result;
  },

  getAllSuppliersForSelectBox: async function () {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/Supplier/GetAllSuppliersForSelectBox`
    );
    return data.result;
  },

  getAllThemes: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Supplier/ColorThemes`);
    return data.result;
  },

  getVendors: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Supplier/GetVendors`);
    return data.result;
  },

  createSuppliersFromVendors: async function (vendorIds) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/Supplier/CreateSuppliersFromVendors`,
      vendorIds
    );
    return data;
  },
};

export default Supplier;
