import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  FormGroup,
  SnackbarCloseReason,
  Button,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import JobApi from '../../../api/job/JobApi';
import { createPortal } from 'react-dom';
import { AltruityCheckbox, ValidationSnackbar } from '../../../components/form';

interface DmsmsGidepProps {
  open: boolean;
  onClose: any;
}

export default function DmsmsPowerLogModal({ open, onClose }: DmsmsGidepProps) {
  const [isSending, setIsSending] = useState(false);

  const [formData, setFormData] = useState({
    runGidepAfter: false,
  });

  // Validation snackbar state
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
    severity: 'error',
  });

  /**
   * Closes the validation SnackBar
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
      message: '',
    }));
  };

  /**
   *
   * @returns Submits the scan requests to the server.
   */
  async function onSubmit() {
    setIsSending(true);

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
    }));

    try {
      const response = await JobApi.runPowerLogScanNow(formData.runGidepAfter);

      if (response.isSuccess) {
        setSnackBarState((prevState) => ({
          ...prevState,
          open: true,
          message: 'Scan(s) Initiated',
          severity: 'success',
        }));
        onClose();
      } else {
        console.error(response.message);
        setSnackBarState((prevState) => ({
          ...prevState,
          open: true,
          message: response.message,
          severity: 'error',
        }));
      }
    } catch (e) {
      console.error(e);
      setSnackBarState((prevState) => ({
        ...prevState,
        open: true,
        message: 'Error sending request',
        severity: 'error',
      }));
    }

    setIsSending(false);
  }

  /**
   *
   * @param e Updates the form data.
   */
  function handleFormFieldChange(e: any) {
    var { name, value } = e.target;

    // Use checkbox 'checked' prop to determine value.
    if (e.target.type === 'checkbox') {
      value = e.target.checked ? true : false;
      // convert text area to string array
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  }

  return createPortal(
    <>
      <ValidationSnackbar
        key="gidep-snackbar"
        open={snackBarState.open}
        message={snackBarState.message}
        severity={snackBarState.severity}
        onClose={handleSnackBarClose}
      />
      <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
          }}
          id="dialog-title"
          style={{
            backgroundColor: '#306EB6',
            fontSize: '20px',
            fontWeight: 'bold',
            color: 'white',
            paddingRight: '60px',
          }}
        >
          Run PowerLog Scan
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <FormGroup>
            <AltruityCheckbox
              label="Run GIDEP Scan on Added Parts?"
              name="runGidepAfter"
              defaultChecked={formData.runGidepAfter}
              onChange={(e: any) => handleFormFieldChange(e)}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={isSending}
            endIcon={isSending ? <CircularProgress size={16} /> : null}
          >
            OK
          </Button>
          <Button onClick={onClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>,
    document.getElementById('app-modal')!
  );
}
