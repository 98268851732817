import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  SnackbarCloseReason,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  addKeywordModel,
  addKeywordModelsMenu,
  addSelectedKeywordModel,
} from '../../../slices/keywordModelSlice';
import KeywordModelAPI from '../../../api/keywordModel/keywordModelApi';
import './RiskModelsForm.scss';
import {
  CreateKeywordModelRequestDto,
  KeywordModelDto,
  SearchFrequencyDto,
  SelectBoxOptionDto,
  SentimentRuleDto,
  StringKeySelectBoxOptionDto,
  UpdateKeywordModelRequestDto,
} from '../../../api/types/types';
import DataGrid from '../../../components/data-grid/DataGrid';
import { createColumn } from '../../../utils/data-grid';
import { SnackbarProps } from '../../../types/Snackbar';
import AddIcon from '@mui/icons-material/Add';
import ModelExecutionConfirmationDialog from './ModelExecutionConfirmationDialog';

interface Keyword {
  id: number | null;
  text: string;
}

const defaultFormData: UpdateKeywordModelRequestDto = {
  modelName: '',
  owner: {
    id: 0,
    label: '',
  },
  isActive: true,
  searchFrequencyId: 0,
  subscribers: [],
  sentimentRuleId: 0,
  keywords: '',
  searchRules: [],
};

const KeywordModelForm: React.FC = () => {
  const keywordsColumns = [createColumn('text', 'Keyword', undefined, true)];

  const [keywordModel, setKeywordModel] = useState<KeywordModelDto | null>();
  const [owners, setOwners] = useState<SelectBoxOptionDto[]>([]);
  const [subscribers, setSubscribers] = useState<StringKeySelectBoxOptionDto[]>([]);
  const [frequencyOfSearch, setFrequencyOfSearch] = useState<SearchFrequencyDto[]>([]);
  const [sentimentRule, setSentimentRule] = useState<SentimentRuleDto[]>([]);
  const [newKeywordPhrase, setNewKeywordPhrase] = useState<string>('');
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [synonyms, setSynonyms] = useState<string[]>([]);
  const [editingKeywordId, setEditingKeywordId] = useState<number | null>(null);
  const [formData, setFormData] = useState<UpdateKeywordModelRequestDto>({
    ...defaultFormData,
  });
  const [validateForm, setValidateForm] = useState<boolean>(false);

  const [searchRules, setSearchRules] = useState<any[][]>([[{ id: 0, value: 'Select...' }]]);
  const searchRulesOptions = [
    'Select...',
    'Name',
    'Location',
    'Subsidiary',
    'Competitor',
    'Industry',
    'Supplier',
  ];

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [loadingConfirmationDialog, setLoadingConfirmationDialog] = useState<boolean>(false);

  const [snackbar, setSnackbar] = useState<SnackbarProps>();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const newKeywordModel = location.pathname.includes('add-keyword-model');

  const fetchData = async () => {
    try {
      const [
        sentimentRuleData,
        frequencySearchData,
        ownersResponse,
        keywordModelResponse,
        subscribersResponse,
      ]: [
        any[],
        any[],
        SelectBoxOptionDto[],
        KeywordModelDto | null,
        StringKeySelectBoxOptionDto[],
      ] = await Promise.all([
        KeywordModelAPI.getAllSentimentRule(),
        KeywordModelAPI.getAllSearchFrequency(),
        KeywordModelAPI.getOwnersDropdown(),
        newKeywordModel ? Promise.resolve(null) : KeywordModelAPI.getKeywordById(Number(id)),
        KeywordModelAPI.getSubscribersDropdown(),
      ]);

      setSentimentRule(sentimentRuleData);
      setFrequencyOfSearch(frequencySearchData);
      setOwners(ownersResponse);
      setSubscribers(subscribersResponse);

      if (keywordModelResponse) {
        setKeywordModel(keywordModelResponse);

        setFormData((prevFormData: UpdateKeywordModelRequestDto) => ({
          ...prevFormData,
          modelName: keywordModelResponse?.modelName!,
          owner: ownersResponse.find(
            (o: SelectBoxOptionDto) => o.id === keywordModelResponse?.ownerId
          )!,
          isActive: keywordModelResponse?.isActive!,
          searchFrequencyId: keywordModelResponse?.searchFrequencyId!,
          sentimentRuleId: keywordModelResponse?.sentimentRuleId!,
          subscribers: keywordModelResponse.subscribers!.map((option) => {
            const foundSubscriber = subscribersResponse.find((s) => s.id === option.key);
            return { key: foundSubscriber!.id, name: foundSubscriber!.label }; // Ensure correct mapping to SubscriberViewModelDto
          }),
        }));

        setSearchRules(
          keywordModelResponse.searchRules?.length
            ? keywordModelResponse.searchRules?.map((item) =>
                item.rulePhrase.split('|').map((phrase) => ({
                  id: item.id,
                  value: phrase.trim(),
                }))
              )
            : [[{ id: 0, value: 'Select...' }]]
        );

        setKeywords(
          keywordModelResponse?.keywordArray?.map((text: string, index: number) => ({
            id: index + 1,
            text: text.trim(),
          })) ?? []
        );

        dispatch(
          addSelectedKeywordModel({
            selectedKeywordModel: {
              ...keywordModelResponse,
            },
          })
        );
      } else {
        setKeywordModel(null);
        setFormData({ ...defaultFormData });
        setKeywords([]);
        setSynonyms([]);
        dispatch(
          addSelectedKeywordModel({
            selectedKeywordModel: null,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleAddKeywordPhrase = () => {
    if (newKeywordPhrase.trim() !== '') {
      const newKeyword = {
        id: keywords.length + 1,
        text: newKeywordPhrase.trim(),
      };
      setKeywords([...keywords, newKeyword]);
      setNewKeywordPhrase('');
    }
  };

  const handleSaveKeywordEdit = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && editingKeywordId !== null) {
      setKeywords((prevKeywords) => {
        const newKeywords = prevKeywords.map((keyword) =>
          keyword.id === editingKeywordId ? { ...keyword, text: newKeywordPhrase } : keyword
        );
        return newKeywords;
      });
      setEditingKeywordId(null);
      setNewKeywordPhrase('');
    }
  };

  const handleSave = async () => {
    try {
      setValidateForm(true);

      const incompleteForm =
        !formData.modelName.length ||
        !formData.owner.id ||
        !formData.searchFrequencyId ||
        !formData.sentimentRuleId;

      if (incompleteForm) {
        setSnackbar({
          open: true,
          severity: 'warning',
          message: 'Please complete all required fields.',
        });
        return;
      }

      const keywordsValue = keywords.length ? keywords.map((kw) => kw.text).join('|') : null;

      let response;
      let body;
      if (newKeywordModel) {
        // CreateKeywordModelRequestDto
        const createBody: CreateKeywordModelRequestDto = {
          modelName: formData.modelName,
          keywords: keywordsValue,
          ownerId: formData.owner.id,
          searchFrequencyId: formData.searchFrequencyId,
          sentimentRuleId: formData.sentimentRuleId,
          subscriberKeys: formData.subscribers.map((s) => s.key),
          isActive: formData.isActive,
        };
        body = createBody;

        response = await KeywordModelAPI.createKeywordModel(createBody);
      } else {
        // UpdateKeywordModelRequestDto
        const updateBody: UpdateKeywordModelRequestDto = {
          modelName: formData.modelName,
          keywords: keywordsValue,
          owner: formData.owner, // owner is an object for update
          searchFrequencyId: formData.searchFrequencyId,
          sentimentRuleId: formData.sentimentRuleId,
          subscribers: formData.subscribers.map((s) => ({
            key: s.key,
            name: s.name,
          })), // full subscribers array for update
          searchRules: searchRules, // Ensure searchRules exists in the form data
          isActive: formData.isActive,
        };
        body = updateBody;

        response = await KeywordModelAPI.updateKeywordModel(updateBody, keywordModel!.id);
      }

      setSnackbar({
        open: true,
        severity: 'success',
        message: response.message.length ? response.message : 'Entity created.',
      });

      const data = await KeywordModelAPI.getAllKeywordModel();
      dispatch(addKeywordModel({ allKeywordModel: data }));

      dispatch(
        addSelectedKeywordModel({
          selectedKeywordModel: {
            ...body,
            id: response!.result.id,
          },
        })
      );

      const responseRiskModels = await KeywordModelAPI.getRiskModelsMenuItems();
      dispatch(
        addKeywordModelsMenu({
          keywordsModelsMenu: responseRiskModels.keywordModels,
        })
      );

      navigate(`/scrm/risk-models/${response.result.id}`);
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || 'An error occurred';

      setSnackbar({
        open: true,
        severity: 'error',
        message: errorMessage,
      });
    }
  };

  const inputStyles = {
    borderRadius: '10px',
    height: '46px',
    borderColor: 'none',
    fontSize: '14px',
  };

  const labelStyles = {
    textTransform: 'uppercase',
    position: 'relative',
  };

  const handleMultipleChange = useCallback(
    (
      field: keyof UpdateKeywordModelRequestDto,
      value: StringKeySelectBoxOptionDto[] | number[]
    ) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: value || [],
      }));
    },
    [setFormData]
  );

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  const getSynonyms = async (data: any) => {
    const result = await KeywordModelAPI.getSynonyms(data.text);
    setSynonyms(result.synonyms);
  };

  const addSynonymToKeywords = (synonym: string) => {
    const synonymExists = keywords.some(
      (keyword) => keyword.text.toLowerCase() === synonym.trim().toLowerCase()
    );

    if (synonymExists) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: `The synonym "${synonym.trim()}" already exists in the keywords.`,
      });
      return;
    }

    setKeywords((prevKeywords: Keyword[]) => [
      ...prevKeywords,
      { id: prevKeywords.length + 1, text: synonym.trim() },
    ]);

    setSynonyms((prevSynonyms) => prevSynonyms.filter((s) => s !== synonym));

    setSnackbar({
      open: true,
      severity: 'success',
      message: `The synonym "${synonym.trim()}" has been added successfully.`,
    });
  };

  const addAllSynonymsToKeywords = () => {
    let justNewKeywords = true;

    for (let synonym of synonyms) {
      const synonymExists = keywords.some(
        (keyword) => keyword.text.toLowerCase() === synonym.trim().toLowerCase()
      );

      if (synonymExists) {
        justNewKeywords = false;
        continue;
      }

      setKeywords((prevKeywords: Keyword[]) => [
        ...prevKeywords,
        { id: prevKeywords.length + 1, text: synonym.trim() },
      ]);

      setSynonyms((prevSynonyms) => prevSynonyms.filter((s) => s !== synonym));
    }

    if (justNewKeywords) {
      setSnackbar({
        open: true,
        severity: 'success',
        message: `All synonyms have been added successfully.`,
      });
    } else {
      setSnackbar({
        open: true,
        severity: 'warning',
        message: `Some synonyms already existed and were not added.`,
      });
      return;
    }
  };

  const handleSelectChange = (rowIndex: number, colIndex: number, newValue: string) => {
    const newRows = [...searchRules];
    newRows[rowIndex][colIndex].value = newValue;
    setSearchRules(newRows);
  };

  const addRow = () => {
    setSearchRules([...searchRules, [{ id: 0, value: 'Select...' }]]);
  };

  const addColumn = (rowIndex: number) => {
    const newRows = [...searchRules];
    newRows[rowIndex] = [...newRows[rowIndex], { id: 0, value: 'Select...' }];
    setSearchRules(newRows);
  };

  const confirmModelExecution = async () => {
    try {
      setLoadingConfirmationDialog(true);

      await KeywordModelAPI.runPerigonFlowByKeywordModel(keywordModel!.id);

      setSnackbar({
        open: true,
        severity: 'success',
        message: `The model execution has been initiated.`,
      });
    } catch (e) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: `An error occurred while initiating the model execution.`,
      });
    } finally {
      setLoadingConfirmationDialog(false);
      closeDialog();
    }
  };

  const closeDialog = () => {
    setOpenConfirmationDialog(false);
  };

  return (
    <>
      <ModelExecutionConfirmationDialog
        open={openConfirmationDialog}
        onConfirm={confirmModelExecution}
        onCancel={closeDialog}
        loading={loadingConfirmationDialog}
      />

      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={snackbar?.severity === 'error' ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
      <Box component="form" noValidate autoComplete="off" className="new-keywordModels">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={validateForm && !formData.modelName.length}>
              <InputLabel shrink sx={labelStyles}>
                Model Name
              </InputLabel>
              <TextField
                required
                fullWidth
                id="modelName"
                name="modelName"
                value={formData.modelName}
                onChange={(e) => setFormData({ ...formData, modelName: e.target.value })}
                InputProps={{ style: inputStyles }}
                error={validateForm && !formData.modelName.length}
              />
              {validateForm && !formData.owner.id && (
                <FormHelperText>Model Name is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl fullWidth error={validateForm && !formData.owner.id}>
              <InputLabel shrink sx={labelStyles}>
                Owner
              </InputLabel>
              <Select
                value={formData.owner?.id}
                onChange={(e) => {
                  const selectedOwner = owners.find((owner) => owner.id === e.target.value);
                  setFormData({
                    ...formData,
                    owner: selectedOwner!,
                  });
                }}
                style={inputStyles}
                error={validateForm && !formData.owner.id}
              >
                {owners.map((owner) => (
                  <MenuItem key={owner.id} value={owner.id}>
                    {owner.label}
                  </MenuItem>
                ))}
              </Select>
              {validateForm && !formData.owner.id && (
                <FormHelperText>Owner is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel shrink sx={labelStyles}>
                Active?
              </InputLabel>
              <Select
                id="isActive"
                name="isActive"
                value={formData.isActive}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    isActive: e.target.value === 'true',
                  })
                }
                displayEmpty
                style={inputStyles}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={validateForm && !formData.searchFrequencyId}>
              <InputLabel shrink sx={labelStyles}>
                Frequency of Search
              </InputLabel>
              <Select
                value={formData.searchFrequencyId}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    searchFrequencyId: Number(e.target.value),
                  })
                }
                displayEmpty
                style={inputStyles}
                error={validateForm && !formData.searchFrequencyId}
              >
                {frequencyOfSearch.map((frequency) => (
                  <MenuItem key={frequency.id} value={frequency.id}>
                    {frequency.frequencyName}
                  </MenuItem>
                ))}
              </Select>
              {validateForm && !formData.searchFrequencyId && (
                <FormHelperText>Frequency of Search is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel shrink sx={labelStyles}>
                Subscribers
              </InputLabel>
              <Autocomplete
                className="autocomplete"
                multiple
                options={(subscribers as StringKeySelectBoxOptionDto[]) || []}
                getOptionLabel={(option: StringKeySelectBoxOptionDto) => option?.label || ''}
                value={
                  (formData.subscribers
                    ? formData.subscribers.map((subscriber) => ({
                        id: subscriber.key,
                        label: subscriber.name,
                      }))
                    : []) as StringKeySelectBoxOptionDto[]
                }
                onChange={(_, newValue) =>
                  handleMultipleChange('subscribers', newValue as StringKeySelectBoxOptionDto[])
                }
                renderInput={(params) => <TextField {...params} />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key, ...restTagProps } = getTagProps({ index });

                    return <Chip key={option.id} label={option.label} {...restTagProps} />;
                  })
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={validateForm && !formData.sentimentRuleId}>
              <InputLabel shrink sx={labelStyles}>
                Sentiment Rule
              </InputLabel>
              <Select
                value={formData.sentimentRuleId || ''}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    sentimentRuleId: Number(e.target.value),
                  })
                }
                displayEmpty
                style={inputStyles}
                error={validateForm && !formData.sentimentRuleId}
              >
                {sentimentRule.map((sentiment) => (
                  <MenuItem key={sentiment.id} value={sentiment.id}>
                    {sentiment.ruleName}
                  </MenuItem>
                ))}
              </Select>
              {validateForm && !formData.sentimentRuleId && (
                <FormHelperText>Sentiment Rule is required</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container marginTop={1} spacing={4}>
          <Grid item xs={6}>
            <Typography variant="h6">Add a Keyword / Phrase</Typography>
          </Grid>
        </Grid>

        <Grid container marginTop={1}>
          <Grid item xs={6} className="add-keyword-phrase">
            <Grid container>
              <Grid item xs={10}>
                <TextField
                  value={newKeywordPhrase}
                  onChange={(e) => setNewKeywordPhrase(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (editingKeywordId !== null) {
                        handleSaveKeywordEdit(e);
                      } else {
                        handleAddKeywordPhrase();
                      }
                    }
                  }}
                  fullWidth
                  InputProps={{ style: inputStyles }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button onClick={handleAddKeywordPhrase}>Add</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container className="title-with-button">
              <Button disabled={!synonyms.length} onClick={addAllSynonymsToKeywords}>
                Add all
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container marginTop={0} spacing={4}>
          <Grid item xs={6}>
            <DataGrid
              data={keywords}
              columns={keywordsColumns}
              loadData={() => {}}
              deleteData={(id: number) => {
                setKeywords((prevKeywords) => prevKeywords.filter((k) => k.id !== id));

                setSynonyms([]);
              }}
              updateData={(id: number, data: any) => {
                setKeywords((prevKeywords) => prevKeywords.map((k) => (k.id === id ? data : k)));

                getSynonyms(data);
              }}
              isCreatable={false}
              handleRowClick={(data: any) => getSynonyms(data)}
            />
          </Grid>

          <Grid item xs={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Synonym</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {synonyms?.map((synonym, index) => (
                    <TableRow key={index}>
                      <TableCell>{synonym}</TableCell>
                      <TableCell align="right" size="small">
                        <Button onClick={() => addSynonymToKeywords(synonym)}>Add</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        {!newKeywordModel && (
          <Grid container marginTop={0} spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6">Search Rules</Typography>
              <Typography variant="body1">
                You can create search rules using attributes of the Supplier. These attributes will
                be used along with the Keywords and other settings, identified above to surface
                relevant articles and documents.
              </Typography>
            </Grid>

            {searchRules.map((row, rowIndex) => (
              <Grid item container key={rowIndex} spacing={2} alignItems="center">
                <Grid item xs={2}>
                  <Typography variant="h6" color="primary">
                    {!rowIndex ? 'MATCH' : 'OR MATCH'}
                  </Typography>
                </Grid>
                {row.map((col, colIndex) => (
                  <>
                    {colIndex > 0 && (
                      <Grid item>
                        <Typography variant="h6" color="primary">
                          AND
                        </Typography>
                      </Grid>
                    )}
                    <Grid item key={colIndex}>
                      <Select
                        size="small"
                        value={col.value}
                        onChange={(e) => handleSelectChange(rowIndex, colIndex, e.target.value)}
                      >
                        {searchRulesOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </>
                ))}
                <Grid item>
                  <IconButton onClick={() => addColumn(rowIndex)} color="primary">
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <IconButton onClick={addRow} color="primary">
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}

        <Grid container marginTop={4} spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ width: '115px' }}
            >
              Save
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenConfirmationDialog(true)}
              sx={{ width: '115px' }}
            >
              Run model
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default KeywordModelForm;
