// @ts-ignore: Implicit any module
import SuppliersAPI from '../../../api/suppliers/suppliersAPI';

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Chip,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Bubble } from 'react-chartjs-2';
import { Chart, Tooltip, Legend, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import {
  RiskCaseViewModelDto,
  RiskCaseWithSupplierDataDto,
  RiskMeasureDto,
  RiskMeasureLevelDto,
  SupplierWeightViewModelDto,
} from '../../../api/types/types';
import RiskMeasureAPI from '../../../api/riskMeasure/riskMeasureAPI';
import DashboardApi from '../../../api/dashboard/dashboardApi';
import getDataPoints from './utils/getDataPoints';
import { DataPoint } from './types/types';
import RiskCaseApi from '../../../api/riskCase/riskCaseApi';
import PopupRiskCases from '../../../components/risk-discovery/popup-risk-cases/PopupRiskCases';
import { addRiskCasesVm } from '../../../slices/riskCasesSlice';
import { useDispatch } from 'react-redux';

Chart.register(Tooltip, Legend, PointElement, LinearScale, Title, CategoryScale);

const RiskCasesChart: React.FC = () => {
  const theme = useTheme();
  const [processedData, setProcessedData] = useState<DataPoint[]>([]);
  const [yAxis, setYAxis] = useState<number>(0);
  const [xAxis, setXAxis] = useState<number>(0);
  const [selectedSupplierWeight, setSelectedSupplierWeight] = useState<number>(0);
  const [riskMeasures, setRiskMeasures] = useState<RiskMeasureDto[]>([]);
  const [supplierWeights, setSupplierWeights] = useState<SupplierWeightViewModelDto[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [xLabels, setXLabels] = useState<string[]>([]);
  const [yLabels, setYLabels] = useState<string[]>([]);
  const [riskCases, setRiskCases] = useState<RiskCaseWithSupplierDataDto[]>([]);
  const [riskCasesViewModel, setRiskCasesViewModel] = useState<RiskCaseViewModelDto[]>([]);
  const [loadingRiskCases, setLoadingRiskCases] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [popupRiskCasesVisible, setPopupRiskCasesVisible] = useState<boolean>(false);
  const [popupRiskCases, setPopupRiskCases] = useState<RiskCaseViewModelDto[]>([]);
  const dispatch = useDispatch();

  const getRiskMeasures = async () => {
    const response = await RiskMeasureAPI.getAllRiskMeasure();
    setRiskMeasures(response);
    const magnitude: RiskMeasureDto = response.find(
      (rm: RiskMeasureDto) => rm.riskMeasureName === 'Magnitude'
    );
    const probability: RiskMeasureDto = response.find(
      (rm: RiskMeasureDto) => rm.riskMeasureName === 'Probability'
    );
    setYAxis(magnitude.id);
    setXAxis(probability.id);
  };

  const getSupplierWeights = async () => {
    const response = await SuppliersAPI.getAllSupplierWeights();
    setSupplierWeights([{ id: 0, label: 'All Suppliers' }, ...response]);
  };

  const getRiskCases = async () => {
    setLoadingRiskCases(true);
    const response = await DashboardApi.getRiskCasesBySupplierWeight(selectedSupplierWeight);
    setRiskCases(response);

    const groupedData = groupDataPoints(getDataPoints(response, riskMeasures, xAxis, yAxis));
    setProcessedData(groupedData);
    setLoadingRiskCases(false);
  };

  const getRiskCasesViewModel = async () => {
    const response = await RiskCaseApi.getAllRiskCaseVm();
    setRiskCasesViewModel(response);
    dispatch(addRiskCasesVm(response));
  };

  const fetchInitialData = async () => {
    setLoading(true);
    await Promise.all([getRiskMeasures(), getSupplierWeights(), getRiskCasesViewModel()]);
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const getAxisLabels = (rm: RiskMeasureDto) => {
    return rm?.riskMeasureLevels?.map((rml: RiskMeasureLevelDto) => rml.label) || [];
  };

  useEffect(() => {
    if (!xLabels.length) return;
    getRiskCases();
  }, [xLabels, selectedSupplierWeight]);

  useEffect(() => {
    setXLabels(getAxisLabels(riskMeasures.find((rm) => rm.id === xAxis)!).reverse());
    setYLabels(getAxisLabels(riskMeasures.find((rm) => rm.id === yAxis)!));
  }, [xAxis, yAxis]);

  const groupDataPoints = (points: DataPoint[]): DataPoint[] => {
    const grouped: { [key: string]: DataPoint } = {};
    points.forEach((point) => {
      const key = `${point.x}-${point.y}`;
      if (grouped[key]) {
        const uniqueSuppliers = new Set(grouped[key].suppliers?.map((s) => s?.supplierId));
        point.suppliers?.forEach((supplier) => uniqueSuppliers.add(supplier?.supplierId));
        grouped[key].suppliers = Array.from(uniqueSuppliers)
          .map((id) => point.suppliers!.find((s) => s.supplierId === id)!)
          .filter((s) => s != undefined && !isNaN(s.supplierId));

        const uniqueRiskCases = new Set([...grouped[key].riskCases, ...point.riskCases]);
        grouped[key].riskCases = Array.from(uniqueRiskCases);
      } else {
        grouped[key] = {
          x: point.x,
          y: point.y,
          suppliers: point.suppliers!,
          riskCases: point.riskCases,
        };
      }
    });
    return Object.values(grouped);
  };

  const handleSwap = () => {
    const temp = yAxis;
    setYAxis(xAxis);
    setXAxis(temp);
  };

  const chartData = {
    datasets: [
      {
        label: 'Scatter Plot',
        data: processedData.map((d) => ({
          x: d.x,
          y: d.y,
          r: d.riskCases.length > 1 || d.suppliers.length > 1 ? 8 : 4,
          suppliers: d.suppliers.length,
          riskCases: d.riskCases.length,
        })),
        backgroundColor: processedData.map((d) => {
          let color = theme.palette.primary.main;
          const supplierWeight = supplierWeights.find((sw) => sw.id === selectedSupplierWeight);

          if (selectedSupplierWeight === 0) {
            // All suppliers
            if (d.riskCases.length <= 1 && d.suppliers.length <= 1 && d.suppliers[0]?.hex) {
              color = d.suppliers[0]?.hex;
            }
          } else if (supplierWeight?.themeColor?.hex) {
            color = supplierWeight?.themeColor?.hex;
          }

          return color;
        }),

        pointRadius: 7,
      },
    ],
  };

  const options = {
    onClick: (event: any, elements: any[]) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const clickedData = processedData[index];
        if (clickedData) {
          setPopupRiskCases(
            riskCasesViewModel.filter((rcvm) =>
              clickedData.riskCases.includes(rcvm.generalInformation.riskCaseId)
            )
          );
        }
      }
    },
    scales: {
      x: {
        type: 'category' as const,
        position: 'bottom' as const,
        ticks: {
          callback: function (tickValue: string | number) {
            const index = typeof tickValue === 'number' ? tickValue : parseInt(tickValue, 10);
            return xLabels[index] || '';
          },
        },
        labels: xLabels,
      },
      y: {
        type: 'category' as const,
        ticks: {
          callback: function (tickValue: string | number) {
            const index = typeof tickValue === 'number' ? tickValue : parseInt(tickValue, 10);
            return yLabels[index] || '';
          },
        },
        labels: yLabels,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const riskCases = context.raw.riskCases;
            const suppliers = context.raw.suppliers;
            return `(${context.raw.x}, ${context.raw.y}) - ${riskCases} Risk Case${
              riskCases > 1 ? 's' : ''
            }, ${suppliers} Supplier${suppliers > 1 ? 's' : ''}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const openDialog = (id: number) => {
    const selectedMeasure = riskMeasures.find((rm: RiskMeasureDto) => rm.id === id);

    if (selectedMeasure?.isSystemLevel === false) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRiskCasesClose = () => {
    setPopupRiskCases([]);
  };

  useEffect(() => {
    setPopupRiskCasesVisible(popupRiskCases.length ? true : false);
  }, [popupRiskCases]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography variant="body1">
            This is not a required Measure so some Risk Cases may not have a corresponding value.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" pr={1}>
            Risk Cases
          </Typography>
          <Chip
            label={
              loadingRiskCases ? <CircularProgress color="inherit" size={10} /> : riskCases.length
            }
            sx={{ fontWeight: 'bold' }}
          />
        </AccordionSummary>
        <AccordionDetails>
          {loading || loadingRiskCases ? (
            <Box display="flex" justifyContent="center" alignItems="center" height={'400px'}>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={4}
              width="100%"
              height={'400px'}
            >
              <Box width="65%" height={'100%'}>
                <Bubble data={chartData} options={options} />
              </Box>

              <Box display="flex" alignSelf={'center'} flexDirection="column" gap={2} width="30%">
                <FormControl fullWidth>
                  <InputLabel id="y-axis-label">Y Axis</InputLabel>
                  <Select
                    labelId="y-axis-label"
                    value={yAxis}
                    onChange={(e) => {
                      setYAxis(e.target.value as number);
                      openDialog(e.target.value as number);
                    }}
                    label="Y Axis"
                  >
                    {riskMeasures.map((measure: RiskMeasureDto) => (
                      <MenuItem key={measure.id} value={measure.id}>
                        {measure.riskMeasureName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    onClick={handleSwap}
                    color="primary"
                    variant="text"
                    startIcon={<SwapVertIcon />}
                  >
                    Swap
                  </Button>
                </Box>

                <FormControl fullWidth>
                  <InputLabel id="x-axis-label">X Axis</InputLabel>
                  <Select
                    labelId="x-axis-label"
                    value={xAxis}
                    onChange={(e) => {
                      setXAxis(e.target.value as number);
                      openDialog(e.target.value as number);
                    }}
                    label="X Axis"
                  >
                    {riskMeasures.map((measure: RiskMeasureDto) => (
                      <MenuItem key={measure.id} value={measure.id}>
                        {measure.riskMeasureName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="supplier-weight-label">Supplier Weight</InputLabel>
                  <Select
                    labelId="supplier-weight-label"
                    value={selectedSupplierWeight}
                    onChange={(e) => setSelectedSupplierWeight(e.target.value as number)}
                    label="Supplier Weight"
                  >
                    {supplierWeights.map((weight) => (
                      <MenuItem key={weight.id} value={weight.id}>
                        {weight.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <PopupRiskCases
        popupRiskCasesVisible={popupRiskCasesVisible}
        handleRiskCasesClose={handleRiskCasesClose}
        riskCases={popupRiskCases}
      />
    </>
  );
};

export default RiskCasesChart;
