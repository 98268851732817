import React, { useEffect, useState } from 'react';
import { UserDto } from '../../../../api/types/types';
import { createColumn } from '../../../../utils/data-grid';
import { DataGridPro, GridColDef, GridDensity, GridRowModel } from '@mui/x-data-grid-pro';
import DmsmsApi from '../../../../api/dmsms/dmsmsAPI';
import PermissionsApi from '../../../../api/permissions/permissionsAPI';

interface PartHistoryGridProps {
  watchedPartId: number;
  density?: GridDensity | undefined;
}

export default function PartHistoryGrid({ watchedPartId, density }: PartHistoryGridProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [users, setUsers] = useState<UserDto[]>([]);

  /**
   * Retrieve grid data
   */
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await DmsmsApi.getPartHistoriesForWatchedPart(watchedPartId);

        if (response.isSuccess) {
          setRows(response.result);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    fetchData();
  }, [watchedPartId]);

  /**
   * Retrieve users.
   */
  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await PermissionsApi.getAllUsers(false);
        setUsers(response.result);
      } catch (error) {
        console.error(error);
      }
    }
    fetchUsers();
  }, []);

  // Columns for grid
  const columns: GridColDef[] = [
    { ...createColumn('description', 'Description'), flex: 1 },
    {
      ...createColumn('dateCreated', 'Date'),
      width: 200,
      flex: 0,
      valueFormatter: (value: string) => {
        if (value) {
          value += 'Z';
          return new Date(value).toLocaleString();
        } else {
          return undefined;
        }
      },
    },
    {
      field: 'createdByUserId',
      headerName: 'User',
      type: 'singleSelect',
      flex: 0,
      width: 250,
      valueOptions: users,
      getOptionLabel: (value: any) => value.fullName,
      getOptionValue: (value: any) => value.id,
    },
  ];

  return (
    <div style={{ height: '370px' }}>
      <DataGridPro
        loading={isLoading}
        editMode="row"
        rows={rows}
        columns={columns}
        density={density}
        // pagination
        // pageSizeOptions={[5, 10, 20, { value: -1, label: 'All' }]}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
      />
    </div>
  );
}
