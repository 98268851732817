import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DmsmsState {
  selectedLifeStage: string | null;
  selectedSupplier: string | null;
}

const initialState: DmsmsState = {
  selectedLifeStage: null,
  selectedSupplier: null,
};

const dmsmsSlice = createSlice({
  name: 'dmsms',
  initialState,
  reducers: {
    setSelectedLifeStage(state, action: PayloadAction<string | null>) {
      state.selectedLifeStage = action.payload;
    },
    setSelectedSupplier(state, action: PayloadAction<string | null>) {
      state.selectedSupplier = action.payload;
    },
  },
});

export const { setSelectedLifeStage, setSelectedSupplier } = dmsmsSlice.actions;
export default dmsmsSlice.reducer;
