import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import protectedRoutes from './app-routes';
import { SideNavToolbar } from './layouts';

export default function Content() {
  return (
    <SideNavToolbar>
      <Routes>
        {protectedRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path="*" element={<Navigate to="/site-admin" />} />
      </Routes>
    </SideNavToolbar>
  );
}
