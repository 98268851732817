import React, { useEffect, useState } from 'react';
import {
  TextField,
  Autocomplete,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Paper,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import RiskCaseApi from '../../../../api/riskCase/riskCaseApi';

interface SelectBoxOption {
  id: number;
  label: string;
}

interface Owner {
  key: string;
  name: string;
}

// interface IRiskCase {
//   id: number;
//   riskCaseName: string;
//   suppliers: SelectBoxOption[];
//   owner: Owner | null;
//   dateAdded: string | null;
//   isActive: boolean;
//   description: string;
//   kwm: SelectBoxOption[];
//   subscribers: Owner[];
// }
interface IRiskCase {
  riskCaseId?: number;
  dateAdded?: string;
  dateUpdated?: string;
  dateDeleted?: null;
  isActive?: boolean;
  isDeleted?: boolean;
  riskCaseName?: string;
  comments?: string;
  description?: string;
  owner?: Owner | null;
  subscribers?: any[];
  suppliers: any[];
  keywordModels?: any[];
  kwm?: SelectBoxOption[];
}

interface GeneralInformationProps {
  formData: IRiskCase;
  setFormData: React.Dispatch<React.SetStateAction<IRiskCase>>;
  isLoading: boolean;
  ownersDropdown?: Owner[];
  subscribersDropdown?: Owner[];
  suppliersDropdown?: SelectBoxOption[];
  kwmDropdown?: SelectBoxOption[];
}

// const GeneralInformationRiskCase: React.FC<GeneralInformationProps> = ({
//   formData,
//   setFormData,
//   isLoading,
// }) => {
const GeneralInformationRiskCase: React.FC<{
  isLoading: boolean;
  formData: IRiskCase;
  setFormData: (data: Partial<IRiskCase>) => void;
}> = ({ isLoading, formData, setFormData }) => {
  const [ownersDropdown, setOwnersDropdown] = useState<Owner[]>([]);
  const [subscribersDropdown, setSubscribersDropdown] = useState<Owner[]>([]);
  const [suppliersDropdown, setSuppliersDropdown] = useState<SelectBoxOption[]>([]);
  const [kwmDropdown, setKwmDropdown] = useState<SelectBoxOption[]>([]);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const owners = await RiskCaseApi.getOwnersDropdown();
        const subscribers = await RiskCaseApi.getSubscriberDropdown();
        const suppliers = await RiskCaseApi.getSuppliersDropdown();
        const kwm = await RiskCaseApi.getKeywordModelsDropdown();

        setOwnersDropdown(owners);
        setSubscribersDropdown(subscribers);
        setSuppliersDropdown(suppliers);
        setKwmDropdown(kwm);
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);

  const handleChange = (field: keyof IRiskCase, value: any) => {
    requestAnimationFrame(() => {
      setFormData({
        ...formData,
        [field]: value,
      });
    });
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper elevation={0} style={{ padding: '20px', marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Risk Case Name"
              value={formData.riskCaseName}
              onChange={(e) => handleChange('riskCaseName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              label="Date Created"
              value={isLoading ? null : formData.dateAdded ? new Date(formData.dateAdded) : null}
              onChange={(newValue: Date | null) =>
                handleChange('dateAdded', newValue?.toISOString() || null)
              }
              disabled={isLoading}
              slotProps={{
                textField: {
                  fullWidth: true,
                  InputProps: {
                    endAdornment: isLoading ? <CircularProgress size={20} /> : null,
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <FormControl fullWidth>
              <InputLabel>Is Active?</InputLabel>
              <Select
                value={formData.isActive ? 'Yes' : 'No'}
                onChange={(e) => handleChange('isActive', e.target.value === 'Yes')}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl> */}
            <Grid item xs={12} md={7}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.isActive}
                    onChange={(e) => handleChange('isActive', e.target.checked)}
                  />
                }
                label="Is Active?"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              options={suppliersDropdown}
              getOptionLabel={(option) => option.label}
              value={formData.suppliers}
              onChange={(_, newValue) => handleChange('suppliers', newValue)}
              renderInput={(params) => <TextField {...params} label="Suppliers" />}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option.label} {...getTagProps({ index })} />
                ))
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Description"
              value={formData.description}
              onChange={(e) => handleChange('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={ownersDropdown}
              getOptionLabel={(option) => option.name}
              value={formData.owner || null}
              onChange={(_, newValue) => handleChange('owner', newValue)}
              renderInput={(params) => <TextField {...params} label="Select Owner" />}
              isOptionEqualToValue={(option, value) => option.key === value?.key}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              options={subscribersDropdown}
              getOptionLabel={(option) => option.name}
              value={formData.subscribers}
              onChange={(_, newValue) => handleChange('subscribers', newValue)}
              renderInput={(params) => <TextField {...params} label="Subscribers" />}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option.name} {...getTagProps({ index })} />
                ))
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              options={kwmDropdown}
              getOptionLabel={(option) => option.label}
              // value={formData.kwm || []}
              // onChange={(_, newValue) => handleChange('kwm', newValue)}
              value={formData.keywordModels || []}
              onChange={(_, newValue) => handleChange('keywordModels', newValue)}
              renderInput={(params) => <TextField {...params} label="Keyword Model" />}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option.label} {...getTagProps({ index })} />
                ))
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>
        </Grid>
      </Paper>
    </LocalizationProvider>
  );
};

export default GeneralInformationRiskCase;
