import { LoadIndicator, TreeView } from 'devextreme-react';
import React, { useState } from 'react';
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';

interface TreeViewItem {
  id: number | string;
  text: string;
  expanded?: boolean;
  modelId?: number | string;
  type?: string;
  items?: TreeViewItem[];
  nonSelectable?: boolean;
}

const initialItems: TreeViewItem[] = [
  {
    id: 1,
    text: 'Risk Measures',
    expanded: true,
    items: [],
  },
  {
    id: 2,
    text: 'Risk Discovery Settings',
    expanded: true,
    items: [],
  },
  {
    id: 3,
    text: 'Role & Permissions',
    expanded: true,
    items: [],
  },
  {
    id: 8,
    text: 'Supplier Settings',
    expanded: true,
    items: [],
  },
  {
    id: 9,
    text: 'Vendor Auto-Load',
    expanded: true,
    items: [],
  },
  {
    id: 4,
    text: 'Logs',
    expanded: true,
    items: [
      {
        id: 5,
        text: 'API Calls',
        expanded: true,
        items: [],
      },
      {
        id: 6,
        text: 'Supplier Inserts',
        expanded: true,
        items: [],
      },
      {
        id: 7,
        text: 'User Access',
        expanded: true,
        items: [],
      },
    ],
  },
];

const renderTreeViewItem = (item: TreeViewItem, selectedItem: string | number | null) => {
  const itemClass =
    item.modelId === selectedItem
      ? 'tree-view-item selected'
      : item.nonSelectable
        ? 'tree-view-item non-selectable'
        : item.items && item.items.length > 0
          ? 'tree-view-item title'
          : 'tree-view-item value';
  return <div className={itemClass}>{item.text}</div>;
};

const AdminMenu: React.FC = () => {
  const [items, setItems] = useState<TreeViewItem[]>(initialItems);
  const [selectedItem, setSelectedItem] = useState<string | number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleItemClick = (e: { itemData: TreeViewItem }) => {
    const itemData = e.itemData as TreeViewItem;
    const selectedId = itemData.modelId ?? itemData.id;
    setSelectedItem(selectedId);

    if (selectedId) {
      let url = '';
      switch (selectedId) {
        case 1:
          url = `/scrm/admin/risk-measures`;
          break;
        case 8:
          url = `/scrm/admin/supplier-settings`;
          break;
        case 9:
          url = `/scrm/admin/vendor-auto-load`;
          break;
        default:
          break;
      }
      return navigate(url);
    }
  };

  return (
    <div id="treeViewContainer" className="tree-view-container">
      {isLoading ? (
        <div className="spinner">
          <LoadIndicator />
        </div>
      ) : (
        <TreeView
          items={items}
          width={300}
          itemRender={(item) => renderTreeViewItem(item, selectedItem)}
          onItemClick={handleItemClick as any}
          selectByClick={true}
        />
      )}
    </div>
  );
};

export default AdminMenu;
