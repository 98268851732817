import Dashboard from '../../components/scrm/dashboard/Dashboard';
import React from 'react';
import './index.scss';

const DashboardPage = () => {
  return (
    <div className="dashboard-page">
      <Dashboard />
    </div>
  );
};

export default DashboardPage;
