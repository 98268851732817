// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, PartForecastDto } from '../types/types';
const { DMSMS_SERVICE_URL } = config;

const PartForecasts = {
  /**
   * Retrieves all part forecasts
   * @returns
   */
  getPartForecasts: async function (): Promise<ResponseDto<PartForecastDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/PartForecast`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<PartForecastDto[]>;
  },
  /**
   * Retrieves all forecasts for a watched part.
   * @returns
   */
  getForecastsForWatchedPart: async function (
    watchedPartId: number
  ): Promise<ResponseDto<PartForecastDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/PartForecast/ForWatchedPart/${watchedPartId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<PartForecastDto[]>;
  },
  /**
   * Retrieves a part forecast
   * @returns
   */
  getPartForecast: async function (partForecastId: number): Promise<ResponseDto<PartForecastDto>> {
    var url = `${DMSMS_SERVICE_URL}/PartForecast/${partForecastId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<PartForecastDto>;
  },
  getPartForecastByWatchedPartId: async function (
    watchedPartId: number
  ): Promise<ResponseDto<PartForecastDto>> {
    var url = `${DMSMS_SERVICE_URL}/PartForecast/ForWatchedPart/${watchedPartId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<PartForecastDto>;
  },

  /**
   * Creates a part forecast
   * @param forecast
   */
  createPartForecast: async function (
    forecast: PartForecastDto
  ): Promise<ResponseDto<PartForecastDto>> {
    var url = `${DMSMS_SERVICE_URL}/PartForecast`;
    const { data } = await httpClient.post(url, forecast);
    return data as ResponseDto<PartForecastDto>;
  },

  /**
   * Updates a part forecast
   * @param forecast
   */
  updatePartForecast: async function (
    forecast: PartForecastDto
  ): Promise<ResponseDto<PartForecastDto>> {
    var url = `${DMSMS_SERVICE_URL}/PartForecast`;
    const { data } = await httpClient.put(url, forecast);
    return data as ResponseDto<PartForecastDto>;
  },

  /**
   * Deletes a part forecast
   * @param part
   */
  deletePartForecast: async function (partForecastId: number): Promise<ResponseDto<any>> {
    var url = `${DMSMS_SERVICE_URL}/PartForecast/${partForecastId}`;
    const { data } = await httpClient.delete(url);
    return data as ResponseDto<any>;
  },
};

export default PartForecasts;
