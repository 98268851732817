const config = {
  DNB_SERVICE_URL: process.env.REACT_APP_DNB_SERVICE_URL,
  SUPPLIERS_SERVICE_URL: process.env.REACT_APP_SUPPLIERS_SERVICE_URL,
  ARTICLES_SERVICE_URL: process.env.REACT_APP_ARTICLES_SERVICE_URL,
  KEYWORD_MODELS_SERVICE_URL: process.env.REACT_APP_KEYWORD_MODELS_SERVICE_URL,
  RISK_DISCOVERY_SERVICE_URL: process.env.REACT_APP_RISK_DISCOVERY_SERVICE_URL,
  RISK_CASE_SERVICE_URL: process.env.REACT_APP_RISK_CASE_SERVICE_URL,
  NSN_SERVICE_URL: process.env.REACT_APP_NSN_SERVICE_URL,
  DMSMS_SERVICE_URL: process.env.REACT_APP_DMSMS_SERVICE_URL,
  PERMISSIONS_SERVICE_URL: process.env.REACT_APP_PERMISSIONS_SERVICE_URL,
  POWERLOG_SERVICE_URL: process.env.REACT_APP_POWERLOG_SERVICE_URL,
  JOB_SERVICE_URL: process.env.REACT_APP_JOB_SERVICE_URL,
  ALTRUITY_API_URL: process.env.REACT_APP_ALTRUITY_API_URL,
};

export default config;
