import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Form, GroupItem, SimpleItem, Item } from 'devextreme-react/form';
import { Button, LoadIndicator } from 'devextreme-react';
import './index.scss';
import DnbAPI from '../../../../api/dnb/dnbAPI';
import { useSelector } from 'react-redux';

const Information = () => {
  const [companyInformation, setCompanyInformation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  const suppliers = useSelector((state) => state.suppliers.allSuppliers);
  const supplier =
    id !== 'add-supplier' && suppliers.find((supplier) => supplier.id === Number(id));
  const duns = supplier ? supplier.duns : undefined;

  const fetchData = async () => {
    try {
      const data = await DnbAPI.getCompanyInformationByDuns(duns);
      setCompanyInformation(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshData = async () => {
    try {
      const data = await DnbAPI.refreshCompanyInformationByDuns(duns);
      setCompanyInformation(data);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = () => {
    refreshData();
  };

  const sanitizeUrl = (url) => {
    if (!url) {
      return '';
    }

    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return 'https://' + url;
  };

  if (error) return <div>No Data</div>;

  const Website = (data) => {
    return (
      <div className="dnb-information__website-cell">
        <a href={sanitizeUrl(data.editorOptions.value)} target="_blank" rel="noopener noreferrer">
          {data.editorOptions.value}
        </a>
      </div>
    );
  };

  const Competitors = (data) => (
    <div className="dnb-information__competitors-cell">
      {data.editorOptions.items.map((competitor, index) => (
        <React.Fragment key={competitor.id}>
          <a href={sanitizeUrl(competitor.website)} target="_blank" rel="noopener noreferrer">
            {competitor.primaryName}
          </a>
          {index < data.editorOptions.items.length - 1 && ', '}
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadIndicator />
        </div>
      ) : (
        <div>
          <Form formData={companyInformation} colCount={3}>
            <SimpleItem
              dataField="primaryName"
              label={{ text: 'Primary Name' }}
              editorOptions={{ readOnly: true }}
              colSpan={1}
            />
            <SimpleItem
              dataField="website"
              label={{ text: 'Website' }}
              editorOptions={{ readOnly: true }}
              colSpan={1}
              render={Website}
            />
            <GroupItem colSpan={1} colCount={4}>
              <SimpleItem
                dataField="lastUpdated"
                editorType="dxDateBox"
                label={{ text: 'Last Updated' }}
                editorOptions={{ readOnly: true }}
                colSpan={3}
              />
              <Item colSpan={1}>
                <Button
                  icon="refresh"
                  type="normal"
                  onClick={handleRefresh}
                  hint="Refresh"
                  style={{ marginTop: '32px', marginLeft: '10px' }}
                />
              </Item>
            </GroupItem>
            <SimpleItem
              dataField="legalForm"
              label={{ text: 'Legal Form' }}
              editorOptions={{ readOnly: true }}
              colSpan={1}
            />
            <SimpleItem
              dataField="yearlyRevenue"
              label={{ text: 'Yearly Revenue' }}
              editorOptions={{ readOnly: true, format: 'currency' }}
              colSpan={1}
            />
            <SimpleItem
              dataField="numberOfEmployees"
              label={{ text: 'Number of Employees' }}
              editorOptions={{ readOnly: true, format: '#,##0' }}
              colSpan={1}
            />
            <SimpleItem
              dataField="tradeNames"
              label={{ text: 'Trade Names' }}
              editorOptions={{ readOnly: true }}
              colSpan={2}
            />
            <SimpleItem
              dataField="firstReportDate"
              editorType="dxDateBox"
              label={{ text: 'First Report Date' }}
              editorOptions={{ readOnly: true }}
              colSpan={1}
            />
            <SimpleItem
              dataField="summary"
              editorType="dxTextArea"
              label={{ text: 'Summary' }}
              editorOptions={{ readOnly: true }}
              colSpan={3}
            />
            <SimpleItem
              dataField="businessTrust"
              label={{ text: 'Business Trust' }}
              editorOptions={{ readOnly: true }}
              colSpan={3}
            />
            <SimpleItem
              dataField="stockExchanges"
              label={{ text: 'Stock Exchanges' }}
              editorOptions={{ readOnly: true }}
              colSpan={3}
            />
            <SimpleItem
              dataField="competitors"
              label={{ text: 'Competitors' }}
              render={Competitors}
              editorOptions={{
                items: companyInformation.competitors,
                readOnly: true,
              }}
              colSpan={3}
            />
          </Form>
        </div>
      )}
    </>
  );
};

export default Information;
