import Help from './help';
import Search from './search';
import Batch from './batch';
import Verify from './verify';

const NsnApi = {
  ...Search,
  ...Help,
  ...Batch,
  ...Verify,
};

export default NsnApi;
