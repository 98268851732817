// @ts-ignore: Implicit any module
import SuppliersAPI from '../../../../api/suppliers/suppliersAPI';

import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataGrid from '../../../../components/data-grid/DataGrid';
import './SupplierSettings.scss';
import { GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid';
import ThemePickerEditComponent from '../../../risk-measures/RiskMeasuresTable/CustomThemePickerComponent';
import {
  SupplierWeightUpdateDto,
  SupplierWeightViewModelDto,
  ThemeColorDto,
} from '../../../../api/types/types';
import { ThemeDisplay } from '../../../risk-measures/RiskMeasuresTable/ColorThemePicker';

const SupplierSettings = () => {
  const [supplierWeights, setSupplierWeights] = useState<any>();

  const columns: GridColDef[] = [
    {
      field: 'weight',
      headerName: 'Weight',
      editable: false,
    },
    {
      field: 'label',
      headerName: 'Label',
    },
    {
      field: 'themeColor',
      headerName: 'Color Theme',
      width: 300,
      editable: true,
      renderCell: (params) => {
        const availableThemes: ThemeColorDto[] | null = params.row.availableThemes;
        const themeId = params.value?.id ?? null;

        const theme =
          themeId && availableThemes ? availableThemes.find((t) => t.id === themeId) : null;

        return theme ? <ThemeDisplay theme={theme}></ThemeDisplay> : <></>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <ThemePickerEditComponent {...params} availableThemes={params.row.availableThemes} />
      ),
    },
  ];

  const loadData = async () => {
    const response: SupplierWeightViewModelDto[] = await SuppliersAPI.getAllSupplierWeights();
    const allThemes: ThemeColorDto[] = await SuppliersAPI.getAllThemes();

    // Attach the available themes for the theme picker
    const data = response.map((r) => ({
      ...r,
      availableThemes: allThemes,
    }));

    setSupplierWeights(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="supplier-settings">
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Supplier Weights</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            data={supplierWeights}
            columns={columns}
            loadData={loadData}
            updateData={async (id: number, data: any) => {
              const body: SupplierWeightUpdateDto = {
                ...data,
                themeColorId: data.themeColor?.id,
              };
              await SuppliersAPI.updateSupplierWeight(body);
            }}
            isDeletable={false}
            isCreatable={false}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SupplierSettings;
