// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, ActionDto } from '../types/types';
const { DMSMS_SERVICE_URL } = config;

const Actions = {
  /**
   * Retrieves all actions
   * @returns
   */
  getActions: async function (): Promise<ResponseDto<ActionDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/Action`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<ActionDto[]>;
  },

  /**
   * Retrieves a action
   * @returns
   */
  getAction: async function (actionId: number): Promise<ResponseDto<ActionDto>> {
    var url = `${DMSMS_SERVICE_URL}/Action/${actionId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<ActionDto>;
  },

  /**
   * Creates a action
   * @param action
   */
  createAction: async function (action: ActionDto): Promise<ResponseDto<ActionDto>> {
    var url = `${DMSMS_SERVICE_URL}/Action`;
    const { data } = await httpClient.post(url, action);
    return data as ResponseDto<ActionDto>;
  },

  /**
   * Creates an action
   * @param action
   */
  updateAction: async function (action: ActionDto): Promise<ResponseDto<ActionDto>> {
    var url = `${DMSMS_SERVICE_URL}/Action`;
    const { data } = await httpClient.put(url, action);
    return data as ResponseDto<ActionDto>;
  },

  /**
   * Deletes a action
   * @param actionId
   */
  deleteAction: async function (actionId: number): Promise<ResponseDto<any>> {
    var url = `${DMSMS_SERVICE_URL}/Action/${actionId}`;
    const { data } = await httpClient.delete(url);
    return data as ResponseDto<any>;
  },
};
export default Actions;
