// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, WatchedPartCaseLogEntryDto } from '../types/types';
const { DMSMS_SERVICE_URL } = config;

const WatchedPartCaseLog = {
  /**
   * Retrieves all case log entries.
   * @returns
   */
  getWatchedPartCaseLogEntries: async function (): Promise<
    ResponseDto<WatchedPartCaseLogEntryDto[]>
  > {
    var url = `${DMSMS_SERVICE_URL}/WatchedPartCaseLogEntry`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<WatchedPartCaseLogEntryDto[]>;
  },

  /**
   * Retrieves all case logs for a part.
   * @returns
   */
  getCaseLogEntriesForWatchedPart: async function (
    watchedPartId: number
  ): Promise<ResponseDto<WatchedPartCaseLogEntryDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPartCaseLogEntry/ForWatchedPart/${watchedPartId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<WatchedPartCaseLogEntryDto[]>;
  },
  /**
   * Retrieves a case log.
   * @returns
   */
  getWatchedPartCaseLogEntry: async function (
    substitutePartId: number
  ): Promise<ResponseDto<WatchedPartCaseLogEntryDto>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPartCaseLogEntry/${substitutePartId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<WatchedPartCaseLogEntryDto>;
  },

  /**
   * Creates a case log
   * @param part
   */
  createWatchedPartCaseLogEntry: async function (
    part: WatchedPartCaseLogEntryDto
  ): Promise<ResponseDto<WatchedPartCaseLogEntryDto>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPartCaseLogEntry`;
    const { data } = await httpClient.post(url, part);
    return data as ResponseDto<WatchedPartCaseLogEntryDto>;
  },

  /**
   * Updates a case log
   * @param part
   */
  updateWatchedPartCaseLogEntry: async function (
    part: WatchedPartCaseLogEntryDto
  ): Promise<ResponseDto<WatchedPartCaseLogEntryDto>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPartCaseLogEntry`;
    const { data } = await httpClient.put(url, part);
    return data as ResponseDto<WatchedPartCaseLogEntryDto>;
  },

  /**
   * Deletes a case log
   * @param part
   */
  deleteWatchedPartCaseLogEntry: async function (
    watchedPartCaseLogEntryId: number
  ): Promise<ResponseDto<any>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPartCaseLogEntry/${watchedPartCaseLogEntryId}`;
    const { data } = await httpClient.delete(url);
    return data as ResponseDto<any>;
  },
};

export default WatchedPartCaseLog;
