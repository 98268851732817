import React, { ReactElement } from 'react';
import { Box } from '@mui/material';

interface BadgeProps {
  monochromaticScale: string[];
  type: 'light' | 'dark';
  children?: React.ReactNode;
  icon?: ReactElement;
}

const Badge = ({ monochromaticScale, type, children, icon }: BadgeProps) => {
  const iconColor = type === 'dark' ? monochromaticScale[8] : monochromaticScale[2]; // Determine icon color

  return (
    <>
      {monochromaticScale.length > 0 && (
        <Box
          sx={{
            backgroundColor: type === 'dark' ? monochromaticScale[2] : monochromaticScale[8],
            color: iconColor,
            border: `1px solid ${iconColor}`,
            padding: '3px 10px',
            borderRadius: '15px',
            display: 'inline-flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          {icon && React.cloneElement(icon, { fontSize: 'small', sx: { color: iconColor } })}{' '}
          {/* Render icon */}
          {children || 'Altruity'} {/* Render children or default text */}
        </Box>
      )}
    </>
  );
};

export default Badge;
