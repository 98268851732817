import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
  SnackbarCloseReason,
} from '@mui/material';
import RiskCaseApi from '../../../../api/riskCase/riskCaseApi';
import { RiskCaseDto, UserDto } from '../../../../api/types/types';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRiskCaseGridData,
  setSelectedMenuItem,
  updateRiskCaseMenu,
} from '../../../../slices/riskCasesSlice';
import { SnackbarProps } from '../../../../types/Snackbar';

const RiskCasesGrid = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [riskCaseToBeArchived, setRiskCaseToBeArchived] = useState<any>();
  const [snackbar, setSnackbar] = useState<SnackbarProps>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Select riskCaseGridData from Redux store
  const riskCases = useSelector((state: any) => state.riskCases.riskCaseGridData);
  const shouldRefresh = useSelector((state: any) => state.riskCases.stateChangeCounter);

  const handleViewClick = (riskCase: RiskCaseDto) => {
    dispatch(setSelectedMenuItem(riskCase.id));
    const url = `/scrm/risk-cases/supplier/${riskCase.id}`;
    return navigate(url);
  };

  const getRiskCases = async () => {
    try {
      setLoading(true);
      const data = await RiskCaseApi.getAllRiskCasesForGridView();
      dispatch(setRiskCaseGridData(data));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRiskCases();
  }, [shouldRefresh, dispatch]);

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  const handleArchiveConfirmation = (row: any) => {
    setOpenConfirmDialog(true);
    setRiskCaseToBeArchived(row);
  };

  const handleArchived = async () => {
    try {
      await RiskCaseApi.archiveRiskCase(riskCaseToBeArchived.id);

      setOpenConfirmDialog(false);

      setSnackbar({
        open: true,
        severity: 'success',
        message: `Risk case archived successfully`,
      });

      dispatch(
        updateRiskCaseMenu({
          updatedRiskCase: {
            ...riskCaseToBeArchived,
            riskCaseId: riskCaseToBeArchived.id,
          },
          isArchived: true,
        })
      );
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        severity: 'error',
        message: `Error archiving risk case`,
      });
    }
  };

  const formatDate = (dateString: string | null) =>
    dateString ? new Date(dateString).toLocaleDateString('en-US') : '';

  const columns: GridColDef[] = [
    { field: 'riskCaseName', headerName: 'Risk Case Name', flex: 1 },
    {
      field: 'dateAdded',
      headerName: 'Date Created',
      flex: 1,
      valueGetter: (dateAdded: string) => formatDate(dateAdded),
    },
    {
      field: 'dateUpdated',
      headerName: 'Date Last Activity',
      flex: 1,
      valueGetter: (dateUpdated: string) => formatDate(dateUpdated),
    },
    { field: 'description', headerName: 'Description', flex: 1 },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 1,
      valueGetter: (owner: UserDto) => owner?.fullName,
    },
    {
      field: 'riskCaseSuppliers',
      headerName: 'Suppliers',
      flex: 1,
      valueGetter: (suppliers: string) => suppliers,
    },
    { field: 'probability', headerName: 'Probability', flex: 1 },
    { field: 'magnitude', headerName: 'Magnitude', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      flex: 0.1,
      minWidth: 170,
      renderCell: (params) => (
        <>
          <Button variant="text" color="primary" onClick={() => handleViewClick(params.row)}>
            View
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => handleArchiveConfirmation(params.row)}
          >
            Archive
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        rows={riskCases}
        columns={columns}
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
      />

      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={snackbar?.severity === 'error' ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
          {'Are you sure you want to archive this risk case?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleArchived} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RiskCasesGrid;
