import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';

interface ConfirmDeleteProps {
  deleteId: number | null;
  open: boolean;
  onClose: (open: boolean) => void;
  onDelete: (id: number) => void;
}

export default function ConfirmDeleteDialog({
  deleteId,
  open,
  onClose,
  onDelete,
}: ConfirmDeleteProps) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
        {'Are you sure you want to delete this record?'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => deleteId !== null && onDelete(deleteId)} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
