import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import { useDispatch, useSelector } from 'react-redux';
import { handleAuth } from './slices/authSlice';
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
import { ThemeProvider } from '@mui/material/styles';
import createCustomTheme from './theme';
import AdminSettingsApi from './api/adminSettings/adminSettingsApi';
import { setPrimaryColor } from './slices/themeSlice';

function App() {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);

  config({ licenseKey });

  useEffect(() => {
    dispatch(handleAuth());
  }, [dispatch]);

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (!user) {
    return <div>Redirecting to login...</div>;
  }

  return <Content />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();
  const primaryColor = useSelector((state) => state.theme.primaryColor);
  const customTheme = createCustomTheme(primaryColor);

  const dispatch = useDispatch();

  useEffect(() => {
    document.body.style.setProperty('--primary-color', primaryColor);
    document.body.style.setProperty('--secondary-color', customTheme.palette.secondary.main);
  }, [primaryColor, customTheme]);

  useEffect(() => {
    AdminSettingsApi.getPrimaryColor().then((response) => {
      dispatch(setPrimaryColor(response.hex));
    });
  }, []);

  return (
    <Router>
      <NavigationProvider>
        <ThemeProvider theme={customTheme}>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </ThemeProvider>
      </NavigationProvider>
    </Router>
  );
}
