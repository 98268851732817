// MISC. FUNCTIONS ================================================================================

/**
 * Determines whether an object is Undefined, Null, or WhiteSpace.
 * */
export function isNullOrWhitespace(input: any | null) {
  if (input === undefined || input === null) return true;

  if (typeof input === 'number') return false;

  return input.replace(/\s/g, '').length < 1;
}

/**
 * Determinies whether an object is Undefined or Null.
 * */
export function isNullOrUndefined(input: any | null) {
  return input === undefined || input === null;
}
