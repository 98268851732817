import React from 'react';
import { Typography } from '@mui/material';
import './index.scss';
import RiskMeasures from '../../components/risk-measures/RiskMeasures';

const RiskMeasuresAdmin = () => {
  return (
    <div className="admin">
      <Typography className="title">Risk Measures</Typography>
      <RiskMeasures />
    </div>
  );
};

export default RiskMeasuresAdmin;
