import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TreeView from 'devextreme-react/tree-view';
import './RiskModelsMenu.scss';
// @ts-ignore: Implicit any module
import KeywordModelAPI from '../../../api/keywordModel/keywordModelApi';
import { useNavigate } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, styled } from '@mui/material';
import {
  addKeywordModel,
  addKeywordModelsMenu,
  addSelectedKeywordModel,
} from '../../../slices/keywordModelSlice';
import { LoadIndicator } from 'devextreme-react';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.grey[700],
  },
  borderRadius: '50%',
  padding: '2px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  fontSize: '1rem',
  marginRight: '6px',
}));

interface TreeViewItem {
  id: number | string;
  text: string;
  expanded?: boolean;
  modelId?: number;
  items?: TreeViewItem[];
  nonSelectable?: boolean;
}

interface KeywordModels {
  id: number;
  description: string;
}

const RiskModelsMenu: React.FC = () => {
  const [items, setItems] = useState<TreeViewItem[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const keywordModelsMenu = useSelector((state: any) => state.keywordModel.keywordsModelsMenu);
  const selectedKwm = useSelector((state: any) => state.keywordModel.selectedKeywordModel);

  const fetchAllKKeywordsModels = async () => {
    try {
      const data = await KeywordModelAPI.getAllKeywordModel();
      dispatch(addKeywordModel({ allKeywordModel: data }));
    } catch (error) {
      console.error(error);
    }
  };

  const generateMenuItems = (keywordModels: KeywordModels[]): TreeViewItem[] => {
    const keywordModelItems = keywordModels
      .map((key: KeywordModels, index: number) => ({
        id: `item-${index}`,
        text: key.description,
        expanded: true,
        modelId: key.id,
        items: [],
      }))
      .sort((a, b) => a.text.localeCompare(b.text));

    const initialItems: TreeViewItem[] = [
      {
        id: 1,
        text: 'Keyword Models',
        expanded: true,
        modelId: 0,
        items: keywordModelItems,
      },
      {
        id: 2,
        text: 'Formulaic Models',
        expanded: true,
        items: [],
        modelId: 0,
      },
    ];

    return initialItems;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        if (keywordModelsMenu && keywordModelsMenu.length > 0) {
          const menuItems = generateMenuItems(keywordModelsMenu);
          setItems(menuItems);

          if (selectedKwm?.id) {
            navigate(`/scrm/risk-models/${selectedKwm.id}`);
          }
        } else {
          const response = await KeywordModelAPI.getRiskModelsMenuItems();
          dispatch(addKeywordModelsMenu({ keywordsModelsMenu: response.keywordModels }));

          if (response && Array.isArray(response.keywordModels)) {
            const menuItems = generateMenuItems(response.keywordModels);
            setItems(menuItems);

            if (selectedKwm?.id) {
              navigate(`/scrm/risk-models/${selectedKwm.id}`);
            }
          } else {
            console.error('keywordModels is not an array or is missing', response.keywordModels);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, selectedKwm, keywordModelsMenu]);

  useEffect(() => {
    fetchAllKKeywordsModels();
  }, []);

  const handleItemClick = (item: TreeViewItem) => {
    if (item.modelId) {
      navigate(`/scrm/risk-models/${item.modelId}`);
    } else {
      console.error('modelId is missing in itemData');
    }
  };
  const handleAddKeyword = () => {
    navigate('/scrm/risk-models/add-keyword-model');
  };

  const handleAddFormulaic = () => {
    navigate('/scrm/risk-models/add-formulaic-model');
  };

  const renderTreeViewItem = (
    item: TreeViewItem,
    onAddKeyword: () => void,
    onAddFormulaic: () => void,
    selectedKwm: string | number,
    onItemClick: (item: TreeViewItem) => void
  ) => {
    const itemClass =
      item.items && item.items.length > 0 ? 'tree-view-item title' : 'tree-view-item value';

    const selectedClass = item.modelId === selectedKwm ? 'selected' : '';

    return (
      <div
        className={`${itemClass} ${selectedClass}`}
        onClick={(e) => {
          if (item.modelId) {
            onItemClick(item);
          }
          e.stopPropagation();
        }}
      >
        {item.text}
        {item.id === 1 && (
          <StyledIconButton
            aria-label="add keyword"
            onClick={(e) => {
              e.stopPropagation();
              onAddKeyword();
            }}
            className="styledAddIcon"
          >
            <AddIcon className="icon" />
          </StyledIconButton>
        )}
        {item.id === 2 && (
          <StyledIconButton
            aria-label="add formulaic"
            onClick={(e) => {
              e.stopPropagation();
              onAddFormulaic();
            }}
            className="styledAddIcon"
          >
            <AddIcon className="icon" />
          </StyledIconButton>
        )}
      </div>
    );
  };

  return (
    <div id="treeViewContainer" className="tree-view-container">
      {isLoading ? (
        <div className="spinner">
          <LoadIndicator />
        </div>
      ) : (
        <TreeView
          items={items}
          width={300}
          noDataText=""
          itemRender={(item) =>
            renderTreeViewItem(
              item,
              handleAddKeyword,
              handleAddFormulaic,
              selectedKwm?.id || '',
              handleItemClick
            )
          }
          onItemClick={(e) => {
            const itemData = e.itemData as TreeViewItem | undefined;
            if (itemData && itemData.modelId) {
              handleItemClick(itemData);
            }
          }}
          selectByClick={true}
        />
      )}
    </div>
  );
};

export default RiskModelsMenu;
