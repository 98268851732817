// import React, { useEffect, useState, useRef } from 'react';
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   Tooltip,
//   IconButton,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   CircularProgress,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import HelpIcon from '@mui/icons-material/Help';
// import { Bar } from 'react-chartjs-2';
// import { Chart, registerables } from 'chart.js';
// import zoomPlugin from 'chartjs-plugin-zoom';
// import RiskCaseApi from '../../../../api/riskCase/riskCaseApi';
// import { RiskCaseDto, SupplierDto } from '../../../../api/types/types';
// import { SelectChangeEvent } from '@mui/material/Select';
// //@ts-ignore
// import SuppliersAPI from '../../../../api/suppliers/suppliersAPI';
// import './RiskCaseAging.scss';
// import PopupRiskCases from '../../../../components/risk-discovery/popup-risk-cases/PopupRiskCases';

// Chart.register(...registerables, zoomPlugin);

// const RiskCaseAging = () => {
//   const [allRiskCases, setAllRiskCases] = useState<RiskCaseDto[]>([]);
//   const [filteredRiskCases, setFilteredRiskCases] = useState<RiskCaseDto[]>([]);
//   const [selectedSupplierId, setSelectedSupplierId] = useState('');
//   const [supplierIds, setSupplierIds] = useState<number[]>([]);
//   const [allSuppliers, setAllSuppliers] = useState<SupplierDto[]>([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [selectedRiskCaseId, setSelectedRiskCaseId] = useState<number | null>(null);
//   const [isPopupVisible, setIsPopupVisible] = useState(false);

//   const chartRef = useRef(null);

//   const fetchAllRiskCases = async () => {
//     try {
//       const response = await RiskCaseApi.getAllRiskCase();
//       setAllRiskCases(response);
//       const uniqueSupplierIds = Array.from(
//         new Set<number>(
//           response.flatMap((riskCase: RiskCaseDto) => riskCase.suppliers.map((s) => s.supplierId))
//         )
//       );
//       setSupplierIds(uniqueSupplierIds);
//       setIsLoading(false);
//     } catch (error) {
//       console.error('Error fetching risk cases:', error);
//     }
//   };

//   const fetchAllSuppliers = async () => {
//     try {
//       const response = await SuppliersAPI.getAllSuppliers();
//       setAllSuppliers(response);
//       setIsLoading(false);
//     } catch (error) {
//       console.error('Error fetching suppliers:', error);
//     }
//   };

//   const handleSupplierChange = (event: SelectChangeEvent<string>) => {
//     setSelectedSupplierId(event.target.value);
//     if (event.target.value === '') {
//       setFilteredRiskCases(allRiskCases);
//     } else {
//       const filteredCases = allRiskCases.filter((riskCase) =>
//         riskCase.suppliers.some((s) => s.supplierId.toString() === event.target.value)
//       );
//       setFilteredRiskCases(filteredCases);
//     }
//   };

//   useEffect(() => {
//     setIsLoading(true);
//     fetchAllRiskCases();
//     fetchAllSuppliers();
//   }, []);

//   useEffect(() => {
//     if (selectedSupplierId === '') {
//       setFilteredRiskCases(allRiskCases);
//     } else {
//       const filteredCases = allRiskCases.filter((riskCase) =>
//         riskCase?.suppliers.some((s) => s.supplierId === Number(selectedSupplierId))
//       );
//       setFilteredRiskCases(filteredCases);
//     }
//   }, [selectedSupplierId, allRiskCases]);

//   const chartData = {
//     labels: filteredRiskCases.map((riskCase) => riskCase.riskCaseName),
//     datasets: [
//       {
//         label: 'Since Last Activity',
//         data: filteredRiskCases.map((riskCase) => {
//           const lastActivityDate = new Date(riskCase?.dateUpdated || '');
//           return Math.floor(
//             (new Date().getTime() - lastActivityDate.getTime()) / (1000 * 3600 * 24)
//           );
//         }),
//         backgroundColor: '#005B8C',
//         stack: 'stack1',
//       },
//       {
//         label: '',
//         data: filteredRiskCases.map((riskCase) => {
//           const dateCreated = riskCase?.dateAdded ? new Date(riskCase.dateAdded) : null;
//           const lastActivityDate = riskCase?.dateUpdated ? new Date(riskCase.dateUpdated) : null;

//           if (dateCreated && lastActivityDate) {
//             return Math.floor(
//               (new Date().getTime() - dateCreated.getTime()) / (1000 * 3600 * 24) -
//                 (new Date().getTime() - lastActivityDate.getTime()) / (1000 * 3600 * 24)
//             );
//           }
//           return 0;
//         }),
//         backgroundColor: '#83B9DB',
//         stack: 'stack1',
//       },
//     ],
//   };

//   const options = {
//     indexAxis: 'y' as const,
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       x: {
//         beginAtZero: true,
//         stacked: true,
//         grid: {
//           display: true,
//           drawBorder: true,
//           drawOnChartArea: false,
//           drawTicks: true,
//           tickMarkLength: 10,
//           color: '#666',
//           borderWidth: 2,
//         },
//         ticks: {
//           stepSize: 10,
//           color: '#666',
//           font: {
//             size: 18,
//           },
//         },
//         title: {
//           display: true,
//           text: 'Days',
//           font: {
//             size: 18,
//           },
//           color: '#666',
//           padding: { top: 5, left: 0, right: 0, bottom: 0 },
//         },
//         min: 0,
//       },
//       y: {
//         beginAtZero: true,
//         stacked: true,
//         grid: {
//           display: false,
//           drawOnChartArea: false,
//           borderWidth: 2,
//           color: '#666',
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         display: true,
//         position: 'top' as const,
//         labels: {
//           color: '#005B8C',
//           font: {
//             size: 14,
//             weight: 'bold' as const,
//           },
//           filter: function (legendItem: any, data: any) {
//             return legendItem.datasetIndex === 0;
//           },
//         },
//         onClick: () => {},
//       },
//       tooltip: {
//         enabled: true,
//         callbacks: {
//           label: function (context: any) {
//             const datasets = context.chart.data.datasets;
//             if (context.datasetIndex === 0) {
//               return `${datasets[0].label}: ${context.raw} Days`;
//             } else {
//               const sum = datasets.reduce((acc: number, dataset: any) => {
//                 return acc + dataset.data[context.dataIndex];
//               }, 0);

//               return `Since Date Created: ${sum} Days`;
//             }
//           },
//         },
//       },
//       zoom: {
//         pan: {
//           enabled: true,
//           mode: 'y' as const,
//         },
//         zoom: {
//           wheel: {
//             enabled: true,
//           },
//           pinch: {
//             enabled: true,
//           },
//           mode: 'y' as const,
//         },
//       },
//     },
//     barThickness: 20,
//     onClick: (event: any, elements: any) => {
//       if (elements.length > 0) {
//         const elementIndex = elements[0].index;
//         const riskCaseId = filteredRiskCases[elementIndex].id;
//         handleRiskCaseSelect(riskCaseId);
//       }
//     },
//   };

//   const handleRiskCaseSelect = (riskCaseId: number) => {
//     setSelectedRiskCaseId(riskCaseId);
//     setIsPopupVisible(true);
//   };

//   return (
//     <Accordion defaultExpanded>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography variant="h6" pr={1}>
//           Risk Case Aging
//         </Typography>
//         <Tooltip
//           title="Total bar length represents the age of each Risk Case. The darker element in each
//             stacked bar represents the number of days since last activity on the Risk Case."
//         >
//           <IconButton size="small" style={{ color: '#005B8C' }}>
//             <HelpIcon fontSize="inherit" />
//           </IconButton>
//         </Tooltip>
//       </AccordionSummary>

//       <AccordionDetails>
//         {isLoading ? (
//           <div
//             style={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               height: '100%',
//             }}
//           >
//             <CircularProgress />
//           </div>
//         ) : (
//           <div
//             style={{
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'space-between',
//               width: '100%',
//             }}
//           >
//             <div style={{ height: 400, width: '70%' }}>
//               <Bar ref={chartRef} data={chartData} options={options} />
//             </div>
//             <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
//               <InputLabel id="supplier-select-label">Supplier Weight</InputLabel>
//               <Select
//                 labelId="supplier-select-label"
//                 id="supplier-select"
//                 value={selectedSupplierId}
//                 onChange={handleSupplierChange}
//                 label="Supplier Weight"
//               >
//                 <MenuItem value="">All Suppliers</MenuItem>
//                 {supplierIds.map((supplierId) => {
//                   const supplier = allSuppliers.find((s) => s.id === supplierId);
//                   return (
//                     <MenuItem key={supplierId} value={supplierId}>
//                       {supplier ? supplier.supplierName : supplierId}
//                     </MenuItem>
//                   );
//                 })}
//               </Select>
//             </FormControl>
//           </div>
//         )}
//       </AccordionDetails>
//       <PopupRiskCases
//         popupRiskCasesVisible={isPopupVisible}
//         handleRiskCasesClose={() => setIsPopupVisible(false)}
//         selectedRiskCaseId={selectedRiskCaseId || undefined}
//       />
//     </Accordion>
//   );
// };

// export default RiskCaseAging;

import React, { useEffect, useState, useRef } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import RiskCaseApi from '../../../../api/riskCase/riskCaseApi';
import { RiskCaseDto, RiskCaseViewModelDto, SupplierDto } from '../../../../api/types/types';
import { SelectChangeEvent } from '@mui/material/Select';
//@ts-ignore
import SuppliersAPI from '../../../../api/suppliers/suppliersAPI';
import './RiskCaseAging.scss';
import PopupRiskCases from '../../../../components/risk-discovery/popup-risk-cases/PopupRiskCases';
import { addRiskCases } from '../../../../slices/riskCasesSlice';
import { useSelector } from 'react-redux';

Chart.register(...registerables, zoomPlugin);

const RiskCaseAging = () => {
  const [allRiskCases, setAllRiskCases] = useState<RiskCaseDto[]>([]);
  const [filteredRiskCases, setFilteredRiskCases] = useState<RiskCaseDto[]>([]);
  const [selectedSupplierId, setSelectedSupplierId] = useState('');
  const [supplierIds, setSupplierIds] = useState<number[]>([]);
  const [allSuppliers, setAllSuppliers] = useState<SupplierDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRiskCaseId, setSelectedRiskCaseId] = useState<number | null>(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const allRiskCaseVm = useSelector((state: any) => state.riskCases?.allRiskCasesVm);

  const chartRef = useRef(null);

  const fetchAllRiskCases = async () => {
    try {
      const response = await RiskCaseApi.getAllRiskCase();
      setAllRiskCases(response);
      const uniqueSupplierIds = Array.from(
        new Set<number>(
          response.flatMap((riskCase: RiskCaseDto) => riskCase.suppliers.map((s) => s.supplierId))
        )
      );
      setSupplierIds(uniqueSupplierIds);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching risk cases:', error);
    }
  };

  const fetchAllSuppliers = async () => {
    try {
      const response = await SuppliersAPI.getAllSuppliers();
      setAllSuppliers(response);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
  };

  const handleSupplierChange = (event: SelectChangeEvent<string>) => {
    setSelectedSupplierId(event.target.value);
    if (event.target.value === '') {
      setFilteredRiskCases(allRiskCases);
    } else {
      const filteredCases = allRiskCases.filter((riskCase) =>
        riskCase.suppliers.some((s) => s.supplierId.toString() === event.target.value)
      );
      setFilteredRiskCases(filteredCases);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAllRiskCases();
    fetchAllSuppliers();
  }, []);

  useEffect(() => {
    if (selectedSupplierId === '') {
      setFilteredRiskCases(allRiskCases);
    } else {
      const filteredCases = allRiskCases.filter((riskCase) =>
        riskCase?.suppliers.some((s) => s.supplierId === Number(selectedSupplierId))
      );
      setFilteredRiskCases(filteredCases);
    }
  }, [selectedSupplierId, allRiskCases]);

  const chartData = {
    labels: filteredRiskCases.map((riskCase) => riskCase.riskCaseName),
    datasets: [
      {
        label: 'Since Last Activity',
        data: filteredRiskCases.map((riskCase) => {
          if (!riskCase?.dateUpdated) {
            return 0;
          }
          const lastActivityDate = new Date(riskCase.dateUpdated);
          return Math.floor(
            Math.max((new Date().getTime() - lastActivityDate.getTime()) / (1000 * 3600 * 24), 0)
          );
        }),
        backgroundColor: '#005B8C',
        stack: 'stack1',
      },
      {
        label: '',
        data: filteredRiskCases.map((riskCase) => {
          const dateCreated = riskCase?.dateAdded ? new Date(riskCase.dateAdded) : null;
          const lastActivityDate = riskCase?.dateUpdated ? new Date(riskCase.dateUpdated) : null;

          if (dateCreated && lastActivityDate) {
            return Math.floor(
              (new Date().getTime() - dateCreated.getTime()) / (1000 * 3600 * 24) -
                (new Date().getTime() - lastActivityDate.getTime()) / (1000 * 3600 * 24)
            );
          }
          return 0;
        }),
        backgroundColor: '#83B9DB',
        stack: 'stack1',
      },
    ],
  };

  const options = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: true,
          tickMarkLength: 10,
          color: '#666',
          borderWidth: 2,
        },
        ticks: {
          stepSize: 10,
          color: '#666',
          font: {
            size: 18,
          },
        },
        title: {
          display: true,
          text: 'Days',
          font: {
            size: 18,
          },
          color: '#666',
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
        min: 0,
      },
      y: {
        beginAtZero: true,
        stacked: true,
        grid: {
          display: false,
          drawOnChartArea: false,
          borderWidth: 2,
          color: '#666',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
        labels: {
          color: '#005B8C',
          font: {
            size: 14,
            weight: 'bold' as const,
          },
          filter: function (legendItem: any, data: any) {
            return legendItem.datasetIndex === 0;
          },
        },
        onClick: () => {},
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            const datasets = context.chart.data.datasets;
            if (context.datasetIndex === 0) {
              return `${datasets[0].label}: ${context.raw} Days`;
            } else {
              const sum = datasets.reduce((acc: number, dataset: any) => {
                return acc + dataset.data[context.dataIndex];
              }, 0);

              return `Since Date Created: ${sum} Days`;
            }
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'y' as const,
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'y' as const,
        },
      },
    },
    barThickness: 20,
    onClick: (event: any, elements: any) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const riskCaseId = filteredRiskCases[elementIndex].id;
        handleRiskCaseSelect(riskCaseId);
      }
    },
  };

  const handleRiskCaseSelect = (riskCaseId: number) => {
    setSelectedRiskCaseId(riskCaseId);
    setIsPopupVisible(true);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" pr={1}>
          Risk Case Aging
        </Typography>
        <Tooltip
          title="Total bar length represents the age of each Risk Case. The darker element in each
            stacked bar represents the number of days since last activity on the Risk Case."
        >
          <IconButton size="small" style={{ color: '#005B8C' }}>
            <HelpIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </AccordionSummary>

      <AccordionDetails>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div style={{ height: 400, width: '70%' }}>
              <Bar ref={chartRef} data={chartData} options={options} />
            </div>
            <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
              <InputLabel id="supplier-select-label">Supplier Weight</InputLabel>
              <Select
                labelId="supplier-select-label"
                id="supplier-select"
                value={selectedSupplierId}
                onChange={handleSupplierChange}
                label="Supplier Weight"
              >
                <MenuItem value="">All Suppliers</MenuItem>
                {supplierIds.map((supplierId) => {
                  const supplier = allSuppliers.find((s) => s.id === supplierId);
                  return (
                    <MenuItem key={supplierId} value={supplierId}>
                      {supplier ? supplier.supplierName : supplierId}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}
      </AccordionDetails>
      <PopupRiskCases
        popupRiskCasesVisible={isPopupVisible}
        handleRiskCasesClose={() => setIsPopupVisible(false)}
        selectedRiskCaseId={selectedRiskCaseId || undefined}
        riskCases={
          selectedRiskCaseId
            ? allRiskCaseVm.filter(
                (rc: RiskCaseViewModelDto) =>
                  rc.generalInformation.riskCaseId === selectedRiskCaseId
              )
            : []
        }
      />
    </Accordion>
  );
};

export default RiskCaseAging;
