// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, WatchedPartCaseLogEntryFileDto } from '../types/types';
const { DMSMS_SERVICE_URL } = config;

const WatchedPartCaseLogFile = {
  /**
   * Retrieves all case log entries.
   * @returns
   */
  getWatchedPartCaseLogEntryFiles: async function (): Promise<
    ResponseDto<WatchedPartCaseLogEntryFileDto[]>
  > {
    var url = `${DMSMS_SERVICE_URL}/WatchedPartCaseLogEntryFile`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<WatchedPartCaseLogEntryFileDto[]>;
  },

  /**
   * Retrieves a case log.
   * @returns
   */
  getWatchedPartCaseLogEntryFile: async function (
    watchedPartEntryFileId: number
  ): Promise<ResponseDto<WatchedPartCaseLogEntryFileDto>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPartCaseLogEntryFile/${watchedPartEntryFileId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<WatchedPartCaseLogEntryFileDto>;
  },

  /**
   * Creates a case log
   * @param part
   */
  createWatchedPartCaseLogEntryFile: async function (
    part: WatchedPartCaseLogEntryFileDto
  ): Promise<ResponseDto<WatchedPartCaseLogEntryFileDto>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPartCaseLogEntryFile`;
    const { data } = await httpClient.post(url, part);
    return data as ResponseDto<WatchedPartCaseLogEntryFileDto>;
  },

  /**
   * Deletes a case log
   * @param part
   */
  deleteWatchedPartCaseLogEntryFile: async function (
    watchedPartCaseLogEntryFileId: number
  ): Promise<ResponseDto<any>> {
    var url = `${DMSMS_SERVICE_URL}/WatchedPartCaseLogEntryFile/${watchedPartCaseLogEntryFileId}`;
    const { data } = await httpClient.delete(url);
    return data as ResponseDto<any>;
  },
};

export default WatchedPartCaseLogFile;
