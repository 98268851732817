import React from 'react';
import { TextField, FormControl, InputLabel, Box } from '@mui/material';
import { labelStyles, inputProps_textField } from '../styles';
import HelpIcon from '../help/HelpIcon.jsx';

export default function AltruityTextField({
  label,
  width,
  onChange,
  helpConent,
  inputRef,
  ...props
}) {
  return (
    <FormControl className="altruity-field" style={{ width: width }}>
      <Box display="flex">
        <TextField {...props} onChange={onChange} inputRef={inputRef} label={label} />
      </Box>
      {helpConent ? <HelpIcon label={label}>{helpConent}</HelpIcon> : null}
    </FormControl>
  );
}
