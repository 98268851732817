// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, RiskCaseLogEntryDto } from '../types/types';
const { RISK_CASE_SERVICE_URL } = config;

const RiskCaseLogEntry = {
  /**
   * Retrieve all risk case log entries for a part.
   * @returns
   */
  getRiskCaseLogEntries: async function (): Promise<ResponseDto<RiskCaseLogEntryDto[]>> {
    var url = `${RISK_CASE_SERVICE_URL}/RiskCaseLogEntry`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<RiskCaseLogEntryDto[]>;
  },
};

export default RiskCaseLogEntry;
