import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { CreateRiskMeasureDto, ThemeColorDto } from '../../../api/types/types';
import ColorThemePicker from './ColorThemePicker';
import { AdditionalRiskMeasuresData } from './AdditionalRiskMeasuresData';

interface RiskMeasureCreationDialogProps {
  additionalData: AdditionalRiskMeasuresData;
  open: boolean;
  handleClose: () => void;
  handleSubmit: (form: any) => void;
}

export default function RiskMeasureCreationDialog({
  additionalData,
  open,
  handleSubmit,
  handleClose,
}: RiskMeasureCreationDialogProps) {
  const defaultRiskMeasure: CreateRiskMeasureDto = {
    riskMeasureName: '',
    description: null,
    themeColorId: null,
    isRequired: false,
    levelLabels: null,
  };

  const [form, setForm] = useState<CreateRiskMeasureDto>({
    ...defaultRiskMeasure,
  });

  const availableThemeColors: ThemeColorDto[] = additionalData.availableColorThemes;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setForm((prevForm: any) => ({
      ...prevForm,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleThemeChange = (selectedItemId: number | null) => {
    setForm((prevForm: any) => ({
      ...prevForm,
      themeColorId: selectedItemId,
    }));
  };

  const onClose = () => {
    setForm({
      ...defaultRiskMeasure,
    });
    handleClose();
  };

  return (
    <Dialog className="supplier-popup" open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Risk Measure</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Risk Measure Name"
          type="text"
          name="riskMeasureName"
          variant="standard"
          value={form.riskMeasureName}
          fullWidth
          onChange={handleChange}
        />
        <FormControlLabel
          control={<Switch name="isRequired" checked={form.isRequired} onChange={handleChange} />}
          label="Is Required?"
        />
        <Box sx={{ mt: 2 }}>
          <ColorThemePicker
            colorThemes={availableThemeColors}
            selectedThemeId={form.themeColorId}
            isDisabled={false}
            onChange={handleThemeChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleSubmit({ ...form });
            onClose();
          }}
          variant="contained"
          color="primary"
        >
          Create Risk Measure
        </Button>
        <Button onClick={onClose} type="submit" variant="outlined" color="inherit">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
