import React from 'react';
import { Typography } from '@mui/material';
import './CurrentRiskCases.scss';
import RiskCasesGrid from '../../components/scrm/risk-cases/current/RiskCasesGrid';
import CreateRiskCaseContainer from '../../components/risk-case/create-risk-case-container/CreateRiskCaseContainer';

const CurrentRiskCases = () => {
  return (
    <div className="current-risk-cases">
      <Typography className="title">Current Risk Cases</Typography>
      <CreateRiskCaseContainer></CreateRiskCaseContainer>
      <RiskCasesGrid />
    </div>
  );
};

export default CurrentRiskCases;
