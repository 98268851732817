import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { WatchedPartDto } from '../../../../api/types/types';
import DmsmsApi from '../../../../api/dmsms/dmsmsAPI';
import { useDispatch } from 'react-redux';
import { setSelectedLifeStage } from '../../../../slices/dmsmsSlice';
import './DmsmsLifeStage.scss';
import MetricCard from '../MetricCard';
import { Box } from '@mui/material';

const DmsmsLifeStage = ({ parts }: { parts: WatchedPartDto[] }) => {
  const stageCounts = parts.reduce(
    (acc, part) => {
      if (part.stageOfLife) {
        acc[part.stageOfLife.toLowerCase()] = (acc[part.stageOfLife.toLowerCase()] || 0) + 1;
      }
      return acc;
    },
    {} as Record<string, number>
  );

  const chartData = [
    { name: 'Early', value: stageCounts['early'] || 0 },
    { name: 'Middle', value: stageCounts['middle'] || 0 },
    { name: 'Late', value: stageCounts['late'] || 0 },
  ];

  const COLORS = ['#6FCF97 ', '#FFDD75 ', '#FF6B6B '];

  const handlePieClick = (data: any, event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    localStorage.setItem('lifeStage', data.name.toUpperCase());
    localStorage.setItem('selectedSupplier', '');
    window.open('/dmsms/parts?lifeStage=' + data.name, '_blank');
  };
  const removeFocus = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const renderCustomLabel = ({ cx, cy, midAngle, outerRadius, percent, name }: any) => {
    const RADIAN = Math.PI / 180;
    const x = cx + (outerRadius + 20) * Math.cos(-midAngle * RADIAN);
    const y = cy + (outerRadius + 30) * Math.sin(-midAngle * RADIAN);

    return percent > 0 ? (
      <text
        x={x}
        y={y}
        fill="#333"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: '13px', fontWeight: '500' }}
      >
        {name}
      </text>
    ) : null;
  };

  return (
    <div className="life-stage-container" style={{ border: 'none', padding: 0 }}>
      <MetricCard title="Life Stage" lastMonthText="" lastMonthValue={0} isPositive={null}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <PieChart
            width={450}
            height={300}
            style={{ outline: 'none', cursor: 'pointer', marginTop: '30px' }}
          >
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={true}
              outerRadius={80}
              dataKey="value"
              onClick={(data, index, event) => handlePieClick(data, event)}
              style={{ cursor: 'pointer' }}
              label={renderCustomLabel}
              tabIndex={-1}
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  style={{ cursor: 'pointer' }}
                  onMouseDown={removeFocus}
                />
              ))}
            </Pie>
            <Tooltip
              content={({ active, payload }) =>
                active && payload && payload.length ? (
                  <div
                    style={{
                      backgroundColor: '#f5f5f5',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      fontSize: '16px',
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor:
                          COLORS[chartData.findIndex((entry) => entry.name === payload[0].name)],
                        marginRight: '8px',
                      }}
                    />
                    <div>{payload[0].name}</div>: {payload[0].value}
                  </div>
                ) : null
              }
            />
          </PieChart>
        </Box>
      </MetricCard>
    </div>
  );
};

export default DmsmsLifeStage;
