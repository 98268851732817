import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import SuppliersAPI from '../../../../api/suppliers/suppliersAPI';
import { createColumn } from '../../../../utils/data-grid';
import DataGrid from '../../../data-grid/DataGrid';

const Industries = () => {
  const { id: supplierId } = useParams();
  const [data, setData] = useState();

  const columns = [createColumn('industryName', 'Name', null, true)];

  const loadData = async () => setData(await SuppliersAPI.getAllSupplierIndustries(supplierId));

  const createData = async (data) =>
    await SuppliersAPI.addSupplierIndustry({ supplierId, ...data });

  const deleteData = async (id) => await SuppliersAPI.deleteSupplierIndustry(id);

  const updateData = async (id, data) =>
    await SuppliersAPI.updateSupplierIndustry({ id, supplierId, ...data });

  return (
    <DataGrid
      data={data}
      columns={columns}
      loadData={loadData}
      createData={createData}
      deleteData={deleteData}
      updateData={updateData}
    />
  );
};

export default Industries;
