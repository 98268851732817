import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  LinearProgress,
  Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NsnApi from '../../api/nsn/nsnAPI';
import { NsnCageDetailDto } from '../../api/types/types';
import './CageDetailsModal.scss';
import { useSelector } from 'react-redux';
import { isNullOrWhitespace } from '../../utils/validation-helpers';

interface CageDetailsProps {
  open: boolean;
  cage: string;
  onClose: any;
}

const initialContent: NsnCageDetailDto | null = null;

export default function CageDetailsModal({ open, cage, onClose }: CageDetailsProps) {
  const [modalState, setModalState] = useState({
    title: 'Cage Info: ' + cage,
    isLoading: true,
    content: initialContent,
  });

  const allSuppliers = useSelector((state: any) => state.suppliers?.allSuppliers);
  useEffect(() => {
    async function fetchData() {
      if (isNullOrWhitespace(cage)) return;

      setModalState((oldState) => ({
        ...oldState,
        isLoading: true,
      }));
      const response = await NsnApi.getCageDetails(cage);

      try {
        if (response.isSuccess) {
          setModalState((oldState) => ({
            ...oldState,
            isLoading: false,
            title: 'Cage Info: ' + cage,
            content: response.result,
          }));
        } else {
          setModalState((oldState) => ({
            ...oldState,
            isLoading: false,
          }));
        }
      } catch {
        setModalState((oldState) => ({
          ...oldState,
          isLoading: false,
        }));
      }
    }

    fetchData();
  }, [cage]);

  const supplier = allSuppliers?.find((s: any) => s.cageCode === modalState.content?.cage_cd);
  return createPortal(
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
        }}
        id="dialog-title"
        style={{
          backgroundColor: '#306EB6',
          fontSize: '20px',
          fontWeight: 'bold',
          color: 'white',
          paddingRight: '60px',
        }}
      >
        {modalState.title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 10,
          color: 'white',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {modalState.isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
            <LinearProgress sx={{ width: '100%' }} />
          </Box>
        ) : (
          <DialogContentText id="dialog-description">
            {modalState.content && (
              <Box className="cage-detail-container">
                <div>Cage: </div>
                {/* <div>{modalState.content.cage_cd}</div> */}
                <div>
                  {supplier ? (
                    <Link href={`/scrm/suppliers/${supplier.id}`} target="_blank">
                      {modalState.content.cage_cd}
                    </Link>
                  ) : (
                    modalState.content.cage_cd
                  )}
                </div>
                <div>Business Name:</div>
                <div>
                  {!isNullOrWhitespace(modalState.content.name_line_2) ? (
                    <>
                      <span>{modalState.content.name_line_1}</span>
                      <br />
                      <span>{modalState.content.name_line_2}</span>
                    </>
                  ) : (
                    <span>{modalState.content.name_line_1}</span>
                  )}
                </div>
                <div>FLIS Status:</div>
                <div>{modalState.content.status_cd + ' - ' + modalState.content.statusdesc}</div>
                <div>Org Type:</div>
                <div>{modalState.content.orgtype}</div>
                <div>SAM Status:</div>
                <div>{modalState.content.sam_status}</div>
                <div>Sam Init Reg Date:</div>
                <div>{modalState.content.initial_registration_date}</div>
                <div>Sam Activation Date:</div>
                <div>{modalState.content.activation_date}</div>
                <div>Sam Expiration Date:</div>
                <div>{modalState.content.expiration_date}</div>
                <div>Address:</div>
                <div>
                  <span>{modalState.content.street_line_1}</span>
                  <br />
                  {!isNullOrWhitespace(modalState.content.street_line_2) ? (
                    <>
                      <span>{modalState.content.street_line_2}</span>
                      <br />
                    </>
                  ) : null}
                  <span>{modalState.content.city}</span>
                  <br />
                  <span>{modalState.content.st_us_posn}</span>
                </div>
                <div>P.O. Box:</div>
                <div>{modalState.content.po_box}</div>
                <div>Country:</div>
                <div>{modalState.content.country}</div>
                <div>Phone:</div>
                <div>{modalState.content.telephone}</div>
                <div>Entity Type:</div>
                <div>{modalState.content.type_cd + ' - ' + modalState.content.typedesc} </div>
                <div>Primary Business Code:</div>
                <div>
                  {modalState.content.prim_bus_cd + '-' + modalState.content.primbuscddesc}{' '}
                </div>
                <div>Business Type:</div>
                <div>
                  {modalState.content.type_bus_cd + '-' + modalState.content.typebuscddesc}{' '}
                </div>
                <div>Business Size:</div>
                <div>{modalState.content.size_cd}</div>
                <div>Woman Owned:</div>
                <div>{modalState.content.woman_owned_cd}</div>
                <div>Associated Cage:</div>
                <div>{modalState.content.assoc_cd}</div>
              </Box>
            )}
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>,
    document.getElementById('app-modal')!
  );
}
