// src/components/Site-Admin/UsersTable.tsx
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Box,
  Typography,
  Select,
  MenuItem,
  IconButton,
  Button,
  AlertColor,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import UserAccessControl from './access-control/UserAccessControl';
import CustomSnackbar from '../../custom-snackbar';
import formatDate from '../../../utils/format-date';
import UserAdminAPI from '../../../api/userAdmin/userAdminAPI';
import { UsersAdminDto } from '../../../api/types/custom-types';
import { useSelector } from 'react-redux';

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  fullName: string;
  isActive: boolean;
  dateCreated: string;
  dateLastActivity: string | null;
  dateLastUpdated: string | null;
  dateDeleted: string | null;
  createdById: number | null;
  updatedById: number | null;
  deletedById: number | null;
  isSuperAdmin: boolean;
  roleIds: number[] | null;
}

const UsersTable = () => {
  const [users, setUsers] = useState<UsersAdminDto[]>([]);
  const [open, setOpen] = useState(false);
  const [usersNotInSystem, setUsersNotInSystem] = useState<UsersAdminDto[]>([]);
  const [openUserId, setOpenUserId] = useState<number | null>(null);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [newUser, setNewUser] = useState<UsersAdminDto | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [isLoading, setIsLoading] = useState(false);
  const [loggedInUserIsSuperAdmin, setLoggedInUserIsSuperAdmin] = useState(false);

  const userLoggedIn = useSelector((state: any) => state.auth.user);

  useEffect(() => {
    if (userLoggedIn && users.length > 0) {
      const loggedInUser = users.find((user) => user.email === userLoggedIn);
      if (loggedInUser) {
        setLoggedInUserIsSuperAdmin(loggedInUser.isSuperAdmin);
      }
    }
  }, [userLoggedIn, users]);

  const handleNewUserChange = (email: string) => {
    const selectedUser =
      usersNotInSystem.find((usersNotInSystem) => usersNotInSystem.email === email) || null;
    setNewUser(selectedUser);
  };

  function handleChange(value: string) {
    // Handle the change
  }
  const handleClick = (id: number) => {
    setOpenUserId((prevId) => (prevId === id ? null : id));
  };

  const handleDeactivate = async (id: number, user: any) => {
    const body = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      isActive: !user.isActive,
      createdById: user.createdById,
      updatedById: user.updatedById,
    };

    try {
      const response = await UserAdminAPI.putUserById(id, body);
      setUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.id === id) {
            const newIsActive = !user.isActive;
            setSnackbarMessage(`User ${newIsActive ? 'activated' : 'deactivated'} successfully`);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            return { ...user, isActive: newIsActive };
          }
          return user;
        })
      );
    } catch (error) {}
  };

  const handleSaveNewUser = async () => {
    const addUser = {
      id: newUser?.id ?? 0,
      firstName: newUser?.firstName ?? '',
      lastName: newUser?.lastName ?? '',
      email: newUser?.email ?? '',
      isActive: newUser?.isActive ?? false,
      createdById: newUser?.createdById ?? 0,
      updatedById: newUser?.updatedById ?? 0,
    };
    try {
      const response = await UserAdminAPI.addUser(addUser);

      if (newUser) {
        setUsers((prevUsers) => [...prevUsers, newUser]);
        setIsAddingUser(false);
        setNewUser(null);
        setSnackbarMessage('User added successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {}
  };

  const handleAddUser = async () => {
    setIsAddingUser(true);
    setNewUser(null);
  };

  const handleCancelAddUser = () => {
    setIsAddingUser(false);
    setNewUser(null);
  };

  const fetchUsers = async () => {
    try {
      const response = await UserAdminAPI.getAllUsers();
      setUsers(response);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchUsersNotInSystem = async () => {
    try {
      const response = await UserAdminAPI.getUserNotInSystem();
      setUsersNotInSystem(response);
    } catch (error) {
      console.error('Error fetching users not in system:', error);
    }
  };
  useEffect(() => {
    fetchUsersNotInSystem();
  }, []);
  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
  }, [newUser]);

  return (
    <TableContainer component={Paper}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={200}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUser}
            style={{ margin: '10px' }}
          >
            Add User
          </Button>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Date Added</TableCell>
                <TableCell align="center">Last Activity</TableCell>
                <TableCell align="center">Active?</TableCell>
                <TableCell align="center">Deactivated in AD?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isAddingUser && (
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Select
                      size="small"
                      value={newUser?.email || ''}
                      onChange={(e) => handleNewUserChange(e.target.value as string)}
                      fullWidth
                    >
                      <MenuItem value="">Select...</MenuItem>
                      {usersNotInSystem && usersNotInSystem.length > 0 ? (
                        usersNotInSystem.map((user: UsersAdminDto) => (
                          <MenuItem key={user.id} value={user.email}>
                            {user.fullName} ({user.email})
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">No users available</MenuItem>
                      )}
                    </Select>
                  </TableCell>
                  <TableCell align="right">
                    {formatDate(newUser?.dateCreated || undefined) ?? 'N/A'}
                  </TableCell>
                  <TableCell align="right">{newUser?.dateLastActivity ?? 'N/A'}</TableCell>
                  <TableCell align="right">{newUser?.isActive ? 'Yes' : 'No'}</TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={handleSaveNewUser}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!newUser}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={handleCancelAddUser}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginLeft: '10px' }}
                    >
                      Cancel
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {users.map((user) => (
                <React.Fragment key={user.id}>
                  <TableRow>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleClick(user.id)}
                      >
                        {openUserId === user.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {user.fullName} ({user.email})
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(user?.dateCreated || undefined)}
                    </TableCell>
                    <TableCell align="center">
                      {user.dateLastActivity ? formatDate(user.dateLastActivity) : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox checked={user.isActive} disabled color="primary" />
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => handleDeactivate(user.id, user)}
                        variant="contained"
                        color={user.isActive ? 'error' : 'primary'}
                        size="small"
                      >
                        {user.isActive ? 'Deactivate' : 'Activate'}
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={openUserId === user.id} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Typography variant="h6" gutterBottom component="div">
                            Access for {user.fullName}
                          </Typography>

                          <UserAccessControl
                            userId={user.id}
                            superAdmin={loggedInUserIsSuperAdmin}
                          />
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          <CustomSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={() => setSnackbarOpen(false)}
          />
        </>
      )}
    </TableContainer>
  );
};

export default UsersTable;
