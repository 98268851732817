import { Box, FormControlLabel, Checkbox } from '@mui/material';
import HelpIcon from '../help/HelpIcon.jsx';

export default function AltrutyCheckbox({
  label,
  name,
  onChange,
  value,
  defaultChecked,
  helpConent,
  ...props
}) {
  return (
    <Box display="flex" className="checkbox-container">
      <FormControlLabel
        {...props}
        checked={defaultChecked ?? false}
        control={<Checkbox name={name} value={value ?? true} onChange={onChange} />}
        label={label}
        style={{
          width: 'fit-content',
        }}
      />
      {helpConent ? <HelpIcon label={label}>{helpConent}</HelpIcon> : null}
    </Box>
  );
}
