import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import PowerLogJ2Settings from '../../components/dmsms/admin-settings/PowerLogJ2Settings';
import GidepSettings from '../../components/dmsms/admin-settings/GidepSettings';
import ActionTypes from '../../components/dmsms/admin-settings/ActionTypes';
import PermissionsApi from '../../api/permissions/permissionsAPI';
import { Role, User } from '../../slices/permissionsSlice';
import DmsmsApi from '../../api/dmsms/dmsmsAPI';
import { DmsmsAdminSettingsDto } from '../../api/types/types';
import { isNullOrUndefined, isNullOrWhitespace } from '../../utils/validation-helpers';

export default function DmsmsAdminSettingsPage() {
  const [formData, setFormData] = useState<DmsmsAdminSettingsDto>({
    smrOption: null,
    smrValues: [],
    essentialityOption: null,
    essentialityValues: [],
    fscnOption: null,
    fscnValues: [],
    lruOption: null,
    frequencyOfPlj2Loads: 0,
    initiateGidepScanOnLoadedParts: true,
    automaticallyRemoveParts: true,
    usersToNotify: [],
    rolesToNotify: [],
    frequencyOfGidepScanNonTaggedParts: 0,
    frequencyOfGidepScanTaggedParts: 0,
    runGidepAfterPowerLog: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);

  const fetchUsersAndRoles = async () => {
    try {
      const rolesData = await PermissionsApi.getAllRoles(true);
      const usersData = await PermissionsApi.getAllUsers(true);
      if (usersData.isSuccess) {
        const users: User[] = usersData.result.map((u) => ({
          id: u.id,
          fullName: u.fullName,
        }));
        setUsers(users);
      }

      if (rolesData.isSuccess) {
        const roles: Role[] = rolesData.result.map((r) => ({
          id: r.id,
          name: r.roleName,
        }));
        setRoles(roles);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const fetchSettings = async () => {
    setIsLoading(true);
    try {
      const response = await DmsmsApi.getSettings();
      if (response.isSuccess) {
        setFormData({
          ...response.result,
        });
      }
    } catch (error) {
      console.error('error', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsersAndRoles();
    fetchSettings();
  }, []);

  /**
   * Updates form data.
   * @param field
   * @param e
   */
  function handleFieldChange(field: string, e: any) {
    setFormData((prevData) => {
      var newData = { ...prevData };

      switch (field) {
        case 'smrOption':
          newData.smrOption = e;
          break;
        case 'smrValues':
          {
            const { value } = e.target;
            if (validateSmrCode(value)) {
              const newValue = value.toUpperCase();
              if (newData.smrValues.indexOf(newValue) < 0) {
                newData.smrValues.push(newValue);
              }
            }
          }
          break;
        case 'essentialityOption':
          newData.essentialityOption = e;
          break;
        case 'essentialityValues':
          {
            const { value } = e.target;
            if (validateEssentialityCode(value)) {
              const newValue = value.toUpperCase();
              if (newData.essentialityValues.indexOf(newValue) < 0) {
                newData.essentialityValues.push(newValue);
              }
            }
          }
          break;
        case 'fscnOption':
          newData.fscnOption = e;
          break;
        case 'fscnValues':
          {
            const { value } = e.target;
            if (validateFscnCode(value)) {
              const newValue = value.toUpperCase();
              if (newData.fscnValues.indexOf(newValue) < 0) {
                newData.fscnValues.push(newValue);
              }
            }
          }
          break;
        case 'lruOption':
          newData.lruOption = e;
          break;
        case 'frequencyOfPlj2Loads':
          {
            const { value } = e.target;
            newData.frequencyOfPlj2Loads = value;
          }
          break;
        case 'initiateGidepScanOnLoadedParts':
          newData.initiateGidepScanOnLoadedParts = e;
          break;
        case 'automaticallyRemoveParts':
          newData.automaticallyRemoveParts = e;
          break;
        case 'usersToNotify':
          addToArray(newData.usersToNotify, e);
          break;
        case 'rolesToNotify':
          addToArray(newData.rolesToNotify, e);
          break;
        case 'frequencyOfGidepScanNonTaggedParts':
          {
            const { value } = e.target;
            newData.frequencyOfGidepScanNonTaggedParts = value;
          }
          break;
        case 'frequencyOfGidepScanTaggedParts':
          {
            const { value } = e.target;
            newData.frequencyOfGidepScanTaggedParts = value;
          }
          break;
      }

      return newData;
    });
  }

  function addToArray(array: number[], value: any) {
    if (!isNullOrUndefined(value)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const v = value[i];
          const numValue = parseInt(v);
          if (array.indexOf(numValue) < 0) {
            array.push(numValue);
          }
        }
      } else {
        if (array.indexOf(value) < 0) {
          array.push(value);
        }
      }
    }
  }
  /**
   * Validation for SMR codes
   * @param value
   * @returns
   */
  function validateSmrCode(value: string): boolean {
    let regex = /^[a-zA-Z]+$/;

    if (isNullOrWhitespace(value)) {
      return false;
    }

    if (value.length !== 5) {
      return false;
    }

    if (!regex.test(value)) {
      return false;
    }

    return true;
  }

  /**
   * Validation for Essentiality codes
   * @param value
   * @returns
   */
  function validateEssentialityCode(value: string): boolean {
    if (isNullOrWhitespace(value)) {
      return false;
    }

    if (value.length !== 1) {
      return false;
    }

    return true;
  }

  /**
   * Validation for FSCN codes
   * @param value
   * @returns
   */
  function validateFscnCode(value: string): boolean {
    let regex = /^[0-9]+$/;

    if (isNullOrWhitespace(value)) {
      return false;
    }

    if (value.length !== 4) {
      return false;
    }

    if (!regex.test(value)) {
      return false;
    }

    return true;
  }

  /**
   * Tab Box value delete function
   * @param field
   * @param value
   */
  function handleChipDelete(field: string, value: any) {
    setFormData((prevData) => {
      const newData = { ...prevData };
      let prop = field as keyof typeof prevData;
      const arrValues = newData[prop] as any[];
      var newValues: any[] = [];

      if (arrValues.indexOf(value) > -1) {
        newValues = arrValues.filter((v) => v !== value);
      }

      return { ...prevData, [prop]: newValues };
    });
  }

  return (
    <div className="page-container">
      <Typography variant="h4" className="page-title">
        Admin Settings
      </Typography>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            marginTop: '20%',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Box sx={{ mb: 2 }}>
            <PowerLogJ2Settings
              formData={formData}
              onFieldChange={handleFieldChange}
              onChipDelete={handleChipDelete}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <GidepSettings
              formData={formData}
              onFieldChange={handleFieldChange}
              onChipDelete={handleChipDelete}
              users={users}
              roles={roles}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <ActionTypes />
          </Box>
        </div>
      )}
    </div>
  );
}
