import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Snackbar,
  Paper,
  Box,
  CircularProgress,
  Alert,
  SnackbarCloseReason,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import chroma from 'chroma-js';
import { generateMonochromaticScale } from './utils/generateMonochromaticScale';
import Badge from './Badge';
import MonochromaticScale from './MonochromaticScale';
import { ThemeColorDto } from '../../../api/types/types';
import { useDispatch, useSelector } from 'react-redux';
import AdminSettingsApi from '../../../api/adminSettings/adminSettingsApi';
import { setColors as setColorsThemeSlice } from '../../../slices/themeSlice';
import { SnackbarProps } from '../../../types/Snackbar';
import DeleteConfirmationDialog from '../../../components/data-grid/DeleteConfirmationDialog';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const defaultNewColor = {
  id: 0,
  name: '',
  hex: '',
  isPrimaryColor: false,
  monochromaticScale: [],
  isActive: true,
  isDeleted: false,
  dateAdded: null,
  dateUpdated: null,
  dateDeleted: null,
};

const ColorGrid: React.FC = () => {
  const allColors = useSelector((state: any) => state.theme.colors);
  const [colors, setColors] = useState<ThemeColorDto[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedColor, setEditedColor] = useState<ThemeColorDto | null>(null);
  const [newColor, setNewColor] = useState<ThemeColorDto>({
    ...defaultNewColor,
  });
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState<SnackbarProps>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteRowId, setDeleteRowId] = useState<any>(null);
  const [isColorInUse, setIsColorInUse] = useState<boolean>(false);

  const dispatch = useDispatch();

  const getAllColors = async () => {
    const response = await AdminSettingsApi.getAllColors();
    dispatch(setColorsThemeSlice(response));
  };

  useEffect(() => {
    setColors(allColors.filter((color: ThemeColorDto) => color.isPrimaryColor === false));
    if (allColors.length > 0) setLoading(false);
  }, [allColors]);

  const handleAddColor = () => {
    setIsAdding(true);
  };

  const cleanForm = () => {
    setNewColor({ ...defaultNewColor });
    setIsAdding(false);
  };

  const handleSaveColor = async () => {
    try {
      setLoading(true);
      await AdminSettingsApi.createColor(newColor);
      await getAllColors();
      setSnackbar({
        open: true,
        severity: 'success',
        message: 'New color created successfully.',
      });
      cleanForm();
    } catch (e: any) {
      console.log(e);
      const errorMessage =
        String(Object.values(e.response?.data?.errors)[0]) || 'An error occurred';

      setSnackbar({
        open: true,
        severity: 'error',
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveEdit = async () => {
    try {
      if (editedColor) {
        setLoading(true);
        await AdminSettingsApi.updateColor(editedColor);
        setSnackbar({
          open: true,
          severity: 'success',
          message: 'Color updated successfully.',
        });
        await getAllColors();
        setEditingIndex(null);
      }
    } catch (e: any) {
      const errorMessage =
        String(Object.values(e.response?.data?.errors)[0]) || 'An error occurred';

      setSnackbar({
        open: true,
        severity: 'error',
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (index: number) => {
    setEditingIndex(index);
    setEditedColor({ ...colors[index] });
  };

  const handleHexChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    action: 'add' | 'edit'
  ): void => {
    let inputHex = event.target.value;

    if (
      (inputHex.startsWith('#') && inputHex.length > 7) ||
      (!inputHex.startsWith('#') && inputHex.length > 6)
    )
      return;

    if (!inputHex.startsWith('#')) {
      inputHex = `#${inputHex}`;
    }

    if (action === 'add') {
      setNewColor({
        ...newColor,
        hex: inputHex,
        monochromaticScale: chroma.valid(inputHex) ? generateMonochromaticScale(inputHex) : [],
      });
    } else {
      setEditedColor((prev) => ({
        ...prev!,
        hex: inputHex,
        monochromaticScale: chroma.valid(inputHex) ? generateMonochromaticScale(inputHex) : [],
      }));
    }
  };

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  const handleDeleteColor = async (id: number) => {
    const response = await AdminSettingsApi.isColorInUse(id);
    setIsColorInUse(response);

    setDeleteRowId(id);
    setOpenDeleteDialog(true);
  };

  const closeDialog = () => {
    setDeleteRowId(null);
    setOpenDeleteDialog(false);
  };

  const confirmDelete = async () => {
    if (deleteRowId != null) {
      setLoading(true);
      await AdminSettingsApi.deleteColor(deleteRowId);
      await getAllColors();
      setSnackbar({
        open: true,
        severity: 'success',
        message: 'Color deleted successfully.',
      });
      closeDialog();
      setLoading(false);
    }
  };

  return (
    <>
      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={snackbar?.severity === 'error' ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={7}>
                <Button
                  variant="outlined"
                  onClick={handleAddColor}
                  startIcon={<AddIcon />}
                  disabled={isAdding}
                >
                  ADD A COLOR
                </Button>
              </TableCell>
            </TableRow>
            <TableRow sx={{ height: '52px' }}>
              <TableCell>Color Name</TableCell>
              <TableCell>Hex</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Monochromatic Scale</TableCell>
              <TableCell>Light Badge</TableCell>
              <TableCell>Dark Badge</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isAdding && (
              <TableRow>
                <TableCell sx={{ padding: 0, paddingLeft: 2 }}>
                  <TextField
                    value={newColor.name}
                    onChange={(e) => setNewColor({ ...newColor, name: e.target.value })}
                    variant="outlined"
                    size="small"
                    InputProps={{ sx: { height: '52px' } }}
                  />
                </TableCell>
                <TableCell sx={{ padding: 0, paddingLeft: 2 }}>
                  <TextField
                    value={newColor.hex}
                    onChange={(e: any) => handleHexChange(e, 'add')}
                    variant="outlined"
                    size="small"
                    InputProps={{ sx: { height: '52px' } }}
                  />
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      height: '20px',
                      width: '100px',
                      borderRadius: 1,
                      backgroundColor: newColor.hex,
                    }}
                  ></Box>
                </TableCell>
                <TableCell>
                  <MonochromaticScale
                    monochromaticScale={newColor.monochromaticScale}
                    onClick={(color: string) => {
                      setNewColor((prev) => ({
                        ...prev,
                        hex: color,
                        monochromaticScale: generateMonochromaticScale(color),
                      }));
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Badge
                    monochromaticScale={newColor.monochromaticScale}
                    type="light"
                    icon={<HourglassEmptyIcon />}
                  />
                </TableCell>
                <TableCell>
                  <Badge
                    monochromaticScale={newColor.monochromaticScale}
                    type="dark"
                    icon={<HourglassEmptyIcon />}
                  />
                </TableCell>
                <TableCell>
                  <Button onClick={handleSaveColor}>Save</Button>
                  <Button onClick={cleanForm}>Cancel</Button>
                </TableCell>
              </TableRow>
            )}

            {colors.length
              ? colors.map((color: ThemeColorDto, index: number) => (
                  <TableRow key={color.id}>
                    {editingIndex === index ? (
                      <>
                        <TableCell>
                          <TextField
                            value={editedColor?.name || ''}
                            onChange={(e) =>
                              setEditedColor((prev) => ({
                                ...prev!,
                                name: e.target.value,
                              }))
                            }
                            variant="outlined"
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={editedColor?.hex || ''}
                            onChange={(e: any) => handleHexChange(e, 'edit')}
                            variant="outlined"
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              height: '20px',
                              width: '100px',
                              borderRadius: 1,
                              backgroundColor: editedColor?.hex,
                            }}
                          ></Box>
                        </TableCell>
                        <TableCell>
                          <MonochromaticScale
                            monochromaticScale={editedColor?.monochromaticScale || []}
                            onClick={(color: string) => {
                              setEditedColor((prev) => ({
                                ...prev!,
                                hex: color,
                                monochromaticScale: generateMonochromaticScale(color),
                              }));
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Badge
                            monochromaticScale={editedColor?.monochromaticScale || []}
                            type="light"
                            icon={<HourglassEmptyIcon />}
                          />
                        </TableCell>
                        <TableCell>
                          <Badge
                            monochromaticScale={editedColor?.monochromaticScale || []}
                            type="dark"
                            icon={<HourglassEmptyIcon />}
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={handleSaveEdit}>Save</Button>
                          <Button onClick={() => setEditingIndex(null)}>Cancel</Button>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{color.name}</TableCell>
                        <TableCell>{color.hex}</TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              height: '20px',
                              width: '100px',
                              borderRadius: 1,
                              backgroundColor: color.hex,
                            }}
                          ></Box>
                        </TableCell>
                        <TableCell>
                          <MonochromaticScale
                            monochromaticScale={generateMonochromaticScale(color.hex)}
                            onClick={(color: string) => {}}
                          />
                        </TableCell>
                        <TableCell>
                          <Badge
                            monochromaticScale={generateMonochromaticScale(color.hex)}
                            type="light"
                            icon={<HourglassEmptyIcon />}
                          />
                        </TableCell>
                        <TableCell>
                          <Badge
                            monochromaticScale={generateMonochromaticScale(color.hex)}
                            type="dark"
                            icon={<HourglassEmptyIcon />}
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => handleEdit(index)}>Edit</Button>
                          <Button onClick={() => handleDeleteColor(color.id)}>Delete</Button>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))
              : !isAdding && (
                  <TableRow sx={{ height: '52px' }}>
                    <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                      {loading ? <CircularProgress /> : 'No Colors'}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onConfirm={confirmDelete}
        onCancel={closeDialog}
        customMsg={
          isColorInUse
            ? 'This color has already been assigned to a system element. Are you sure you want to delete?'
            : null
        }
      />
    </>
  );
};

export default ColorGrid;
