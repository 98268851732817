import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface CustomDialogProps {
  title: string;
  isLoading: boolean;
  onCloseClick: () => void;
  onSaveClick: () => void;
  onCancelClick: () => void;
  children: ReactNode;
}

const CustomDialog = ({
  title,
  isLoading,
  onCloseClick,
  onSaveClick,
  onCancelClick,
  children,
}: CustomDialogProps) => {
  return (
    <>
      <Dialog
        open={true}
        onClose={onCloseClick}
        maxWidth="md"
        fullWidth
        className="risk-case-popup"
        PaperProps={{
          sx: {
            height: '600px',
            maxHeight: '600px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="div" sx={{ fontSize: '22px' }}>
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseClick}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            p: 2,
            overflowY: 'scroll',
            flex: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 transparent',
          }}
        >
          {isLoading ? (
            <div className="spinner">
              <CircularProgress />
            </div>
          ) : (
            children
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick}>Cancel</Button>
          <Button onClick={onSaveClick} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomDialog;
