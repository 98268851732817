// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, PartHistoryDto } from '../types/types';
const { DMSMS_SERVICE_URL } = config;

const PartHistory = {
  /**
   * Retrieves all part histories.
   * @returns
   */
  getPartHistories: async function (): Promise<ResponseDto<PartHistoryDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/PartHistory`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<PartHistoryDto[]>;
  },
  /**
   * Retrieves all histories for a watched part
   * @returns
   */
  getPartHistoriesForWatchedPart: async function (
    watchedPartId: number
  ): Promise<ResponseDto<PartHistoryDto[]>> {
    var url = `${DMSMS_SERVICE_URL}/PartHistory/ForWatchedPart/${watchedPartId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<PartHistoryDto[]>;
  },
  /**
   * Retrieves a single Part History.
   * @returns
   */
  getPartHistory: async function (partHistoryId: number): Promise<ResponseDto<PartHistoryDto>> {
    var url = `${DMSMS_SERVICE_URL}/PartHistory/${partHistoryId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<PartHistoryDto>;
  },
};

export default PartHistory;
