import Permissions from './permissions';
import User from './user';
import Role from './role';

const PermissionsApi = {
  ...Permissions,
  ...User,
  ...Role,
};

export default PermissionsApi;
