import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createColumn } from '../../../utils/data-grid';
import { NsnDataGridHelpMenu } from './NsnDataGridHelpMenu';
import './NsnDetails.scss';

export default function NsnDetailPhraseDataGrid({ data }) {
  // Create column definitions	// Create column definitions
  const columns = [
    { ...createColumn('MOE', 'MOE'), minWidth: 80, flex: 1 },
    { ...createColumn('USC', 'USC'), minWidth: 80, flex: 1 },
    {
      ...createColumn('PHRASE_CD', 'PHRASE CD'),
      minWidth: 100,
      flex: 1,
    },
    {
      ...createColumn('PHRASE_STATEMENT', 'PHRASE STATEMENT'),
      minWidth: 100,
      flex: 1,
    },
    {
      ...createColumn('ORDER_OF_USE', 'ORDER OF USE'),
      minWidth: 100,
      flex: 1,
    },
    {
      ...createColumn('JUMP_TO_CODE', 'JUMP TO CODE'),
      minWidth: 100,
      flex: 1,
    },
    { ...createColumn('QPA', 'QPA'), minWidth: 80, flex: 1 },
    {
      ...createColumn('UM', 'UM'),
      minWidth: 80,
      flex: 1,
    },
    {
      ...createColumn('TECH_DOC_NBR', 'TECH DOC NBR'),
      minWidth: 100,
      flex: 1,
    },
    {
      ...createColumn('QNTV_EXPRSN', 'QNTV EXPRSN'),
      minWidth: 100,
      flex: 1,
    },
  ];

  return (
    <Box className="nsn-detail-grid">
      <h5>Phrase</h5>
      <DataGrid
        autoHeight
        getRowId={() => 10000 * Math.random()}
        density="compact"
        rows={data}
        columns={columns}
        slots={{ columnMenu: NsnDataGridHelpMenu }}
      />
    </Box>
  );
}
