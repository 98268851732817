import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createColumn } from '../../../utils/data-grid';
import CageDetailsModal from '../../modal/CageDetailsModal';
import { useState } from 'react';
import { NsnDataGridHelpMenu } from './NsnDataGridHelpMenu';
import './NsnDetails.scss';

export default function NsnDetailRefPnDataGrid({ data }) {
  const [modalState, setModalState] = useState({
    open: false,
    cage: '',
  });

  function handleModalClose() {
    setModalState((oldState) => ({
      ...oldState,
      open: false,
    }));
  }

  const handleCellClick = async (
    params, // GridCellParams<any>
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    if (params.field === 'CAGECD') {
      setModalState((oldState) => ({
        ...oldState,
        open: true,
        cage: params.row.CAGECD,
      }));
    }
  };

  // Create column definitions
  const columns = [
    {
      ...createColumn('REFPN', 'REF PN'),
      flex: 1,
    },
    {
      ...createColumn('CAGECD', 'CAGE CD'),
      width: 150,
      cellClassName: (params) => {
        const rowData = params.row;
        var cellClass = 'cell-clickable';

        if (
          (rowData.RNCC === '2' || rowData.RNCC === '3' || rowData.RNCC === '5') &&
          rowData.RNVC === '2' &&
          rowData.INSAM === 0
        ) {
          cellClass += ' red-cell';
        }

        return cellClass;
      },
    },
    { ...createColumn('STAT', 'STAT'), width: 80 },
    { ...createColumn('RNCC', 'RNCC'), width: 80 },
    { ...createColumn('RNVC', 'RNVC'), width: 80 },
    { ...createColumn('DAC', 'DAC'), width: 80 },
    { ...createColumn('RNAAC', 'RNAAC'), width: 80 },
    { ...createColumn('RNFC', 'RNFC'), width: 80 },
    {
      ...createColumn('RNSC', 'RNSC'),
      width: 80,
      cellClassName: (params) => {
        const rowData = params.row;

        if (
          (rowData.RNCC === '2' || rowData.RNCC === '3' || rowData.RNCC === '5') &&
          rowData.RNVC === '2' &&
          rowData.RNSC === 'B'
        ) {
          return 'red-cell';
        } else return '';
      },
    },
    { ...createColumn('RNJC', 'RNJC'), width: 80 },
    { ...createColumn('SADC', 'SADC'), width: 80 },
    { ...createColumn('HCC', 'HCC'), width: 80 },
    { ...createColumn('MSDS', 'MSDS'), width: 80 },
    { ...createColumn('INSAM', 'INSAM'), width: 80 },
  ];
  return (
    <Box className="nsn-detail-grid">
      <h5>Reference/Part Number</h5>
      <DataGrid
        autoHeight
        getRowId={() => 10000 * Math.random()}
        density="compact"
        rows={data}
        columns={columns}
        getRowClassName={(params) => {
          const rowData = params.row;

          if (
            (rowData.RNCC === '2' || rowData.RNCC === '3' || rowData.RNCC === '5') &&
            rowData.RNVC === '2'
          ) {
            return 'bold-row';
          } else return '';
        }}
        onCellClick={handleCellClick}
        slots={{ columnMenu: NsnDataGridHelpMenu }}
      />
      <CageDetailsModal open={modalState.open} cage={modalState.cage} onClose={handleModalClose} />
    </Box>
  );
}
