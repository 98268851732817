// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ThemeColorDto } from '../../api/types/types';

const { ALTRUITY_API_URL } = config;

const AdminSettings = {
  getAllColors: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/SiteAdmin/ThemeColors/GetAllColors`);
    return data.result;
  },

  getPrimaryColor: async function () {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/SiteAdmin/ThemeColors/GetPrimaryColor`
    );
    return data.result;
  },

  createColor: async function (body: ThemeColorDto) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/SiteAdmin/ThemeColors/CreateColor`,
      body
    );
    return data;
  },

  updateColor: async function (body: ThemeColorDto) {
    const { data } = await httpClient.put(
      `${ALTRUITY_API_URL}/SiteAdmin/ThemeColors/UpdateColor/${body.id}`,
      body
    );
    return data;
  },

  deleteColor: async function (id: number) {
    const { data } = await httpClient.delete(
      `${ALTRUITY_API_URL}/SiteAdmin/ThemeColors/DeleteColor/${id}`
    );
    return data;
  },

  isColorInUse: async function (id: number) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/SiteAdmin/ThemeColors/IsColorInUse/${id}`
    );
    return data.result;
  },
};

export default AdminSettings;
