import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AttachFile } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Select,
  SnackbarCloseReason,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { enUS } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ValidationSnackbar } from '../../../../../components/form';

export interface FileData {
  watchedPartCaseLogEntryId?: number;
  filename: string;
  operationType: string;
  base64Data: string | null;
  watchedPartCaseLogEntryFileId: number;
}

export interface WatchedPartCaseLogEntry {
  watchedPartCaseLogEntryId: number;
  date: string | null;
  authorFullName: string;
  entryText: string;
  files: FileData[];
}

interface CaseLogDialogProps {
  open: boolean;
  watchedPartCaseLogEntry: WatchedPartCaseLogEntry;
  onClose: () => void;
  onSave: (data: WatchedPartCaseLogEntry) => void;
}

export function CaseLogDialog({
  open,
  onClose,
  onSave,
  watchedPartCaseLogEntry,
}: CaseLogDialogProps) {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState(false);
  const [newEntry, setNewEntry] = useState<WatchedPartCaseLogEntry>(watchedPartCaseLogEntry);
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
    severity: 'error',
  });

  useEffect(() => {
    setNewEntry(watchedPartCaseLogEntry);
  }, [watchedPartCaseLogEntry]);
  /**
   * Closes the validation SnackBar
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
      message: '',
    }));
  };

  const handleProgress = () => {
    setIsUploading(true);
    setUploadProgress(0);
    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 90) {
          clearInterval(interval);
          return prevProgress;
        }
        return prevProgress + 5;
      });
    }, 700);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsUploading(true);

      const totalFiles = e.target.files.length;
      let filesProcessed = 0;

      const processFile = (index: number) => {
        if (index >= totalFiles) {
          setUploadProgress(100);
          setTimeout(() => setIsUploading(false), 1000);
          return;
        }

        const file = e.target.files![index];
        const reader = new FileReader();

        reader.onload = (event) => {
          const base64Data = event.target?.result as string;
          filesProcessed++;
          const progress = (filesProcessed / totalFiles) * 100;
          setUploadProgress(progress);

          setNewEntry((prev) => ({
            ...prev,
            files: [
              ...prev.files,
              {
                filename: file.name,
                operationType: 'ADD',
                base64Data: base64Data.split(',')[1],
                watchedPartCaseLogEntryFileId: 0,
              },
            ],
          }));
          processFile(index + 1);
        };

        reader.readAsDataURL(file);
      };

      processFile(0);
    } else {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleRemoveFile = (fileToRemove: FileData) => {
    setNewEntry((prev) => ({
      ...prev,
      files: prev.files.filter((file) => file.filename !== fileToRemove.filename),
    }));
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle variant="h6" component="div" sx={{ fontSize: '26px' }}>
          {newEntry.watchedPartCaseLogEntryId > 0 ? 'Edit Case Log' : 'New Case Log'}
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
            <DatePicker
              label="Date"
              value={newEntry.date ? new Date(newEntry.date) : null}
              onChange={(newDate) => {
                setNewEntry({
                  ...newEntry,
                  date: newDate ? newDate.toISOString() : null,
                });
              }}
              slotProps={{
                textField: {
                  style: {
                    marginBottom: '5px',
                    marginTop: '10px',
                  },
                },
              }}
            />
          </LocalizationProvider>
          <Select
            margin="dense"
            label="Author"
            fullWidth
            value={newEntry.authorFullName || ''}
            onChange={(e) =>
              setNewEntry({
                ...newEntry,
                authorFullName: e.target.value as string,
              })
            }
            disabled
          >
            <MenuItem value={newEntry.authorFullName}>{newEntry.authorFullName}</MenuItem>
          </Select>
          <TextField
            margin="dense"
            label="Entry"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={newEntry.entryText}
            onChange={(e) =>
              setNewEntry({
                ...newEntry,
                entryText: e.target.value,
              })
            }
          />{' '}
          <Box
            sx={{
              margin: '10px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Button variant="contained" component="label" onClick={handleProgress}>
              Upload Files
              <input type="file" hidden multiple onChange={handleFileUpload} />
            </Button>
            {isUploading && (
              <Box sx={{ mt: 2, width: '100%', maxWidth: '300px' }}>
                <LinearProgress
                  variant={uploadProgress < 90 ? 'indeterminate' : 'determinate'}
                  value={uploadProgress}
                  sx={{
                    height: 8,
                    borderRadius: 4,
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 4,
                    },
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                mt: 2,
              }}
            >
              {newEntry.files.map((file, index) => (
                <Chip
                  key={index}
                  icon={<AttachFile sx={{ fontSize: '0.8rem' }} />}
                  label={file.filename}
                  onDelete={() => handleRemoveFile(file)}
                  sx={{
                    bgcolor: 'grey.200',
                    color: 'text.primary',
                    '& .MuiChip-deleteIcon': {
                      color: 'text.secondary',
                    },
                    height: '24px',
                    fontSize: '0.75rem',
                  }}
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => onSave(newEntry)} color="primary">
            {newEntry.watchedPartCaseLogEntryId > 0 ? 'Save Changes' : 'Add Entry'}
          </Button>
        </DialogActions>
      </Dialog>
      <ValidationSnackbar
        open={snackBarState.open}
        message={snackBarState.message}
        severity={snackBarState.severity}
        onClose={handleSnackBarClose}
      />
    </>
  );
}
