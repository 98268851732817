import React from 'react';
import KeyMetrics from './KeyMetrics';
import SupplierHealth from './SupplierHealth';
import Dmsms from './Dmsms';
import RiskCasesChart from './RiskCasesChart';
import RiskSourcesPieCharts from './RiskSourcesPieCharts';
import RiskCaseAging from './RiskCaseAging/RiskCaseAging';
import HitsAgings from './hitsAgings/HitsAgings';
import DnBAssessmentScatterChart from './DnBAssessmentScatterChart';

const Dashboard = () => {
  return (
    <>
      <KeyMetrics />
      <SupplierHealth />
      <RiskCasesChart />
      <RiskSourcesPieCharts />
      <DnBAssessmentScatterChart />
      <Dmsms />
      <HitsAgings />
      <RiskCaseAging />
    </>
  );
};

export default Dashboard;
