// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import {
  ResponseDto,
  NsnSearchMultipleDto,
  NsnDetailDto,
  NsnSearchResponseDto,
} from '../types/types';

import { NsnQueryDto } from '../types/custom-types';

const { NSN_SERVICE_URL } = config;

const Search = {
  /**
   * Creates a new supplier.
   * @param {NsnQueryDto} queryData The query parameters
   */
  searchFullSingle: async function (
    queryData: NsnQueryDto
  ): Promise<ResponseDto<NsnSearchResponseDto>> {
    // Construct url
    var url = `${NSN_SERVICE_URL}/Search/Full/Single`;
    var count = 0;
    let prop: keyof typeof queryData;
    for (prop in queryData) {
      if (count === 0) {
        url += '?';
      } else {
        url += '&';
      }

      url += `${prop}=${queryData[prop]}`;

      count++;
    }

    const { data } = await httpClient.get(url);
    return data as ResponseDto<NsnSearchResponseDto>;
  },

  /**
   * Creates a new supplier.
   * @param {NsnQueryDto} queryData The query parameters
   */
  searchFull: async function (
    queryData: NsnSearchMultipleDto
  ): Promise<ResponseDto<NsnSearchResponseDto>> {
    // Construct url
    var url = `${NSN_SERVICE_URL}/Search/Full`;
    // var queryString = "";
    // url += constructSearchQueryString(queryString, queryData);

    //const { data } = await httpClient.get(url);
    const { data } = await httpClient.post(url, queryData);
    return data as ResponseDto<NsnSearchResponseDto>;
  },

  /**
   * Get the details for a particular NSN.
   * @param nsn
   * @returns
   */
  getNsnDetails: async function (nsn: String): Promise<ResponseDto<NsnDetailDto>> {
    const url = `${NSN_SERVICE_URL}/Search/Direct?nsn=${nsn}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<NsnDetailDto>;
  },

  /**
   * Gets information for a particular cage code.
   * @param cage
   * @returns
   */
  getSearchFile: async function (
    type: string,
    includeMetadata: boolean,
    queryData: NsnSearchMultipleDto
  ): Promise<ResponseDto<string[]>> {
    var url = `${NSN_SERVICE_URL}/Search/File`;
    var queryString = '?fileType=' + type + '&includeMetadata=' + includeMetadata;
    //url += constructSearchQueryString(queryString, queryData);
    url += queryString;
    const { data } = await httpClient.post(url, queryData);
    return data as ResponseDto<string[]>;
  },
};

export default Search;
