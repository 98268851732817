import React from 'react';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
} from '@mui/material';
import { AltruityCheckbox } from '../../../components/form';
import CloseIcon from '@mui/icons-material/Close';

interface ExportDialogProps {
  onClose: () => void;
  onSubmit: (type: string, includeMetadata: boolean) => void;
  open: boolean;
  type: string;
}

export function ExportDialog({ open, type, onClose, onSubmit }: ExportDialogProps) {
  const [includeMetadata, setIncludeMetadata] = useState(false);

  return createPortal(
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
        }}
        id="dialog-title"
        style={{
          backgroundColor: '#306EB6',
          fontSize: '20px',
          fontWeight: 'bold',
          color: 'white',
          paddingRight: '60px',
        }}
      >
        NSN Detail Metadata
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 10,
          color: 'white',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <FormGroup>
          <AltruityCheckbox
            label="Include NSN Detail Metadata in file? (will take longer)"
            name="includeMetadata"
            defaultChecked={includeMetadata}
            onChange={(e: any) => {
              setIncludeMetadata(e.target.checked);
            }}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onSubmit(type, includeMetadata)}>
          Export
        </Button>
        <Button onClick={onClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>,
    document.getElementById('app-modal')!
  );
}
