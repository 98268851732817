import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RiskCaseResponseDto } from '../api/types/custom-types';
import { RiskCaseViewModelDto } from '../api/types/types';

interface RiskCases {
  id: number;
  riskCaseName: string;
  description: string;
  ownerId: number;
  subscribers: string;
  keywords: string;
  createdById: number;
  measures: [];
  articles: [];
  suppliers: [];
  logEntries: [];
  stateChangeCounter: number;
}

interface RiskCasesMenuItem {
  id: number;
  description: string;
}

interface MenuRiskCaseSection {
  [label: string]: { items: RiskCasesMenuItem[] };
}

interface RiskCasesMenuItem {
  id: number;
  description: string;
}

interface RiskCasesSliceState {
  allRiskCases: RiskCases[];
  allRiskCasesVm: RiskCaseViewModelDto[];
  menuRiskCase: {
    byKeywordModel: MenuRiskCaseSection;
    bySupplier: MenuRiskCaseSection;
    archived: {
      byKeywordModel: MenuRiskCaseSection;
      bySupplier: MenuRiskCaseSection;
    };
  };
  riskCaseGridData: RiskCaseResponseDto[];
  selectedMenuItem: string | number | null | undefined;
  currentRiskCaseName: string;
  stateChangeCounter: number;
}

const initialState: RiskCasesSliceState = {
  allRiskCases: [] as RiskCases[],
  allRiskCasesVm: [] as RiskCaseViewModelDto[],
  menuRiskCase: {
    byKeywordModel: {} as MenuRiskCaseSection,
    bySupplier: {} as MenuRiskCaseSection,
    archived: {
      byKeywordModel: {} as MenuRiskCaseSection,
      bySupplier: {} as MenuRiskCaseSection,
    },
  },
  riskCaseGridData: [] as RiskCaseResponseDto[],
  selectedMenuItem: null,
  currentRiskCaseName: '',
  stateChangeCounter: 0,
};

const riskCasesSlice = createSlice({
  name: 'riskCases',
  initialState,
  reducers: {
    addRiskCases: (state, action: PayloadAction<RiskCases[]>) => {
      const newRiskCases = action.payload.filter(
        (newRiskCase) =>
          !state.allRiskCases.some((existingRiskCase) => existingRiskCase.id === newRiskCase.id)
      );
      state.allRiskCases = [...state.allRiskCases, ...newRiskCases];
    },
    addRiskCasesVm: (state: RiskCasesSliceState, action: PayloadAction<RiskCaseViewModelDto[]>) => {
      const newRiskCases = action.payload.filter(
        (newRiskCase) =>
          !state.allRiskCasesVm.some(
            (existingRiskCase) =>
              existingRiskCase.generalInformation.riskCaseId ===
              newRiskCase.generalInformation.riskCaseId
          )
      );
      state.allRiskCasesVm = [...state.allRiskCasesVm, ...newRiskCases];
    },
    setMenuRiskCase: (state, action) => {
      state.menuRiskCase = action.payload;
    },
    setSelectedMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload;
    },
    setRiskCurrentCaseName: (state, action: PayloadAction<string>) => {
      state.currentRiskCaseName = action.payload;
    },
    updateRiskCaseName: (state, action: PayloadAction<{ id: number; newName: string }>) => {
      const { id, newName } = action.payload;

      const updateRiskCaseRecursive = (obj: any, path: string) => {
        if (obj && typeof obj === 'object') {
          for (const key in obj) {
            if (obj[key].items && Array.isArray(obj[key].items)) {
              for (let i = 0; i < obj[key].items.length; i++) {
                const item = obj[key].items[i];

                if (item.id === id) {
                  item.description = newName;
                }
              }

              obj[key].items.sort((a: any, b: any) => a.description.localeCompare(b.description));
            } else {
              updateRiskCaseRecursive(obj[key], `${path} -> ${key}`);
            }
          }
        }
      };

      updateRiskCaseRecursive(state.menuRiskCase.byKeywordModel, 'menuRiskCase.byKeywordModel');
      updateRiskCaseRecursive(state.menuRiskCase.bySupplier, 'menuRiskCase.bySupplier');

      state.currentRiskCaseName = newName;
    },
    setRiskCaseGridData: (state, action: PayloadAction<RiskCaseResponseDto[]>) => {
      state.riskCaseGridData = action.payload;
    },
    notifyRiskCaseChange: (state) => {
      state.stateChangeCounter = state.stateChangeCounter + 1;
    },
    updateRiskCaseMenu: (
      state,
      action: PayloadAction<{ updatedRiskCase: any; isArchived: boolean }>
    ) => {
      const { updatedRiskCase, isArchived } = action.payload;

      const activeSection = isArchived ? state.menuRiskCase : state.menuRiskCase.archived;
      const targetSection = isArchived ? state.menuRiskCase.archived : state.menuRiskCase;

      if (isArchived && targetSection === state.menuRiskCase) {
        return;
      }

      Object.keys(activeSection.byKeywordModel).forEach((keywordModelLabel: any) => {
        activeSection.byKeywordModel[keywordModelLabel].items = activeSection.byKeywordModel[
          keywordModelLabel
        ].items.filter((item: any) => item.id !== updatedRiskCase.riskCaseId);

        if (activeSection.byKeywordModel[keywordModelLabel].items.length === 0) {
          delete activeSection.byKeywordModel[keywordModelLabel];
        }
      });

      Object.keys(activeSection.bySupplier).forEach((supplierLabel: any) => {
        activeSection.bySupplier[supplierLabel].items = activeSection.bySupplier[
          supplierLabel
        ].items.filter((item: any) => item.id !== updatedRiskCase.riskCaseId);

        if (activeSection.bySupplier[supplierLabel].items.length === 0) {
          delete activeSection.bySupplier[supplierLabel];
        }
      });

      updatedRiskCase.keywordModels.forEach((keywordModel: any) => {
        const keywordModelLabel = keywordModel.label;
        if (!targetSection.byKeywordModel[keywordModelLabel]) {
          targetSection.byKeywordModel[keywordModelLabel] = { items: [] };
        }
        const existingRiskCase = targetSection.byKeywordModel[keywordModelLabel].items.find(
          (item) => item.id === updatedRiskCase.riskCaseId
        );
        if (!existingRiskCase) {
          targetSection.byKeywordModel[keywordModelLabel].items.push({
            description: updatedRiskCase.riskCaseName,
            id: updatedRiskCase.riskCaseId,
          });
        }
      });

      updatedRiskCase.suppliers.forEach((supplier: any) => {
        const supplierLabel = supplier.label;
        if (!targetSection.bySupplier[supplierLabel]) {
          targetSection.bySupplier[supplierLabel] = { items: [] };
        }
        const existingRiskCase = targetSection.bySupplier[supplierLabel].items.find(
          (item) => item.id === updatedRiskCase.riskCaseId
        );
        if (!existingRiskCase) {
          targetSection.bySupplier[supplierLabel].items.push({
            description: updatedRiskCase.riskCaseName,
            id: updatedRiskCase.riskCaseId,
          });
        }
      });

      Object.keys(targetSection.byKeywordModel).forEach((keywordModelLabel: any) => {
        const existsInUpdated = updatedRiskCase.keywordModels.some(
          (keywordModel: any) => keywordModel.label === keywordModelLabel
        );
        if (!existsInUpdated) {
          targetSection.byKeywordModel[keywordModelLabel].items = targetSection.byKeywordModel[
            keywordModelLabel
          ].items.filter((item) => item.id !== updatedRiskCase.riskCaseId);
          if (targetSection.byKeywordModel[keywordModelLabel].items.length === 0) {
            delete targetSection.byKeywordModel[keywordModelLabel];
          }
        }
      });

      Object.keys(targetSection.bySupplier).forEach((supplierLabel: any) => {
        const existsInUpdated = updatedRiskCase.suppliers.some(
          (supplier: any) => supplier.label === supplierLabel
        );
        if (!existsInUpdated) {
          targetSection.bySupplier[supplierLabel].items = targetSection.bySupplier[
            supplierLabel
          ].items.filter((item) => item.id !== updatedRiskCase.riskCaseId);
          if (targetSection.bySupplier[supplierLabel].items.length === 0) {
            delete targetSection.bySupplier[supplierLabel];
          }
        }
      });

      state.stateChangeCounter += 1;
    },
  },
});

export const {
  addRiskCases,
  addRiskCasesVm,
  setMenuRiskCase,
  setSelectedMenuItem,
  setRiskCurrentCaseName,
  updateRiskCaseName,
  setRiskCaseGridData,
  notifyRiskCaseChange,
  updateRiskCaseMenu,
} = riskCasesSlice.actions;
export default riskCasesSlice.reducer;
